
import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { APIS, SERVER_NAME, LAMBDA_COMBO_URL, STAGING_ENVIRONMENT_URL, MICROSERVICE_APIS } from '../config';
import { Moment } from 'moment';
import { UIRouter } from '@uirouter/angular';
// import 'rxjs/add/operator/timeout';
import { timeout } from 'rxjs/operators';


import { UserDetailsService } from '../services/user-details.service';
import { from } from 'rxjs';


declare const moment: any;
declare const $: any;

@Injectable()
export class HttpService {

  // accessToken : any = "eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJkdW1teSIsImF1ZGllbmNlIjoiSU9TIiwicGFzcyI6bnVsbCwiY3JlYXRlZCI6MTQ5NDQ5MjM0MDU5NiwiZXhwIjoxNDk1MDk3MTQwfQ.Au3WmeALu5WM3cfj17bPGrR9Iwiksrxgs234kVxttrqX18-uqBSCPPMiAMvM91OIQbewIkNp9SLIzH2_H6Ka-g";

  constructor(public _http: Http, public _uiRouter: UIRouter, public _userDetailsService: UserDetailsService) { }

  getApiUrl(apiName) {
    const transformedAPIName = APIS[apiName] || apiName;
    let url = SERVER_NAME + transformedAPIName;
    return url;
  }

  getApiUrl2(apiName) {
    // let url = 'https://service-ticketing.herokuapp.com' + APIS[apiName];
    // let url = 'http://localhost:3001' + APIS[apiName];
    let url = 'https://3lzemreykj.execute-api.us-east-1.amazonaws.com/staging' + APIS[apiName];
    // let url = 'https://lmx6ecr227.execute-api.us-east-1.amazonaws.com/prod' + APIS[apiName];
    return url;
  }

  getApiUrl3(apiName) {
    // let url = 'https://service-ticketing.herokuapp.com' + APIS[apiName];
    // let url = 'http://localhost:3009' + APIS[apiName];
    // let url = 'https://iyo2f2vv27.execute-api.us-east-1.amazonaws.com/staging' + APIS[apiName];

    // USE THIS FOR PROD
    let url = 'https://1qndwt4f05.execute-api.us-east-1.amazonaws.com/prod' + APIS[apiName];
    return url;
  }

  getStagingEnvironmentApiUrl(apiName) {
    let url = STAGING_ENVIRONMENT_URL + APIS[apiName];
    return url;
  }

  getMicroServiceApiUrl(serviceName) {
    let url = MICROSERVICE_APIS[serviceName];
    return url;
  }

  static setUserData(data) {
    window.sessionStorage.setItem('userData', JSON.stringify(data));
  }

  static getUserData() {
    return JSON.parse(window.sessionStorage.getItem('userData'));
  }
  static removeUserData() {
    window.sessionStorage.clear();
  }

  getHeader(headerJson) {
    //console.log(HttpService.getUserData())
    const headers = new Headers();
    if (headerJson) {
      headers.append('Content-Type', 'application/json');
    }
    headers.append('Access-Control-Allow-Origin', '*');
    if (HttpService.getUserData() != null) {
      headers.append('Token', HttpService.getUserData().accessToken);


      console.log('headers', headers);

    }
    return headers;
  }

  httpRequest(
    type: string,
    apiName: string,
    data: any,
    showLoader: boolean = true,
    headerJson: boolean = true
  ) {
    if (showLoader == true) {
      $('loaders-css').show();
    }
    return new Promise((resolve, reject) => {
      let request;
      let headers = this.getHeader(headerJson);
      if (type == "POST") {
        request = this._http.post(this.getApiUrl(apiName), data, { headers: headers })
      }
      else if (type == "GET") {
        let getData = this.getApiUrl(apiName) + (typeof data === 'object' ? "" : `?${data}`);
        request = this._http.get(getData, { headers: headers })
        console.log('BIG OL URL: ' + getData);

      }
      else if (type == "DELETE") {
        let getData = this.getApiUrl(apiName) + (typeof data === 'object' ? "" : `?${data}`);
        request = this._http.delete(getData, { headers: headers })
      }



      request.pipe(timeout(25000)).subscribe(data => {
        this.successHandler(resolve, reject, data);
      }, data => {
        this.errorHandler(resolve, reject, data);
      })

    })
  }

  httpRequest2(
    type: string,
    apiName: string,
    data: any,
    showLoader: boolean = true,
    headerJson: boolean = true
  ) {
    if (showLoader == true) {
      $('loaders-css').show();
    }
    console.log(this.getApiUrl2(apiName))
    console.log('http request 2');

    return new Promise((resolve, reject) => {
      let request;
      let headers = this.getHeader(headerJson);
      console.log(headers);
      if (type == "POST") {
        request = this._http.post(this.getApiUrl2(apiName), data, { headers: headers })
      }
      else if (type == "GET") {
        let getData = this.getApiUrl2(apiName) + (typeof data === 'object' ? "" : `?${data}`);
        request = this._http.get(getData, { headers: headers })
        console.log('BIG OL URL: ' + getData);
      }
      else if (type == "DELETE") {
        let getData = this.getApiUrl2(apiName) + (typeof data === 'object' ? "" : `?${data}`);
        request = this._http.delete(getData, { headers: headers })
      }



      request.pipe(timeout(25000)).subscribe(data => {
        this.successHandler(resolve, reject, data);
      }, data => {
        this.errorHandler(resolve, reject, data);
      })

    })
  }

  httpRequest3(
      type: string,
      apiName: string,
      data: any,
      showLoader: boolean = true,
      headerJson: boolean = true
  ) {
    if (showLoader == true) {
      $('loaders-css').show();
    }
    console.log(this.getApiUrl3(apiName))
    console.log('http request 3');

    return new Promise((resolve, reject) => {
      let request;
      let headers = this.getHeader(headerJson);
      console.log(headers);
      if (type == "POST") {
        request = this._http.post(this.getApiUrl3(apiName), data, { headers: headers })
      }
      else if (type == "GET") {
        let getData = this.getApiUrl3(apiName) + (typeof data === 'object' ? "" : `?${data}`);
        request = this._http.get(getData, { headers: headers })
        console.log('BIG OL URL: ' + getData);
      }
      else if (type == "DELETE") {
        let getData = this.getApiUrl3(apiName) + (typeof data === 'object' ? "" : `?${data}`);
        request = this._http.delete(getData, { headers: headers })
      }



      request.pipe(timeout(25000)).subscribe(data => {
        this.successHandler(resolve, reject, data);
      }, data => {
        this.errorHandler(resolve, reject, data);
      })

    })
  }

   httpRequestLambdaCombo(
      type: string,
      endpointPath: string,
      data: any,
      showLoader: boolean = true,
      headerJson: boolean = true,
      customHeaders: object = {},
  ) {
    if (showLoader == true) {
      $('loaders-css').show();
    }

    return new Promise((resolve, reject) => {
      let request;
      let headers = this.getHeader(headerJson);
      if (type == "POST") {
        for(const key in customHeaders){
            headers.append(key, customHeaders[key]);
        }
        request = this._http.post(`${LAMBDA_COMBO_URL}/${endpointPath}`, data, { headers: headers })
      }
      else if (type == "GET") {
        request = this._http.get(`${LAMBDA_COMBO_URL}/${endpointPath}`, { headers: headers })
      }

      request.pipe(timeout(25000)).subscribe(data => {
        this.successHandler(resolve, reject, data);
      }, data => {
        this.errorHandler(resolve, reject, data);
      })

    })
  }

  httpRequestForStagingEnvironment(
    type: string,
    apiName: string,
    data: any,
    showLoader: boolean = true,
    headerJson: boolean = true
  ) {
    if (showLoader == true) {
      $('loaders-css').show();
    }
    return new Promise((resolve, reject) => {
      let request;
      let headers = this.getHeader(headerJson);
      if (type == "POST") {
        request = this._http.post(this.getStagingEnvironmentApiUrl(apiName), data, { headers: headers })
      }
      else if (type == "GET") {
        let getData = this.getStagingEnvironmentApiUrl(apiName) + "?" + data;
        request = this._http.get(getData, { headers: headers })
      }
      else if (type == "DELETE") {
        let getData = this.getStagingEnvironmentApiUrl(apiName) + "?" + data;
        request = this._http.delete(getData, { headers: headers })
      }
      request.pipe(timeout(25000)).subscribe(data => {
        this.successHandler(resolve, reject, data);
      }, data => {
        this.errorHandler(resolve, reject, data);
      })
    })
  }

  httpRequestForMicroServices(
      type: string,
      serviceName: string,
      path: string,
      data: any,
      showLoader: boolean = true,
      headerJson: boolean = true,
  ){
    if (showLoader == true) {
      $('loaders-css').show();
    }

    return new Promise((resolve, reject) => {
      let request;
      let headers = this.getHeader(headerJson);
      if (type == "POST") {
        request = this._http.post(`${this.getMicroServiceApiUrl(serviceName)}${path}`, data, { headers: headers })
      }
      else if (type == "GET") {
        let getData = `${this.getMicroServiceApiUrl(serviceName)}${path}` + "?" + data;
        request = this._http.get(getData, { headers: headers })
      }
      else if (type == "DELETE") {
        let getData = `${this.getMicroServiceApiUrl(serviceName)}${path}` + "?" + data;
        request = this._http.delete(getData, { headers: headers })
      }
      request.pipe(timeout(25000)).subscribe(data => {
        this.successHandler(resolve, reject, data);
      }, data => {
        this.errorHandler(resolve, reject, data);
      })
    })
  }

  makeResolveData(isError: boolean, data: any) {
    if (!data) {
      data = {};
    }
    data.isError = isError;
    return data;
  }

  successHandler(resolve, reject, data) {
    $('loaders-css').hide();
    let response;
    try {
      response = data.json();
    } catch (e) {
      reject(this.makeResolveData(true, response));
    }

    if (response) {
      if (response.statusCode) {
        if (response.statusCode === 200) {
          resolve(response);
        } else if (response.statusCode === 401) {
          this._uiRouter.stateService.go('login');
        } else if (response.statusCode === 403) {
          // console.log("coming");
          resolve(response);
        } else {
          if (response.message && response.statusCode) {

          }
          reject(this.makeResolveData(true, response));
        }
      }
    } else {
      this._userDetailsService.showError(response.message);
    }


  }

  errorHandler(resolve, reject, data) {
    $('loaders-css').hide();

    //this._userDetailsService.showError(resolve.message);
    //console.log('errorHandler resolve - '+resolve);
    //console.log('errorHandler reject - '+reject);
    console.log('errorHandler data - ' + data);
    let response;
    try {
      response = data.json();
    } catch (e) { }

    if (response) {
      if (response.statusCode === 401) {
        this._uiRouter.stateService.go('login');
      } else if (response.statusCode == undefined) {
        this._userDetailsService.showError("No internet connection.");
      }
    }


    response = this.makeResolveData(true, response);
    reject(response);
  }

  getShortDate(timestamp) {
    let date = timestamp.split(' ')[0];
    // console.log('date_',date);
    return moment(date).format('ddd, MMM Do, YYYY');
  }


  getOnlyDate(timestamp) {
    let date = timestamp.split(' ')[0];
    return moment(date).format('YYYY-mm-dd');
  }

  // convert date format to UTC
  convertToUTC(date, format) {
    return moment.utc(date).format(format)
  }

  // convert date formal to local
  convertToLocal(date, format) {
    return moment.local(date).format(format)
  }

}