import { Component, OnInit } from '@angular/core';
import { UIRouter, StateService } from '@uirouter/angular';
import { HttpService } from '../services/http.service';
import { ListOfContestModalComponent } from '../list-of-contest-modal/list-of-contest-modal.component';

@Component({
  selector: 'app-list-of-contests',
  templateUrl: './list-of-contests.component.html',
  styleUrls: ['./list-of-contests.component.scss']
})
export class ListOfContestsComponent implements OnInit {

  userId: any;
  currentPage: number = 1;
  pageSize: number = 10;
  numOfElements: any;
  contestListingArr: any;
  errData: any;
  noData: boolean;

  constructor(private _uiRouter: UIRouter, private _httpService: HttpService) {
    this.userId = this._uiRouter.stateService.params.userId;
    console.log('userId',this.userId);
    this.loadContestListing();
  }

  ngOnInit() {
    // this.loadContestListing();
  }

  loadContestListing(){
    let contestListingRequestObj = {
      "userId": parseInt(this.userId),
      "currentPage": this.currentPage,
      "pageSize": this.pageSize
    }
    this._httpService.httpRequest('POST', 'contestListingByUserId', contestListingRequestObj, true)
    .then(Response => {
      console.log('its success');
      let responseData: any = Response;
      this.contestListingArr = responseData.response.data;
      console.log('this.contestListingArr',this.contestListingArr);
      if(this.contestListingArr.length == 0){
        this.noData = true;
      }
      this.numOfElements = responseData.response.pagination.numberOfElements;
    //   console.log('response',response);
    })
    .catch(data => {
        console.log('its error');
        this.errData = data;
      console.log('error',data);
    })
  }

  pageChanged(event){
    this.currentPage = event;
    this.loadContestListing();
    console.log('this.currentPage',this.currentPage);
  }

  listOfEntrants(contestId){
    this._uiRouter.stateService.go('list-of-entrants',{'contestId':contestId,'leaugeStatus':'history'});
  }

}
