import { Component, OnInit } from '@angular/core';
import { Http, Headers, Response } from '@angular/http';
import { FormGroup, FormBuilder, Validators, FormsModule } from '@angular/forms';
import { StateService, UIRouter } from '@uirouter/angular';
import { Moment } from 'moment';
import { DatePipe } from '@angular/common';

// service
import { UserDetailsService } from '../services/user-details.service';
import { HttpService } from '../services/http.service';
import { HttpClientModule, HttpRequest } from '@angular/common/http';

// component


import { SERVER_NAME, APIS } from '../config';

declare const moment: any


@Component({
    selector: 'app-landing-pages',
    templateUrl: './landing-pages.component.html',
    styleUrls: ['./landing-pages.component.scss'],
    providers: [HttpService] // ,DatePipe]
})

export class LandingPageComponent {

    // createLandingPageForm: FormGroup;



    name: string;
    type: string;
    textbox1: string;
    textbox2: string;
    textbox3: string;
    textbox4: string;
    textbox5: string;
    textbox6: string;
    textbox7: string;
    textbox8: string;
    titleBox: string;
    image: string;
    playNowText: string;
    playNowColor: string;
    bottomText: boolean;
    disclaimerText: string;

    constructor(private _httpService: HttpService, private _userDetailsService: UserDetailsService) { }

    onSubmit() {
        console.log('Landing Page Info: ', this.name, this.type, this.textbox1, this.textbox2,
            this.textbox3,
            this.textbox4,
            this.textbox5,
            this.textbox6,
            this.textbox7,
            this.textbox8,
            this.image,
            this.playNowText,
            this.playNowColor,
            this.disclaimerText,
            this.titleBox
        );

        if (this.name === undefined || this.name === null || this.name.length === 0 || this.name === '') {
            this._userDetailsService.showError('Landing Page Name Cannot be Empty');
            return;
        }
        if (this.type === undefined || this.type === null || this.type.length === 0 || this.type === '') {
            this._userDetailsService.showError('Landing Page Type Must be Selected');
            return;
        }
        if (this.type === 'B' && (this.textbox4 !== undefined || this.textbox5 !== undefined || this.textbox6 !== undefined || this.textbox7 !== undefined || this.textbox8 !== undefined)) {
            this._userDetailsService.showError('You May Only Enter Textboxes 1, 2, and 3 for Type B');
            return;
        }
        let requestData = {
            "name": this.name,
            "type": this.type,
            "textbox1": this.textbox1,
            "textbox2": this.textbox2,
            "textbox3": this.textbox3,
            "textbox4": this.textbox4,
            "textbox5": this.textbox5,
            "textbox6": this.textbox6,
            "textbox7": this.textbox7,
            "textbox8": this.textbox8,
            "image": this.image,
            "playNowText": this.playNowText,
            "playNowColor": this.playNowColor,
            "disclaimerText": this.disclaimerText,
            "titleBox": this.titleBox
        }


        this._httpService.httpRequest('POST', 'newLandingPage', requestData, true)

            .then(Response => {
                // this._userDetailsService.showSucccess(Response.response.message);
                this._userDetailsService.showSuccess(Response['message']);
                console.log(Response);

            })
            .catch(data => {
                this._userDetailsService.showError(data.message);
            })

    }
}