import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder , Validators} from '@angular/forms';

import { SERVER_NAME, APIS, DateShowFormat, DateFormat } from '../config';
import { HttpService } from '../services/http.service';
import { UIRouter } from '@uirouter/angular';
import { DatePipe } from '@angular/common';
import { Moment } from 'moment';
import 'eonasdan-bootstrap-datetimepicker';
import { UserDetailsService } from '../services/user-details.service';
import { NKDatetimeModule } from "ng2-datetime/ng2-datetime";

// declare const window:any;

// CommonJS style - working with "require"
declare const $:any;
//declare const moment:any;

@Component({
  selector: 'app-create-contest-similars',
  templateUrl: './create-contest-similars.component.html',
  styleUrls: ['./create-contest-similars.component.scss'],
  providers: [HttpService,DatePipe]
})
export class CreateContestSimilarsComponent implements OnInit {
  createContestForm: FormGroup;
  contestId : any;

  constructor(private _httpService: HttpService, private fb: FormBuilder,private datePipe: DatePipe,private _uiRouter:UIRouter,private _userDetailsService: UserDetailsService) { 
   
  }

  ngOnInit() {
    
    this.createContestForm = this.fb.group({
      contestId : ['',[]],
  
    })

    $('.copyDisable').bind("cut copy paste",function(e) {
        e.preventDefault();
    });   
  }

  createContestFormSubmit(val){           
      let requestData = {
        "contestId" : val.contestId,
      }
      
      this._httpService.httpRequest('POST', 'createContestSimilars',requestData, true)
      .then(Response => {
        let responseData : any = Response;

        this._uiRouter.stateService.go('price-payload',{
          'contestId': this.contestId,
        });
      })
      .catch(data => {
        this._userDetailsService.showError(data.message);
      })
  }
}
