import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder , Validators} from '@angular/forms';
import { UIRouter } from '@uirouter/angular';
import { HttpService } from '../services/http.service';

import {UserDetailsService} from '../services/user-details.service';
import {LoginComponent} from '../login/login.component';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss']
})
export class ForgetPasswordComponent implements OnInit {
  forgetPasswordForm : FormGroup;
  constructor(
      private fb: FormBuilder, 
      private _uiRouter: UIRouter, 
      private _httpService: HttpService,
      private _userDetailsService : UserDetailsService) { }

  ngOnInit() {
    this.forgetPasswordForm = this.fb.group({
			email : ['', [Validators.required, Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]]
		});
  }

  forgetPasswordSubmit(data){
    console.log('data',data);
    this._httpService.httpRequest('POST','forgetPassword',data,true)
    .then(Response => {
      console.log('success data', Response);
      let responseData :any = Response;
      this._userDetailsService.showSuccess(responseData.message);
      this._uiRouter.stateService.go('login');
    })
    .catch(data => {
      this._userDetailsService.showError(data.message);
    })
  }

  setfocustrue(){
    this.forgetPasswordForm.get("email").markAsTouched({onlySelf: true});
  }

}
