import { Component, OnInit, ViewChild} from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Moment } from 'moment';
import { FormGroup, FormBuilder , Validators} from '@angular/forms';

import { HttpService } from '../services/http.service';

@Component({
  selector: 'app-user-tickets-by-house-tickets-modal',
  templateUrl: './user-tickets-by-house-tickets-modal.component.html',
  styleUrls: ['./user-tickets-by-house-tickets-modal.component.css']
})
export class UserTicketsByHouseTicketsModalComponent implements OnInit {
	@ViewChild('userTicketsByHouseTicketsModal') userTicketsByHouseTicketsModal: ModalDirective;
  constructor(
  	private _httpService: HttpService
  	) { }

  userHouseTickets = []
  ngOnInit() {
  }


  show(houseTicketId) {
    this.userTicketsByHouseTicketsModal.show();
    this.getUserHouseTicketsByHouseTicket(houseTicketId);
  }

  closeModal(){
    this.userTicketsByHouseTicketsModal.hide();
  }

  getUserHouseTicketsByHouseTicket(houseTicketId){
    this._httpService.httpRequest('GET','getUserTicketsByHouseTicket', "houseTicketId=" + houseTicketId, true)
    .then(Response => {
      let responseData : any = Response;
      this.userHouseTickets = responseData.response;
    })
  }
}