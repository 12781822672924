import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder , Validators} from '@angular/forms';
import { StateService, UIRouter } from '@uirouter/angular';
import { SERVER_NAME, APIS } from '../config';
import { HttpService } from '../services/http.service';
import { UserDetailsService } from '../services/user-details.service';
import { DatePipe } from '@angular/common';


// modal
import { PropsModalSingleComponent } from '../props-modal-single/props-modal-single.component';
declare const $:any;
@Component({
  selector: 'app-team-listing-prop',
  templateUrl: './team-listing-prop.component.html',
  styleUrls: ['./team-listing-prop.component.scss'],
  providers: [HttpService,DatePipe]
})
export class TeamListingPropComponent implements OnInit {
  teamlistForm: FormGroup;
  startTime: any;
  endTime:any;
  leagueType: any;
  eventsData : any;
  playersData : any;
  teamName : any;
  golfRound : any;
  eventPropsdata : any;
  noDataMsg:string;
  adminContestId : any;
  teamid : any;
  createdPropsTeamArr = [];
  unpublishedHouseProps = [];
  eventDateToShow : any;

  @ViewChild('propModalSingle') propModalSingle : PropsModalSingleComponent;

  constructor(private _httpService: HttpService, 
  private fb: FormBuilder, 
  private _uiRouter: UIRouter,
  private _userDetailsService: UserDetailsService,
  private datePipe : DatePipe) {

    this.leagueType = this._uiRouter.stateService.params.leagueType;
    this.startTime = this._uiRouter.stateService.params.startTime;
    this.endTime = this._uiRouter.stateService.params.endTime;
    this.eventDateToShow = this.datePipe.transform(this.startTime,"MMM dd, yyyy")


    this.adminContestId = this._uiRouter.stateService.params.adminContestId;

  }

  ngOnInit() {
    this.teamlistForm = this.fb.group({
      pl1: ['', []],
      pl2: ['', []],
      pl3: ['', []]

    })

    let requestData = {
      "adminContestId":this.adminContestId,
      "leagueType":this.leagueType,
      "currentPage":1,
      "currentSize":100,
      "startDate":this.startTime,
      "endDate":this.endTime
    }
 
    // ************************** Event Liting *************************
    this._httpService.httpRequest('POST', 'eventsListing',requestData, true)
      .then(Response => {
        let responseData :any = Response;
        if(responseData.response.data.length > 0){
          this.leagueType = responseData.response.data[0].leagueType;
          this.eventsData = responseData.response.data;
        }else{
          this.noDataMsg = "No event is scheduled on this date.";
        }
      })
      .catch(data => {
        this.noDataMsg = data.message;
    })
    // ************************** Event Liting *************************
    this.fetchUnpublishedHouseProps();
    
    if(window.localStorage.getItem("teamid")){
      //this.getTeamPlayer(window.localStorage.getItem("teamid"),window.localStorage.getItem("teamname"),window.localStorage.getItem("eventindex"));
      this.playersData = JSON.parse(window.localStorage.getItem("playersData"));
      this.teamName = window.localStorage.getItem("teamname");
      this.teamid = window.localStorage.getItem("teamid");
      this.eventPropsdata = JSON.parse(window.localStorage.getItem("eventPropsdata"));
    }

    $('#propModal').on('hide', function () {
      console.log("into props modal");
    });

    $('#propModal').on('hidden', function () {
      console.log("into props modal 2");
    });
  }

  deleteHouseProp(propId){
    this._httpService.httpRequest('DELETE', 'deleteHouseProp',"propId="+propId, true)
      .then(Response => {
        let responseData :any = Response;
        this._userDetailsService.showSuccess(responseData.message);
        this._uiRouter.stateService.reload();
      })
      .catch(data => {
        this._userDetailsService.showError(data.message);
    })
  }

  getTeamPlayer(teamid, sportradarTeamId, pandascoreTeamId, abiosTeamId, eventId, teamname, eventIndex){

    this.eventPropsdata = this.eventsData[eventIndex];
    this.teamid= teamid;
    console.log("------calling getTeamPlayerNormal teamId: ",teamid," sportradarTeamId: ",sportradarTeamId," pandascoreTeamId: ",pandascoreTeamId," abiosTeamId: ",abiosTeamId," league: ",this.leagueType);

    if(this.leagueType == "NFL" || this.leagueType == "MLB" || this.leagueType == "NBA" || this.leagueType == "SOCCER" || this.leagueType == "AUDL" || this.leagueType == "CRICKET" || this.leagueType == "HOCKEY" || this.leagueType === "UFC" || this.leagueType === "CBK"){
      this._httpService.httpRequest('POST', 'playerListing',{"leagueType":this.leagueType,"currentPage":1,"currentSize":100,"teamId":teamid}, true)
        .then(Response => {

          let responseData :any = Response;
          this.teamName = teamname;
          this.playersData = responseData.response.data;
          window.localStorage.setItem("teamid",teamid);
          window.localStorage.setItem("teamname",teamname);
          window.localStorage.setItem("playersData",JSON.stringify(this.playersData));
          window.localStorage.setItem("eventPropsdata",JSON.stringify(this.eventPropsdata));
        })
        .catch(data => {
          console.log('team list error', data);
          this.noDataMsg = "No record found";
      })
    }else if(this.leagueType == "CSGO2" || this.leagueType == "LOL" || this.leagueType == "DOTA2" || this.leagueType == "VAL" || this.leagueType == "SLG_CSGO"){
      this._httpService.httpRequest('POST', 'esportsPlayerListing',{"leagueType":this.leagueType,"currentPage":1,"currentSize":100,"sportsRadarTeamId":sportradarTeamId}, true)
        .then(Response => {

          let responseData :any = Response;
          this.teamName = teamname;
          this.playersData = responseData.response.data;
          window.localStorage.setItem("teamid",teamid);
          window.localStorage.setItem("teamname",teamname);
          window.localStorage.setItem("playersData",JSON.stringify(this.playersData));
          window.localStorage.setItem("eventPropsdata",JSON.stringify(this.eventPropsdata));
        })
        .catch(data => {
          console.log('team list error', data);
          this.noDataMsg = "No record found";
      })
    }else if(this.leagueType == "OW"){
      this._httpService.httpRequest('POST', 'esportsPlayerListing',{"leagueType":this.leagueType,"currentPage":1,"currentSize":100,"pandascoreTeamId":pandascoreTeamId}, true)
        .then(Response => {

          let responseData :any = Response;
          this.teamName = teamname;
          this.playersData = responseData.response.data;
          window.localStorage.setItem("teamid",teamid);
          window.localStorage.setItem("teamname",teamname);
          window.localStorage.setItem("playersData",JSON.stringify(this.playersData));
          window.localStorage.setItem("eventPropsdata",JSON.stringify(this.eventPropsdata));
        })
        .catch(data => {
          console.log('team list error', data);
          this.noDataMsg = "No record found";
      })
    }else if(this.leagueType == "GOLF"){
      this._httpService.httpRequest('POST', 'golfPlayerListing',{"leagueType":this.leagueType,"currentPage":1,"currentSize":200,"sportsRadarTeamId":sportradarTeamId,"golfRound":this.eventPropsdata.golfRound,"eventId":eventId}, true)
        .then(Response => {

          let responseData :any = Response;
          this.teamName = teamname;
          this.golfRound = "0";
          if (responseData.response.data[0] != null){
            this.golfRound = responseData.response.data[0].golfRound;
          }
          this.playersData = responseData.response.data;
          window.localStorage.setItem("teamid",teamid);
          window.localStorage.setItem("teamname",teamname);
          window.localStorage.setItem("playersData",JSON.stringify(this.playersData));
          window.localStorage.setItem("eventPropsdata",JSON.stringify(this.eventPropsdata));
        })
        .catch(data => {
          console.log('team list error', data);
          this.noDataMsg = "No record found";
      })
    }else if(this.leagueType == "VAL"){
      this._httpService.httpRequest('POST', 'esportsPlayerListing',{"leagueType":this.leagueType,"currentPage":1,"currentSize":100,"abiosTeamId":abiosTeamId}, true)
        .then(Response => {

          let responseData :any = Response;
          this.teamName = teamname;
          this.playersData = responseData.response.data;
          window.localStorage.setItem("teamid",teamid);
          window.localStorage.setItem("teamname",teamname);
          window.localStorage.setItem("playersData",JSON.stringify(this.playersData));
          window.localStorage.setItem("eventPropsdata",JSON.stringify(this.eventPropsdata));
        })
        .catch(data => {
          console.log('team list error', data);
          this.noDataMsg = "No record found";
      })
    }
  }

  fetchUnpublishedHouseProps() {
    this.unpublishedHouseProps = []
    this._httpService.httpRequest('GET', 'fetchUnpublishedHouseProps', true)
      .then(Response => {
        let responseData: any = Response;
        for (let unpublishedHouseProp of responseData.response) {
          this.unpublishedHouseProps.push(unpublishedHouseProp)
        }
      })
      .catch(data => {
        this._userDetailsService.showError(data.message);
      })
  }

  publishAllHouseProps(){
      this._httpService.httpRequest('POST', 'publishUnpublishedHouseProps', {})
        .then(Response => {
          let responseData :any = Response;
          if(responseData.response){
              this._userDetailsService.showSuccess(responseData.message);
              this._uiRouter.stateService.reload();
          }else{
            this._userDetailsService.showError(responseData.message);
          }
        })
        .catch(data => {
          this._userDetailsService.showError(data.message);
      })
  }

  hideModal(){
    alert("coming")
  }
}