import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { SERVER_NAME, APIS, DateShowFormat, DateFormat } from '../config';
import { HttpService } from '../services/http.service';
import { UIRouter } from '@uirouter/angular';
import { DatePipe } from '@angular/common';
import { Moment } from 'moment';
import 'eonasdan-bootstrap-datetimepicker';
import { UserDetailsService } from '../services/user-details.service';
import { NKDatetimeModule } from "ng2-datetime/ng2-datetime";
// CommonJS style - working with "require"
declare const $: any;
//declare const moment:any;

@Component({
  selector: 'app-create-prop',
  templateUrl: './create-prop.component.html',
  styleUrls: ['./create-prop.component.scss'],
  providers: [HttpService, DatePipe]
})
export class CreatePropComponent implements OnInit {
  createContestForm: FormGroup;
  leagueVal: any;
  value: Date;
  contestTypeVal: any;
  multipleEntry: boolean = false;
  passwordEntry: boolean = false;
  featured: boolean = false;
  dateError: boolean = false;
  propPicked: any;
  propPickedZeroError: boolean;
  propAvailable: any;
  propAvailableZeroError: boolean;
  propError: boolean = false;
  prizeError: boolean = false;
  // entryAllowError : boolean = false;
  guaranteedPrizeMoney: any;
  guarantyPrizeZeroError: boolean;
  entryFee: any;
  entryFeeZeroError: boolean;
  maxEntriesAllowed: any;
  startTime: any;
  endTime: any;
  maxEntryZeroError: boolean;
  entryNumber: any;
  password: any;
  entryPerUserZeroError: any;
  maxEntryError: boolean;
  passwordError: boolean;
  //currentDate = new Date(new Date().getTime() + (3600 * 1000));
  currentDate = new Date();
  currentDateError: boolean = false;
  leagueType: any;
  guarantyPrizeMultiplication: boolean = false;
  maxEntryPerUser: boolean = false;

  date: Moment;
  a2eOptions: any;
  dateFrom: Date = new Date();

  dateChange(date) {
    this.date = date;
  }
  startDateToChange(date) {
    //console.log("start:" + date);
    //console.log(date)
    this.startTime = this._httpService.convertToUTC(date, "MM/DD/YYYY HH:mm:ss");
    console.log("startTime: " + this.startTime);

  }
  dateClick() {
    // console.log('start click!')
  }

  getTime() {
    alert('Selected time is:' + this.date);
  };

  addTime(val, selector) {
    //this.date = moment(this.date.add(val, selector));
  };

  clearTime() {
    this.date = null;
  };

  endDateToChange(date) {
    //console.log("end:" + date);
    this.endTime = this._httpService.convertToUTC(date, "MM/DD/YYYY HH:mm:ss");
    //console.log("endtime: " + this.endTime);

  }
  dateEndClick() {
    //console.log('click end!')
  }

  datepickerOpts = {
    startDate: new Date(),
    autoclose: true,
    todayBtn: 'linked',
    todayHighlight: true,
    assumeNearbyYear: true,
    format: 'M/dd/yyyy'
  }



  dateTo: Date = new Date();
  datepickerToOpts = {
    startDate: this.dateFrom,
    autoclose: true,
    todayBtn: 'linked',
    todayHighlight: true,
    assumeNearbyYear: true,
    format: 'M/dd/yyyy'
  }

  multidayContest: boolean = false;

  constructor(private _httpService: HttpService, private fb: FormBuilder, private datePipe: DatePipe, private _uiRouter: UIRouter, private _userDetailsService: UserDetailsService) {
    this.leagueVal = "MLB";
    this.contestTypeVal = "Traditional";
    //this.date = moment();
    this.a2eOptions = { format: 'YYYY/MM/DD HH:mm:ss' };
  }


  binarySum(num) {

    var temp = 0;

    while (num > 0) {
      temp = num % 2 + temp;
      num >>= 1;
    }

    return (temp);
  }


  ngOnInit() {





    //console.log("zar: " + this.binarySum(31));



    this.createContestForm = this.fb.group({
      leagueType: ['', []],
      type: ['', []],
      name: ['', [Validators.required]],
      startTime: ['', [Validators.required]],
      multidate: ['',],
      endTime: ['',],
      summary: ['', [Validators.required]],
      generalInfo: ['', [Validators.required]],
      entryFee: ['', [Validators.required]],
      multipleEntryAllowed: ['', []],
      passwordEntryAllowed: ['', []],
      maxEntriesPerUser: ['', []],
      passwordForContest: ['', []],
      guaranteedPrizeMoney: ['', [Validators.required]],
      createdPropCount: [''],
      minPropsToPick: ['', [Validators.required]],
      maxEntriesAllowed: ['', [Validators.required]],
      featured: ['', []]
    })


    $('.copyDisable').bind("cut copy paste", function (e) {
      e.preventDefault();
    });


  }

  createContestFormSubmit(val) {

    this.dateError = false;

    this.currentDateError = false;

    var ONE_HOUR = 60 * 60 * 1000;


    // else if(parseInt(this.propPicked) >= parseInt(this.propAvailable)){
    //   this.propError = true;
    // }
    // else if (this.propAvailable < 3){
    //   this.propAvailableZeroError = true;
    // }
    if (this.dateFrom < this.currentDate) {
      this.currentDateError = true;
    }
    // else if(parseInt(this.maxEntriesAllowed) > 10000){
    //    this.entryAllowError = true;
    // }



    /* Brendon Brinkmann 2-6-18 commented out guaranteedPrizeMoney validation
    else if ((parseInt(this.maxEntriesAllowed) * parseInt(this.entryFee)) < this.guaranteedPrizeMoney){
      this.guarantyPrizeMultiplication = true;
    }
    */
    //brendon brinkmann 2-6-18 updated guarantyPrizeMultiplication validation 
    /*else if ((parseInt(this.maxEntriesAllowed) * parseInt(this.entryFee)) <= this.guaranteedPrizeMoney){
      this.guarantyPrizeMultiplication = true;
    }*/


    // else if(this.dateTo && (new Date(this.dateTo) <= new Date(this.dateFrom))){
    //   this.dateError = true;
    // }
    else {

      // var startTime  = moment().format('MM/dd/yyyy HH:mm:ss');
      // var endTime  = moment().format('MM/dd/yyyy HH:mm:ss');

      //let startTimeEST = moment.utc(val.startTime).utcOffset(-4).format('MM/DD/YYYY HH:mm:ss');
      // console.log(startTimeEST);
      // let endTimeEST = moment.utc(val.endTime).utcOffset(-4).format('MM/DD/YYYY HH:mm:ss');
      let startTimeUTC = this._httpService.convertToUTC(val.startTime, "MM/DD/YYYY HH:mm:ss");
      let endTimeUTC = this._httpService.convertToUTC(val.endTime, "MM/DD/YYYY HH:mm:ss");

      //console.log("old endtime: " + val.endtime);
      //console.log("old end time:" + endTimeUTC);



      let requestData = {
        "leagueType": this.leagueVal,

        "startTime": this.startTime
      }


      this._uiRouter.stateService.go('team-listing-prop', {
        'startTime': this.datePipe.transform(this.startTime, "yyyy-MM-dd"),
        'endTime': val.endTime ? this.datePipe.transform(val.endTime, "yyyy-MM-dd") : this.datePipe.transform(val.startTime, "yyyy-MM-dd"),
        'leagueType': this.leagueVal,
        'adminContestId': 1
      });
      //console.log("requestData.multiDayEndDateStr" + requestData.multiDayEndDateStr);

      //console.log('request data', requestData);
      /*      this._httpService.httpRequest('POST', 'createContest',requestData, true)
            .then(Response => {
              //console.log('response',Response);
              let responseData : any = Response;
      
              this._uiRouter.stateService.go('price-payload',{
                //'startTime' : this._httpService.convertToUTC(val.startTime,DateFormat),
                'startTime':this.datePipe.transform(val.startTime,"yyyy-MM-dd"),
                'endTime':val.endTime?this.datePipe.transform(val.endTime,"yyyy-MM-dd"):this.datePipe.transform(val.startTime,"yyyy-MM-dd"),
                'leagueType': this.leagueVal
              });
            })
            .catch(data => {
              //console.log('create contest error', data);
              this._userDetailsService.showError(data.message);
            })*/
    }
  }

  leagueCheckFun(val) {
    console.log('league val', val);
    this.leagueVal = val;
  }

  contestTypeFun(val) {
    //console.log('contestType', val);
    this.contestTypeVal = val;
  }

  startDateChange(event) {

    this.datepickerToOpts = {
      startDate: new Date(event),
      autoclose: true,
      todayBtn: 'linked',
      todayHighlight: true,
      assumeNearbyYear: true,
      format: 'M/dd/yyyy'
    }
    this.dateTo = new Date(event);
  }

  updateCheckedOptions(event) {
    //console.log(event.target.checked);
    if (event.target.checked) {
      this.multidayContest = true;
    } else {
      this.multidayContest = false;
    }
  }
}