import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SERVER_NAME, APIS } from '../config';
import { HttpService } from '../services/http.service';
import { UIRouter } from '@uirouter/angular';
import { DatePipe } from '@angular/common';
import { UserDetailsService } from '../services/user-details.service';

declare const $: any;

@Component({
  selector: 'app-send-notification',
  templateUrl: './send-notification.component.html',
  styleUrls: ['./send-notification.component.scss'],
  providers: [HttpService, DatePipe]
})

export class SendNotificationComponent implements OnInit {
  sendNotificationForm: FormGroup;
  notificationError: any;
  templateMessage = 'Enter Message here'
  constructor(private _httpService: HttpService, private fb: FormBuilder, private _userDetailsService: UserDetailsService) {

  }

  ngOnInit() {
    this.sendNotificationForm = this.fb.group({
      notificationText: [this.templateMessage, []],
    })
  }

  sendNotificationFormSubmit(val, batch) {
    let notificationTxt = $.trim(val.notificationText);
    if (!notificationTxt || notificationTxt == "") {
      this.notificationError = "Plese enter notification text";
    }
    else {
      this.templateMessage = notificationTxt
      let requestData = {
        "message": notificationTxt,
        "batch": batch
      }
      this._httpService.httpRequestForStagingEnvironment('POST', 'sendNotification', requestData, true)
        .then(Response => {
          let responseData: any = Response;

          this.sendNotificationForm = this.fb.group({
            notificationText: [this.templateMessage, []],
          })

          this._userDetailsService.showSuccess(responseData.message);
        })
        .catch(data => {
          console.log('create contest error', data);
        })
    }
  }
}
