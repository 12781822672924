import { Component, OnInit } from '@angular/core';
import { StateService, UIRouter } from '@uirouter/angular';
import { Http, Headers, Response } from '@angular/http';
import { FormGroup, FormBuilder , Validators} from '@angular/forms';
import { Moment } from 'moment' ;

import { UserDetailsService } from '../services/user-details.service';
import { HttpService } from '../services/http.service';

@Component({
  selector: 'contest-tickets',
  templateUrl: './contest-tickets.component.html',
  styleUrls: ['./contest-tickets.component.css']
})
export class ContestTicketsComponent implements OnInit {
  unusedUserContestTickets: any[] = [];
  constructor(
  	private _http: Http, 
    private _userDetails: UserDetailsService,
    private _httpService: HttpService,
    private fb: FormBuilder,
  	private _uiRouter: UIRouter
  ) {}

  isUserContestTicketDisplay = true
  contestTickets=[]

  ngOnInit() {
    this.getUnusedUserContestTickets(1)
    this.getContestTickets()
  }

  wasUsed(boolVal){
    if(boolVal===true){
      return "Y"
    } else {
      return "N"
    }
  }

  disableUserContestTicket(userContestTicketId){
    userContestTicketId = String(userContestTicketId)
    this._httpService.httpRequest('GET','disableUserContestTicket', "userContestTicketId="+userContestTicketId, true)
    .then(Response => {
      let responseData : any = Response;
      location.reload()
      alert(responseData.message)
      console.log(this.unusedUserContestTickets[0]["username"])

    })
  }

  toggleListDisplay(){
    if(this.isUserContestTicketDisplay){
      var hideList = document.getElementById("userContestTickets");
      var showList = document.getElementById("contestTickets");
      this.isUserContestTicketDisplay = false;
    } else {
      var hideList = document.getElementById("contestTickets");
      var showList = document.getElementById("userContestTickets");
      this.isUserContestTicketDisplay = true;
    }
    hideList.style.display = "none"
    showList.style.display = "block"
  }

  getUnusedUserContestTickets(page){
    this._httpService.httpRequest('GET','getUserContestTickets', {"page": page }, true)
    .then(Response => {
      let responseData : any = Response;
      this.unusedUserContestTickets = responseData.response.response;
      console.log(this.unusedUserContestTickets[0]["username"])
    })

    this.unusedUserContestTickets.forEach(e=>{
      e.expirationDate = new Date(e.expirationDate);
    })
    console.log("unusedTickets: " + this.unusedUserContestTickets )
  }

  getContestTickets(){
    this._httpService.httpRequest('GET','getAllContestTickets', "", true)
    .then(Response => {
      let responseData : any = Response;
      this.contestTickets = responseData.response;
    })
  }
}