import { Component, OnInit } from '@angular/core';
import { HttpService } from '../services/http.service';
import { UserDetailsService } from '../services/user-details.service';

@Component({
  selector: 'app-delete-user-requests',
  templateUrl: './delete-user-requests.component.html',
  styleUrls: ['./delete-user-requests.component.scss']
})
export class DeleteUserRequestsComponent implements OnInit {
  registeredUserDetail: any;
  pagination: any;
  currentPage: number = 1;
  pageSize: number = 10;
  userName: any = "";
  noData: boolean = false;

  constructor(private httpService: HttpService, private _userDetailsService : UserDetailsService) {}

  ngOnInit() {
    this.registeredFanList();
  }

  registeredFanList(){
    this.noData = false;
    let requestPayload: any = {
            "pageNo":this.currentPage,
            "pageSize":this.pageSize,
            "userName" : this.userName,
          }
          
    this.httpService.httpRequest('POST','getDeleteUserRequests',requestPayload,false)
    .then(Response => {
      this.noData = false;
      let response: any = Response;
      this.pagination = response.response.pagination;
      this.registeredUserDetail = response.response.data;
      if(this.registeredUserDetail == ""){
        console.log('no records found');
        this.noData = true;
      }
    })
    .catch(data => {
      console.log('error', data);
    })
  }

  pageChanged($event, val){
    this.currentPage = $event;
    this.registeredFanList(); 
  }

  searchFanFun(searchChar){
    searchChar = $.trim(searchChar);
    this.userName = searchChar;
    this.registeredFanList();
  }

  rejectRequest(userId){
    let confirmRejection = confirm("Are you sure you want to reject this user's delete request");
    if(confirmRejection == true){
      let requestObj = {
        "userId" : userId
      }
      this.httpService.httpRequest('POST','rejectDeleteUserRequest',requestObj,true)
      .then(Response => {
        let response: any = Response;
        if (response.success){
          this._userDetailsService.showSuccess(response.message);
          this.registeredFanList();
        } else {
          this._userDetailsService.showError(response.message);
          console.log('Error: ',response);
        }
      })
      .catch(response => {
        this._userDetailsService.showError(response.message);
        console.log('Error: ',response);
      })
    }
  }

  deleteUser(userId){
    let confirmDelete = confirm('Are you sure you want to delete this user');
    if(confirmDelete == true){
      let requestObj = {
        "userId" : userId
      }
      this.httpService.httpRequest('POST','deleteRegisterdFan',requestObj,true)
      .then(Response => {
        let response: any = Response;
        if (response.success){
          this._userDetailsService.showSuccess(response.message);
          this.registeredFanList();
        } else {
          this._userDetailsService.showError(response.message);
          console.log('Error: ',response);
        }
      })
      .catch(response => {
        this._userDetailsService.showError(response.message);
        console.log('Error: ',response);
      })
    }
  }
}
