import { Injectable } from '@angular/core';
import { Headers } from '@angular/http';
declare const $: any;

@Injectable()
export class UserDetailsService {
  accessToken : any = "eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJkdW1teSIsImF1ZGllbmNlIjoiSU9TIiwicGFzcyI6bnVsbCwiY3JlYXRlZCI6MTQ5NDQ5MjM0MDU5NiwiZXhwIjoxNDk1MDk3MTQwfQ.Au3WmeALu5WM3cfj17bPGrR9Iwiksrxgs234kVxttrqX18-uqBSCPPMiAMvM91OIQbewIkNp9SLIzH2_H6Ka-g";
  constructor() {
    console.log('user detail constructor');
   }

   static setUserDetails(data){
     window.sessionStorage.setItem('userData',JSON.stringify(data));
   }

   static getUserDetails(){
     JSON.parse(window.sessionStorage.getItem('userData'))
   }

   static removeUserCredentials(){
    window.sessionStorage.clear();
   }

   getHeader(){
     const headers = new Headers();
     headers.append('Content-Type','application/json');
     headers.append('Access-Control-Allow-Origin', '*');
    //  if(UserDetailsService.getUserDetails() != null){
      //  headers.append('Token', UserDetailsService.getUserDetails().accessToken);
       headers.append('Token',this.accessToken);
       console.log('headers',headers);
    //  }
    return headers;
   }

   getAccessToken(){
     return this.accessToken;
   }

   showError(error: string){
        let snackbar=$("#snackbar");
        snackbar.css({
                    "background-color":" #8e0000"
        });
        snackbar.html(error);
        snackbar.fadeIn();
        setTimeout(function(){
                    snackbar.fadeOut();
                }, 2500);

    }
    /**showSuccess() shows error snackbar
     * @params success: string
     */
    showSuccess(success: string){
        let snackbar=$("#snackbar");
        snackbar.css({
                    "background-color":" #008e75"
        });
        snackbar.html(success);
        snackbar.fadeIn();
        setTimeout(function(){
                    snackbar.fadeOut();
                }, 2500);

    }

}
