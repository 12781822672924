import { Component, OnInit } from '@angular/core';
import { Http, Headers, Response } from '@angular/http';
import { StateService, UIRouter } from '@uirouter/angular';
import { Router, ActivatedRoute } from '@angular/router';


import { ListOfEntrantsComponent } from '../list-of-entrants/list-of-entrants.component';

import { PricePayoutComponent } from '../price-payout/price-payout.component';


// data table


// service
import { UserDetailsService } from '../services/user-details.service';
import { HttpService } from '../services/http.service';

import {SERVER_NAME, APIS} from '../config';

import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import {FormGroup} from "@angular/forms";

@Component({
  selector: 'app-contest-listing',
  templateUrl: './contest-order.component.html',
  styleUrls: ['./contest-order.component.scss'],
  providers: [UserDetailsService, HttpService]
})



export class ContestOrderComponent implements OnInit {
    contestListData: any;
  currentPage: number = 1;
  collection: any[] = [];
  numberOfItem: number;
  totalPages: number;
  itemsPerPage: number= 50;
  contestId: any;
  currentDate = new Date();

  prioritizedContests = []
    normalContests = []


  constructor(
    private _http: Http,
    private _userDetailsService: UserDetailsService,
    private _httpService: HttpService,
    private _uiRouter: UIRouter
    )
  {

  }

  ngOnInit() {
    console.log('get header call',this._userDetailsService.getHeader());
    console.log('SERVER_NAME',SERVER_NAME);
    window.localStorage.removeItem("teamid");
    window.localStorage.removeItem("teamName");
    window.localStorage.removeItem("playersData");
    this.contestListingData();
  }

  contestListingData(){
    let listingData: any = {
            "leagueType":null,
            "contestType":null,
            "featured":"0",
            "currentPage" :this.currentPage,
            "currentSize": this.itemsPerPage
    }

    this._httpService.httpRequestLambdaCombo(
        "POST",
        "contests/getUpcomingContests",
        listingData,
        false,
        false,
        {},
    )
    .then(Response => {
      let data: any = Response['response'].data;
      this.numberOfItem = Response['response'].pagination.numberOfElements;
      this.totalPages = Response['response'].pagination.totalPages;
      this.collection = data;



      for(let contest of this.collection) {
          if(contest['priority'] !== null) {
              this.prioritizedContests.push([contest['contestId'],"     " + contest['name'], "     " + contest['totalEntries'] + "/" + contest['maxEntriesAllowed'] + " Entries"])
          }
          else if(contest['priority'] === null) {
              this.normalContests.push([contest['contestId'],"     " + contest['name'], "     " + contest['totalEntries'] + "/" + contest['maxEntriesAllowed'] + " Entries"])
          }
      }


    })
    .catch(data => {

    })
  }

  pageChanged($event){
    console.log("$event", $event);
    this.currentPage = $event;
    this.contestListingData();
  }

  contestlistClickFun(contestVal){
    this.contestId = contestVal.contestId;
  }

    async updateContestOrder() {
      if(confirm("Are you sure you want to update the contest order?")) {
        this._httpService.httpRequestLambdaCombo(
          "POST",
          "contests/updateContestOrder",
          [this.prioritizedContests, this.normalContests],
          false,
          false,
          {},
        )
        .then(Response => {
            let data: any = Response['response'].data;
            this.numberOfItem = Response['response'].pagination.numberOfElements;
            this.totalPages = Response['response'].pagination.totalPages;
            this.collection = data;

        })
        .catch(data => {

        })
      }
    }

    prioritized = this.prioritizedContests
    normal = this.normalContests

    drop(event: CdkDragDrop<string[]>) {
        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        } else {
            transferArrayItem(
                event.previousContainer.data,
                event.container.data,
                event.previousIndex,
                event.currentIndex,
            );
        }
        // console.log(this.prioritized)
        // console.log(this.normal)
    }


}
