import { Pipe, PipeTransform } from '@angular/core';
import { Moment } from 'moment';
declare const moment:any;
/*
 * Raise the value exponentially
 * Takes an exponent argument that defaults to 1.
 * Usage:
 *   value | amLocal:exponent
 * Example:
 *   {{ 2 |  amLocal:10}}
 *   formats to: 1024
*/
@Pipe({name: 'amLocal'})
export class DatePipe implements PipeTransform {
  transform(date, format="MMM Do, YYYY h:mm A") {
    //console.log(format);
    var ts = moment.utc(date);
    return ts.local().format(format);
  }
}