import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Http, Headers, Response } from '@angular/http';
import { StateService, UIRouter } from '@uirouter/angular';
import {SERVER_NAME, APIS} from '../config';
import { UserDetailsService } from '../services/user-details.service';
import { uniqR } from '@uirouter/angular';
import { queryDef } from '@angular/core/src/view/query';
import { HttpService } from '../services/http.service';


@Component({
  selector: 'app-price-payout-modal',
  templateUrl: './price-payout-modal.component.html',
  styleUrls: ['./price-payout-modal.component.scss']
})
export class PricePayoutModalComponent implements OnInit {
  @ViewChild('pricePayoutModal') pricePayoutModal: ModalDirective;

  playerDetail :any;
  username:any;
  leaugeStatus : any;
  userPropsArray: any = [];
  currentPage: number = 1;
  itemsPerPage: number = 10;
  numberOfItem: number;
  totalPages: number;
  collection: any[] = [];
  entrants: any;
  statsArrayLength: any;
  uniqueArray: any;
  p1props: any = [];
  p2props: any = [];
  sortedPercentageArray: any = [];
  idArray: any = [];
  percentageArray: any = [];
  propPicks: any = [];
  finalArray: any = [];
  result: any = [];
  sorted: any = [];
  waitingRequest: Boolean = false;
  unique: any = [];
  uid: any;
  user: any;
  payoutID: any;
  checkAmount: any;
  constructor(
    private _uiRouter: UIRouter,
    private _httpService: HttpService,
    private _userDetails: UserDetailsService,
    private _userDetailsService: UserDetailsService) {

    
    //this.contestId = this._uiRouter.stateService.params.contestId;
    //this.leaugeStatus = this._uiRouter.stateService.params.leaugeStatus;
    //console.log(this.leaugeStatus);
    //console.log('this.contestId', this.contestId);
  }
  
  ngOnInit() {
   var n = new Date();
   var y = n.getFullYear();
   var m = n.getMonth() + 1;
   var d = n.getDate();
   document.getElementById("date").innerHTML = m + "/" + d + "/" + y;
  }

  show(username, userId, amount, payID){
    this.user = username
    this.uid = userId;
    this.checkAmount = amount;
    this.payoutID = payID;
    console.log("username: " + username + ", userId: " + userId + ", amount: " + amount + ", payoutId: " + payID);
    document.getElementById("username").innerHTML = username;

    this.pricePayoutModal.show();

  }
  
  submit(checkId:string){
    
    console.log("Check id: " + checkId + ", userId: " + this.uid + ", username: " + this.user + ", amount: " + this.checkAmount + ", payId: " + this.payoutID);
    var n = new Date();
    var y = n.getFullYear();
    var m = n.getMonth() + 1;
    var d = n.getDate();

    let urlString = 'userId=' + this.uid + '&amount=' + this.checkAmount + '&checkId=' + checkId + '&payoutId=' + this.payoutID+ '&date=' + m + "/" + d + "/" + y;

    console.log(urlString);
    
    let requestPayload = { 
      "userId":this.uid,
      "amount":this.checkAmount,
      "checkId": checkId,
      "payoutId": this.payoutID,
      "date": m + "/" + d + "/" + y
     };

      

      this._httpService.httpRequest('GET', 'logCheckPayout'/*'paymentSessionPayout'*/, urlString,false)
    .then(Response => {
      let response: any = Response;
      console.log(response);
      //this._userDetailsService.showSuccess(response.message);
      //Consumed another api for script 

      //Consumed another api for script
    })
    .catch(data => {
      this._userDetailsService.showError(data.message);
    })
    
    this.pricePayoutModal.hide();
    //window.location.reload();

  }
   

  closeModal(){
    this.pricePayoutModal.hide();
  }

}
