import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-entrant-detail-modal',
  templateUrl: './entrant-detail-modal.component.html',
  styleUrls: ['./entrant-detail-modal.component.scss']
})
export class EntrantDetailModalComponent implements OnInit {
  @ViewChild('entrantDetailModal') entrantDetailModal: ModalDirective;

  playerDetail :any;
  username:any;
  leaugeStatus : any;
  constructor() { }
  
  ngOnInit() {
  }

  show(username,pick,leaugeStatus){
    console.log("rick");
      console.log('modal player data', pick,leaugeStatus);
      this.entrantDetailModal.show();
      this.playerDetail = pick;
      this.username = username;
      this.leaugeStatus = leaugeStatus;


      console.log('playerDetail: ');
      console.log(this.playerDetail);
  }

  closeModal(){
    this.entrantDetailModal.hide();
  }

}
