import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { HttpService } from '../services/http.service';
import { UIRouter } from '@uirouter/angular';


import { FormGroup, FormBuilder , Validators} from '@angular/forms';
import { UserDetailsService } from '../services/user-details.service';

@Component({
  selector: 'app-props-modal-versus',
  templateUrl: './props-modal-versus.component.html',
  styleUrls: ['./props-modal-versus.component.scss']
})
export class PropsModalVersusComponent implements OnInit {
@ViewChild('propModal') propModal: ModalDirective;
  propsPostData :any =  {};
  eventPropsdata : any;
  propsData: any;
  selectedPlayer = [];
  addPropForm: FormGroup;
  //checkedProps = [];
  propObj: any;
  playerData = [];
  adminPropParameters=[];
  //adminContestId : any;
  //teamid : any;
  playerName : string;
  floatError : string;
  maxLimitError : string;
  inputChecked: any = false;


  constructor(private _httpService:HttpService, 
  private fb: FormBuilder,
  private _userDetailsService : UserDetailsService,
  private _uiRouter:UIRouter) { }

  ngOnInit() {
    this._httpService.httpRequest('GET', 'propParameter',"leagueType=MLB", true)
      .then(Response => {
        let propsData :any = Response;
        this.propsData = propsData.response;
        //console.log("propsData======"+this.propsData);
        
      })
      .catch(data => {
        console.log('props parameters error', data);
    })

    this.addPropForm = this.fb.group({
      propValue : ['', []]
    })

    $('#propModal').on('hide', function () {
      console.log("into props modal");
    });

    $('#propModal').on('hidden', function () {
      console.log("into props modal 2");
    });

  }

  show(player,eventPropsdata,adminContestId,teamid){
      console.log('modal player data', player,eventPropsdata);
      this.eventPropsdata=eventPropsdata;
      player.contestTradational=false;
      player.teamId = teamid;
      player.adminContestId = adminContestId;
      player.statPlayerId = player.playerId;
      this.playerData = player;
      this.playerName = player.firstName + " "+ player.lastName;
     // this.adminContestId = adminContestId;
      //this.teamid = teamid;
      this.propModal.show();
  }

  closeModal(){
    this.propModal.hide();
  }

  selectPlayer(playerId){
    //console.log('player id',playerId);
    this.selectedPlayer.push(playerId);
    // this.adminPropParameters.push({
          
    //       "propParamId": playerId
              
    //     });
    // console.log('players id',document.getElementById('players'+playerId));
    //console.log('this.selectedPlayer',this.selectedPlayer);

  }

  savePlayerProps(val){
    //console.log('savePlayerProps',val);
    // let propValue = val.propValue;
    // this.propObj={
    //   'propVal': propValue,
    //   'checkedProp': this.checkedProps
    // }
    //console.log('this.propObj',this.propObj);
    console.log('event model object ',this.eventPropsdata);
    this.propsPostData = this.playerData;
    this.propsPostData['prop']={"propValue":val.propValue};
    this.propsPostData['prop']['adminPropParameters']=this.adminPropParameters;
    this.propsPostData['event']=this.eventPropsdata;
    

    this._httpService.httpRequest('POST', 'contestCreate',this.propsPostData, true)
      .then(Response => {
        console.log('create constest success', Response);
        let responseData :any = Response;
        if(responseData.success){
            this._userDetailsService.showSuccess(responseData.message);
            // delete this.propsPostData;
            delete this.adminPropParameters;
            //delete this.eventPropsdata;
            this.propModal.hide();
            this._uiRouter.stateService.reload();
        }
        
        //this._uiRouter.stateService.go('team-listing',{'startTime' : this.datePipe.transform(val.startTime,"yyyy-MM-dd"),'leagueType': this.leagueVal});
        
    })
      .catch(data => {
        console.log('create contest error', data);
        this._userDetailsService.showError(data.message);
      })
    
  }


  

  propCheckFun(props, e){
    //console.log(props);
    let propName: any = props.name;
    //let checked = document.querySelectorAll('input[type="checkbox"]:checked').length;
    this.inputChecked = document.querySelectorAll('input[type="checkbox"]:checked').length;
    //console.log("coming"+checked);

    if(this.inputChecked > 3){
      //$event.preventDefault();
      //return false;
      this.maxLimitError = "You can check maximum of 3 props.";
    }else{
        this.maxLimitError = "";
        if(e.target.checked){
          //this.checkedProps.push(props);
          this.adminPropParameters.push({
            "propParamId": props.propParamId    
          });
          console.log(this.adminPropParameters);
        }else{
          //delete this.adminPropParameters.propParamId;
          console.log("false");
          console.log(this.adminPropParameters);
        }
        
    }
  }

  checkFloatValue(value){
    console.log(value);
    var str = value.toString();
    if(!str.match(/^-?[0-9]*[.][5]+$/)) {
        this.floatError = "Prop value should be a float value(Eg. 1.5)";
        return;
    }else{
      this.floatError = "";
    }
    return value;
  }

}
