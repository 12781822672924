import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { SERVER_NAME, APIS } from '../config';
import { HttpService } from '../services/http.service';
import { UIRouter } from '@uirouter/angular';
import { UserDetailsService } from '../services/user-details.service';
import 'eonasdan-bootstrap-datetimepicker';
import { DatePipe } from '@angular/common';
import { ContestListingComponent } from '../contest-listing/contest-listing.component';
import { NKDatetimeModule } from "ng2-datetime/ng2-datetime";
import { Moment } from 'moment';
declare const moment: any;
// CommonJS style - working with "require"
declare const $: any;

@Component({
  selector: 'app-edit-contest',
  templateUrl: './edit-contest.component.html',
  styleUrls: ['./edit-contest.component.scss'],
  providers: [HttpService, DatePipe]
})
export class EditContestComponent implements OnInit {

  createContestForm: FormGroup;

  featured: boolean = false;
  dateError: boolean = false;
  currentDate = new Date();
  currentDateError: boolean = false;
  dateFrom: Date = new Date();
  contestId: any;
  startDate: Date;
  endDate: Date;
  contestPlayerListing: any;
  itemsPerPage: any = 20;
  currentPage: any = 1;
  numberOfItem: any;
  leaugeStatus: any;
  contestPlayerListingError: boolean = false;
  datepickerOpts = {
    startDate: new Date(),
    autoclose: true,
    todayBtn: 'linked',
    todayHighlight: true,
    assumeNearbyYear: true,
    format: 'M/dd/yyyy'
  }

  datepickerOpts1 = {
    startDate: new Date(),
    autoclose: true,
    todayBtn: 'linked',
    todayHighlight: true,
    assumeNearbyYear: true,
    format: 'M/dd/yyyy'
  }
  multiDayEndDateStr: any;
  date: Moment;
  a2eOptions: any;
  startTime: any;
  endTime: any;
  dateTo: Date = new Date();

  constructor(private _httpService: HttpService,
    private fb: FormBuilder,
    private datePipe: DatePipe,
    private _uiRouter: UIRouter,
    private _userDetailService: UserDetailsService) {

    this.contestId = this._uiRouter.stateService.params.contestId;
    this.leaugeStatus = this._uiRouter.stateService.params.leaugeStatus;
  }

  ngOnInit() {
    this.createContestForm = this.fb.group({
      startTime: [''],
      endTime: [''],
      featured: ['']
    })
    this.loadDetail();
    this.loadContestPlayerListing();
  }

  dateChange(date) {
    this.date = date;
  }

  endDateToChange(date) {
    console.log("endDateToChange")
    this.endTime = this._httpService.convertToUTC(date, "MM/DD/YYYY HH:mm:ss");
    if (this.startTime < this.endTime) {
      this.currentDateError = false;
    }else{
      this.currentDateError = true;
    }
  }

  dateToChange(date) {
    console.log("dateToChange")
    this.startTime = this._httpService.convertToUTC(date, "MM/DD/YYYY HH:mm:ss");
    if (this.startTime < this.endTime) {
      this.currentDateError = false;
    }else{
      this.currentDateError = true;
    }
  }

  //nelsonclean
  // dateClick() {
  //   console.log('click start!')
  // }

  // dateEndClick() {
  //   console.log('click end!')
  // }

  loadDetail() {
    this._httpService.httpRequest('POST', 'getEditContest', { 'contestId': this.contestId }, true)
      .then(Response => {
        let response: any = Response;
        this.startDate = response.response.createionDto.startTime;
        this.endDate = response.response.createionDto.endTime;
        console.log("startDate: " + this.startDate + ", endDate: " + this.endDate);
        this.featured = response.response.createionDto.featured;
        this.multiDayEndDateStr = response.response.createionDto.multiDayEndDateStr
        var ts = moment.utc(this.startDate);
        this.dateFrom = ts.local().format("MM/DD/YYYY HH:mm");
        this.dateFrom = new Date(this.dateFrom);

        var ts1 = moment.utc(this.endDate);
        this.dateTo = ts1.local().format("MM/DD/YYYY HH:mm");
        this.dateTo = new Date(this.dateTo);

      })
      .catch(data => {
        console.log('error data', data);
      })
  }

  createContestFormSubmit(val) {
    this.currentDateError = false;
    if (this.startTime < this.endTime) {
      let startTimeUTC;

      if (this.startTime) {
        startTimeUTC = this._httpService.convertToUTC(val.startTime, "MM/DD/YYYY HH:mm:ss");
      } else {
        this.startTime = this.startDate;
        startTimeUTC = this.startDate;
      }

      let endTimeUTC = this._httpService.convertToUTC(val.endTime, "MM/DD/YYYY HH:mm:ss");
      this.dateError = false;
      let requestData = {
        "startTime": this.startTime,
        "endtime": this.endTime,
        'multiDayEndDateStr': this.multiDayEndDateStr ? (val.endTime ? this.datePipe.transform(val.endTime, "yyyy-MM-dd") : '') : '',
        "featured": val.featured ? true : false,
        "contestId": this.contestId
      }

      this._httpService.httpRequest('POST', 'editContest', requestData, true)
        .then(Response => {
          console.log('response', Response);
          let responseData: any = Response;
          this._userDetailService.showSuccess(responseData.message);
          if (this.leaugeStatus == "upcoming") {
            this._uiRouter.stateService.go('contest-listing');
          } else if (this.leaugeStatus == "live") {
            this._uiRouter.stateService.go('contest-listing-live');
          }

        })
        .catch(data => {
          console.log('create contest error', data);
          this._userDetailService.showError(data.message);
        })
    } else {
      this.currentDateError = true;
    }
  }

  // load contest player listing
  loadContestPlayerListing() {
    let requestData: any = {
      "currentSize": 20,
      "currentPage": this.currentPage,
      contestId: parseInt(this.contestId)
    }

    this._httpService.httpRequest("POST", "getPlayerListByContestId", requestData, true)
      .then(Response => {
        let responseData: any = Response;
        this.contestPlayerListing = responseData.response.data;
        this.currentPage = responseData.response.pagination.currentPage;
        this.numberOfItem = responseData.response.pagination.numberOfElements;
        if (this.contestPlayerListing == "") {
          this.contestPlayerListingError = true;
        }
      })
      .catch(data => {
        console.log('error data', data);
      })
  }


  changeStatus(playerID, playerfName, playerlName, adminContestPropId) {
    let confirmDisable = confirm('Do you want to disable this player');
    if (confirmDisable == true) {

      let changeRequestData = {
        "playerId": playerID,
        "firstName": playerfName,
        "lastName": playerlName,
        "playerInactive": true,
        "adminContestPropId": adminContestPropId,
        "contestId": parseInt(this.contestId)
      }
      this._httpService.httpRequest("POST", "propInactiveFlag", changeRequestData, true)
        .then(Response => {
          let responseData: any = Response;
          this._userDetailService.showSuccess(responseData.message);
          this.loadContestPlayerListing();
        })
        .catch(data => {
          this._userDetailService.showError(data.message);
        })
    }
  }

  pageChanged($event) {
    console.log('event', $event);
    this.currentPage = $event;
    this.loadContestPlayerListing();
  }
}