import { Component, OnInit, ViewChild} from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Moment } from 'moment';
import { FormGroup, FormBuilder , Validators} from '@angular/forms';

import { HttpService } from '../services/http.service';


@Component({
  selector: 'app-create-a-user-user-role-modal',
  templateUrl: './create-a-user-user-role-modal.component.html',
  styleUrls: ['./create-a-user-user-role-modal.component.css']
})
export class CreateAUserUserRoleModalComponent implements OnInit {
  @ViewChild('createUserUserRoleModal') CreateAUserUserRoleModalComponent: ModalDirective;

  constructor(
    private _httpService: HttpService,
    private fb: FormBuilder,
  ) { }

  ngOnInit() {
  }

  contestForm = this.fb.group({
    UserId: ['', []],
    UserRole: ['',[]],
  });

  selectedUserId;
  selectedUserRole;
 
  showError = false;


  show(player, eventPropsdata, adminContestId, teamid, leagueType) {
    this.CreateAUserUserRoleModalComponent.show();
  }

  closeModal(){
    this.CreateAUserUserRoleModalComponent.hide();
  }

  setSelectedUserId(userId){
    console.log('changing user Id');
    this.selectedUserId=userId;
    console.log(this.selectedUserId)
  }

  setSelectedUserRole(userRole){
    console.log('changing role');
    this.selectedUserRole=userRole;
    console.log(this.selectedUserRole);
  }

  toggle() {
    this.showError = !this.showError;
  }

  createUserUserRole(){

    console.log(this.selectedUserId);
    console.log(this.selectedUserRole);

    var userUserRoleCreationDto = {
      "userRoleID": this.selectedUserRole,
      "user_id": this.selectedUserId,
      "CreateDate": new Date(),
      "active": true,
    }

    console.log(JSON.stringify(userUserRoleCreationDto));

    this._httpService.httpRequest('POST','createUserUserRole', JSON.stringify(userUserRoleCreationDto), true)
    .then(Response => {
      let responseData : any = Response;
      console.log('REPONSE DATA: ' + responseData);
      if(responseData["success"]){
        alert("UserUserRole Successfully Created.")
        location.reload()
      }
      console.log(responseData)
    })
    .catch(data => {

    })
  }

}