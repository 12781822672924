import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { FormGroup, FormBuilder , Validators} from '@angular/forms';

import { HttpService } from '../services/http.service';

@Component({
  selector: 'app-assign-house-tickets-modal',
  templateUrl: './assign-house-tickets-modal.component.html',
  styleUrls: ['./assign-house-tickets-modal.component.css']
})
export class AssignHouseTicketsModalComponent implements OnInit {
@ViewChild('assignHouseTicketModal') assignHouseTicketModal: ModalDirective;
  dateTo: Date = new Date();
  userSelectionDate: Date = new Date();
  
  a2eOptions = {
    format: "MM/DD/YYYY"
  };

  constructor(
  	private _httpService: HttpService,
  	private fb: FormBuilder,) { }

  ngOnInit() {
    this.fetchHouseTickets();
  }
  
  userSearchData :any =  {};

  userForm = this.fb.group({
    searchName: ['', []],
    searchPromo: ['',[]],
    users:['', []],
    ticketId:['', []],
    selectedUser:'',
    startTime:'',
    promoDate:'',
    
  });

  searchedUsers = []
  selectedUsers =[];
  assignableHouseTickets=[];
  selectedTicketId;

  assignHouseTicket(){
    console.log("ticket: " + this.selectedTicketId + " selected users " + this.selectedUsers)
    console.log("Date: " + this.dateTo)

    var userIdList = [];

    this.selectedUsers.forEach( u => userIdList.push(u.userId) );

    let requestData: any = {
      "houseTicketId": this.selectedTicketId,
      "userIds": userIdList,
      "expirationDate": this.dateTo,
    }

    this.selectedUsers.forEach( u => console.log(u));

    this._httpService.httpRequest('POST','assignHouseTickets', requestData, false)
    .then(Response => {
      
      let data: any = Response['response'];
      this.searchedUsers = data;
      if(Response["success"]){
        alert("House Ticket Successfully Created.")
        location.reload()
      }
      console.log(this.searchedUsers)
    })
    .catch(data => {

    })
  }

  setSelectedTicket(selectedTicket){
    console.log(selectedTicket)
    this.selectedTicketId= selectedTicket;
  }

  show(player, eventPropsdata, adminContestId, teamid, leagueType) {
    this.assignHouseTicketModal.show();
  }

  closeModal(){
    this.assignHouseTicketModal.hide();
  }

  dateToChange(date){
    this.dateTo = new Date(date);
  }

  userSelectionDateToChange(date){
    this.userSelectionDate = new Date(date);
  }

  fetchHouseTickets(){
    console.log('Fetching Assignable House Tickets');
    this._httpService.httpRequest('GET','getAssignableHouseTickets', "", true)
    .then(Response => {
      
      let data: any = Response['response'];
      this.assignableHouseTickets = data;
      console.log('ASSIGNABLE HOUSE TICKETS');;
      console.log(this.assignableHouseTickets)
    })
    .catch(data => {

    })
  }
  


  searchUsers(name){
    
    this.userSearchData.userName = String(name)
   
  	this._httpService.httpRequest('GET','userListByName', "userName="+this.userSearchData.userName, true)
    .then(Response => {
      let data: any = Response['response'];
      this.searchedUsers = data;
      console.log(this.searchedUsers)
    })
    .catch(data => {

    })
  }

  searchUsersPromo(promo){
    this._httpService.httpRequest('GET','userListByPromo', "promoCode="+promo+"&date="+this.userSelectionDate, true)
    .then(Response => {
      let data: any = Response['response'];
      this.searchedUsers = data;
      console.log(this.searchedUsers)
    })
    .catch(data => {

    })
  }

  addAllSearchedUsers(){
    //Loop through all searched users. 
    for(var searchedUser of this.searchedUsers){
      //Make sure user isnt already in 'selected' list.
      var userInSelectedList = false;
      for(var selectedUser of this.selectedUsers){
        if(searchedUser.userName === selectedUser.userName){
          userInSelectedList = true;
        }
      }
      if(!userInSelectedList){
        this.selectedUsers.push(searchedUser);
      }
    }

    //Add user to selected list. 
  }

  addSelectedUser(u){
    if(u.userName==null){
      return null;
    }
    var newSelection = true;
    for(var user of this.selectedUsers){
      if(u.userId == user.userId){
        newSelection = false;
      }
    }
    if(newSelection){
      this.selectedUsers.push(u);
    }
    console.log(this.selectedUsers)
  }

  removeAllSelectedUsers(){
    this.selectedUsers = [];
  }

  removeSelectedUser(u){
    var selectedUsers = this.selectedUsers;
    for(var i=0; i< selectedUsers.length; i++){
      if(u.userId == selectedUsers[i].userId){
        selectedUsers.splice(i,1)
      }
    }
  }

}