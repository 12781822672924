import { Component, OnInit } from '@angular/core';
import { Http, Headers, Response } from '@angular/http';
import { FormGroup, FormBuilder , Validators} from '@angular/forms';
import { StateService, UIRouter } from '@uirouter/angular';
import { Moment } from 'moment';
// service
import { UserDetailsService } from '../services/user-details.service';
import { HttpService } from '../services/http.service';

import {SERVER_NAME, APIS} from '../config';

declare const moment:any


@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.scss'],
  providers: [UserDetailsService, HttpService]
})

export class AnalyticsComponent implements OnInit {
  averageData : any;
  analyticfrm : FormGroup;
  dateSelectError : any;

  date1: Date;
  datepickerOpts = {
      endDate : new Date(),
      autoclose: true,
      todayBtn: 'linked',
      todayHighlight: true,
      assumeNearbyYear: true,
      format: 'M, dd yyyy'
  }
  date2: Date ;
  datepickerToOpts = {
      startDate: this.date1,
      endDate : new Date(),
      autoclose: true,
      todayBtn: 'linked',
      todayHighlight: true,
      assumeNearbyYear: true,
      format: 'M, dd yyyy'
  }
  
  constructor(
    private _http: Http, 
    private _userDetails: UserDetailsService,
    private _httpService: HttpService,
    private _uiRouter: UIRouter,
    private fb : FormBuilder
    ) 
  {

  }

  ngOnInit() {
    this.analyticfrm = this.fb.group({
      leagueType: ['ALL', []],
      contestStartDate:['',[]],
      contestEndDate:['',[]]
    })
    this.contestListingData();
  }

  contestListingData(leagueType='ALL',startDate='',endDate=''){
    console.log(leagueType,startDate,endDate);
    let leagueId=0; 
    
    if(leagueType=="MLB"){
      leagueId =1;
    }else if(leagueType=="NFL"){
      leagueId =2;
    }else if(leagueType=="NBA"){
      leagueId =3;
    }
    this._httpService.httpRequest('POST','averageAmountByleauge',{"leagueType":leagueId,"startDate":startDate?moment(startDate).format('YYYY-MM-DD')+" 00:00:00":'',"endDate":endDate?moment(endDate).format('YYYY-MM-DD')+" 23:59:59":''}, true)
    .then(Response => {
      let responseData: any = Response;
      this.averageData = responseData.response;
    })
    .catch(data => {

    })
  }

  resetFilter(){
    this.contestListingData();
  }

  filterData(val){
    if((val.contestStartDate && !val.contestEndDate) || (!val.contestStartDate && val.contestEndDate)){
      this.dateSelectError = "Please select both start and end date"
    }else{
      this.dateSelectError = "";
      this.contestListingData(val.leagueType,val.contestStartDate,val.contestEndDate);
    }
    
  }

  startDateChange(date1){
    console.log(this.date1+"==="+this.date2);
    //this.date2 = new Date();
    this.dateSelectError = "";
    if((this.date2<this.date1 && (this.date2))){
      this.dateSelectError = "End date should be greater than start date"
    }else{
      this.dateSelectError = "";
      this.datepickerToOpts = {
        startDate: this.date1,
        endDate : new Date(),
        autoclose: true,
        todayBtn: 'linked',
        todayHighlight: false,
        assumeNearbyYear: true,
        format: 'M, dd yyyy'
      }
    }
    
  }

  endDateChange(date1,date2){
    console.log('date1,date2',date1,date2);
    this.dateSelectError = "";
    if(date2 >= date1){
      this.dateSelectError = "";
    }else if((date2 && date1) && date2 <= date1){
      this.dateSelectError = "End date should be greater than start date";
    }
  }


}
