 import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder , Validators} from '@angular/forms';

import { SERVER_NAME, APIS } from '../config';
import { HttpService } from '../services/http.service';
import { UIRouter } from '@uirouter/angular';

import { DatePipe } from '@angular/common';
import { Moment } from 'moment';
import { UserDetailsService } from '../services/user-details.service';

// CommonJS style - working with "require"
declare const $:any;
declare const moment:any;

@Component({
  selector: 'app-create-similar',
  templateUrl: './create-similar.component.html',
  styleUrls: ['./create-similar.component.scss'],
  providers: [HttpService,DatePipe]
})
export class CreateSimilarComponent implements OnInit {
  createContestForm: FormGroup;
  leagueVal: any;
  contestTypeVal: any;
  multipleEntry: boolean = false;
  featured : boolean = false;
  dateError: boolean = false;
  propPicked: any;
  propPickedZeroError: boolean;
  propAvailable: any;
  propAvailableZeroError: boolean;
  propError: boolean = false;
  prizeError: boolean = false;
  // entryAllowError : boolean = false;
  guaranteedPrizeMoney: any;
  guarantyPrizeZeroError: boolean;
  entryFee: any;
  entryFeeZeroError: boolean;
  maxEntriesAllowed : any;
  maxEntryZeroError : boolean;
  entryNumber: any;
  entryPerUserZeroError: any;
  maxEntryError: boolean;
  currentDate = new Date(new Date().getTime() + (3600 * 1000));
  // currentDateError: boolean = false;
  leagueType : any;
  guarantyPrizeMultiplication: boolean = false;
  maxEntryPerUser: boolean = false;
  similarContestId : any;
  dateFrom: Date = new Date();
  startTime : Date;
  endTime : Date;
  previousDateTo: Date;
  name : any;



  summary: any;
  generalInfo: any;
  // Maximum Entries Allowed;Entry Fee;Guaranteed Prize

  

  multiDayEndDateStr : any;
  passwordEntry: boolean = false;
  password: any; 
  passwordError: boolean;

  
  datepickerOpts = {
      startDate: new Date(),
      autoclose: true,
      todayBtn: 'linked',
      todayHighlight: true,
      assumeNearbyYear: true,
      format: 'M, dd yyyy'
  }

  // dateTo: Date = new Date();
  // datepickerToOpts = {
  //     startDate: this.dateFrom,
  //     autoclose: true,
  //     todayBtn: 'linked',
  //     todayHighlight: true,
  //     assumeNearbyYear: true,
  //     format: 'M, dd yyyy'
  // }



  constructor(private _httpService: HttpService, private fb: FormBuilder,private datePipe: DatePipe,private _uiRouter:UIRouter,private _userDetailsService: UserDetailsService) { 
    //this.leagueVal = "MLB";
    //this.contestTypeVal = "Traditional";
    this.similarContestId = this._uiRouter.stateService.params.similarContestId;
  }


  ngOnInit() {

    this.createContestForm = this.fb.group({
      leagueType: ['', []],
      type: ['', []],
      name: ['', [Validators.required]],
      // startTime: ['', [Validators.required]],
      // endTime: ['', [Validators.required]],
      summary: ['', [Validators.required]],
      generalInfo: ['', [Validators.required]],
      entryFee: ['', [Validators.required]],
      multipleEntryAllowed: ['', []],
      maxEntriesPerUser: ['', []],
      passwordEntryAllowed: ['', []],
      passwordForContest: ['', []],
      guaranteedPrizeMoney: ['', [Validators.required]],
      createdPropCount: [''],
      minPropsToPick: ['', [Validators.required]],
      maxEntriesAllowed : ['',[Validators.required]],
      featured: ['', []],
      extraPrize: ['', []]
    })

    
    $('.copyDisable').bind("cut copy paste",function(e) {
        e.preventDefault();
    });
    this.loadDetail();

    // setTimeout(() => {
    //   this.dateTo = this.previousDateTo;
    // },1000);
   
  }

  loadDetail(){
    this._httpService.httpRequest('POST','getEditContest',{'contestId':this.similarContestId},true)
    .then(Response => {
      let response: any = Response;
      this.startTime = response.response.createionDto.startTime;
      this.multiDayEndDateStr = response.response.createionDto.multiDayEndDateStr;
      // this.endTime = response.response.createionDto.endTime;
      
      this.dateFrom = new Date(this.startTime);
      // this.dateTo = new Date(this.endTime);
      this.previousDateTo = new Date(this.endTime);
      console.log('this.dateFrom',this.dateFrom);
      // console.log('this.dateTo',this.dateTo);
      this.name = response.response.createionDto.name;
      this.summary = response.response.createionDto.summary;
      this.generalInfo = response.response.createionDto.generalInfo;
      this.maxEntriesAllowed = response.response.createionDto.maxEntriesAllowed;
      this.entryFee = response.response.createionDto.entryFee;
      this.guaranteedPrizeMoney = response.response.createionDto.guaranteedPrizeMoney;
      this.leagueType = response.response.createionDto.leagueType;
      this.contestTypeVal = response.response.createionDto.type;
      this.propPicked = response.response.createionDto.minPropsToPick;
    })
    .catch(data => {
      console.log('error data',data);
    })
  }

  createContestFormSubmit(val){
    this.dateError = false;
    this.propPickedZeroError = false;
    this.propAvailableZeroError = false;
    // this.entryAllowError = false;
    this.guarantyPrizeZeroError = false;
    this.entryFeeZeroError = false;
    this.maxEntryZeroError = false;
    this.entryPerUserZeroError = false;
    this.maxEntryError = false;
    // this.currentDateError = false;
    this.passwordError = false;
    this.propError = false;
    this.prizeError = false;
    this.guarantyPrizeMultiplication = false;
    this.maxEntryPerUser = false;
    
    // if(new Date(this.dateTo) <= new Date(this.dateFrom)){
    //   this.dateError = true;
    // }
    if (parseInt(this.guaranteedPrizeMoney) <= parseInt(this.entryFee)){
      this.prizeError = true;
    }
    // else if(parseInt(this.maxEntriesAllowed) > 10000){
    //    this.entryAllowError = true;
    // }
    else if ((parseInt(this.maxEntriesAllowed) < parseInt(this.entryNumber)) && this.multipleEntry){
      this.maxEntryError = true;
    }
    
    else if (this.propPicked < 1){
      this.propPickedZeroError = true;
    }    
    else if (this.passwordEntry && (this.password.length < 1)) {
      this.passwordError = true;
    }
    else if (this.maxEntriesAllowed < 1){
      this.maxEntryZeroError = true;
    }
    // Brendon Brinkmann 2-6-18 changed minimun entry fee and prize money
    else if(this.entryFee < 0){
      this.entryFeeZeroError = true;
    }
    else if(this.guaranteedPrizeMoney < 0){
      this.guarantyPrizeZeroError = true;
    }
    else if((this.entryNumber < 1) && this.multipleEntry){
      this.entryPerUserZeroError = true;
    }

    /* Brendon Brinkmann 2-6-18 commented out guaranteedPrizeMoney validation
    else if ((parseInt(this.maxEntriesAllowed) * parseInt(this.entryFee)) < this.guaranteedPrizeMoney){
      this.guarantyPrizeMultiplication = true;
    }
    */
    //brendon brinkmann 2-6-18 updated guarantyPrizeMultiplication validation 
    /*else if ((parseInt(this.maxEntriesAllowed) * parseInt(this.entryFee)) <= this.guaranteedPrizeMoney){
      this.guarantyPrizeMultiplication = true;
    }*/
    else if((parseInt(this.entryNumber) >10000)  && (this.multipleEntry)){
      this.maxEntryPerUser = true;
    }
    else{

      let requestData = {
        "leagueType": this.leagueType,
        "type": this.contestTypeVal,
        "name":val.name,
        "startTime":this.datePipe.transform(this.startTime,"MM/dd/yyyy HH:mm:ss"),
        // "endTime":this.datePipe.transform(this.endTime,"MM/dd/yyyy HH:mm:ss"),
        "multiDayEndDateStr":this.multiDayEndDateStr,
        "summary":val.summary,
        "generalInfo":val.generalInfo,
        "entryFee":val.entryFee,
        "passwordForContest":val.passwordForContest,
        "passwordEntryAllowed": val.passwordEntryAllowed?true:false,
        "multipleEntryAllowed":val.multipleEntryAllowed?true:false,
        "maxEntriesPerUser":val.maxEntriesPerUser,
        "guaranteedPrizeMoney":val.guaranteedPrizeMoney,
        "createdPropCount":val.createdPropCount,
        "minPropsToPick":val.minPropsToPick,
        "maxEntriesAllowed" : val.maxEntriesAllowed,
        "featured":val.featured?true:false,
        "extraPrize":val.extraPrize
      }
      
      this._httpService.httpRequest('POST', 'createContest',requestData, true)
      .then(Response => {
        console.log('response',Response);
        let responseData : any = Response;

        console.log('similarContestId',this.similarContestId);
        this._uiRouter.stateService.go('price-payload',{
          'startTime' : this.datePipe.transform(this.startTime,"yyyy-MM-dd"),
          'endTime' : this.multiDayEndDateStr?this.multiDayEndDateStr:this.datePipe.transform(this.startTime,"yyyy-MM-dd"),
          'leagueType': this.leagueType,
          'type': requestData.type,
          'guaranteedPrizeMoney': requestData.guaranteedPrizeMoney,
          'adminContestId':responseData.response.contestCreation.id,
          'maxEntriesAllowed': this.maxEntriesAllowed,
          'similarContestId':this.similarContestId
        });
      })
      .catch(data => {
        console.log('create contest error', data);
        this._userDetailsService.showError(data.message);
      })
    }
  }

  leagueCheckFun(val){
    this.leagueVal = val;
  }

  contestTypeFun(val){
    this.contestTypeVal = val;
  }

  // startDateChange(event){
  //  this.dateTo = new Date(event); 
  // }

}
