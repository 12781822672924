

import { LoginComponent } from '../login/login.component';
import { DashboardComponent } from '../dashboard/dashboard.component';
import { ContestListingComponent } from '../contest-listing/contest-listing.component';
import { ContestOrderComponent } from '../contest-order/contest-order.component';
import { ContestListingHistoryComponent } from '../contest-listing-history/contest-listing-history.component';
import { ContestListingHistoryArchiveComponent } from '../contest-listing-history-archive/contest-listing-history-archive.component';
import { ContestListingLiveComponent } from '../contest-listing-live/contest-listing-live.component';
import { CreateContestComponent } from '../create-contest/create-contest.component';
import { CreateContestSimilarsComponent } from '../create-contest-similars/create-contest-similars.component';
import { CreateSimilarComponent } from '../create-similar/create-similar.component';
import { TeamListingVersusComponent } from '../team-listing-versus/team-listing-versus.component';
import { TeamListingComponent } from '../team-listing/team-listing.component';
import { TeamListingPropComponent } from '../team-listing-prop/team-listing-prop.component';
import { ForgetPasswordComponent } from '../forget-password/forget-password.component';
import { ResetPasswordComponent } from '../reset-password/reset-password.component';
import { PricePayoutComponent } from '../price-payout/price-payout.component';
import { ListOfEntrantsComponent } from '../list-of-entrants/list-of-entrants.component';
import { ListOfEntrantsArchiveComponent } from '../list-of-entrants-archive/list-of-entrants-archive.component';
import { ContestDetailComponent } from '../contest-detail/contest-detail.component';
import { RegisteredFansComponent } from '../registered-fans/registered-fans.component';
import { DeleteUserRequestsComponent } from '../delete-user-requests/delete-user-requests.component';
import { ListOfContestsComponent } from '../list-of-contests/list-of-contests.component';
import { FanDetailComponent } from '../fan-detail/fan-detail.component';
import { ContentComponent } from '../content/content.component';
import { AnalyticsComponent } from '../analytics/analytics.component';
import { PopularPlayersComponent } from '../popular-players/popular-players.component';
import { ContestPlayersComponent } from '../contest-players/contest-players.component';
import { SendNotificationComponent } from '../send-notification/send-notification.component';
import { EditContestComponent } from '../edit-contest/edit-contest.component';
import { AverageGameTimeComponent } from '../average-game-time/average-game-time.component';
import { PrizePayoutComponent } from '../prize-payout/prize-payout.component';
import { LivePlayerStatsComponent } from '../live-player-stats/live-player-stats.component';
import { PlayerPropsComponent } from '../player-props/player-props.component';
import { CreatePropComponent } from '../create-prop/create-prop.component';
import { HouseUserContestUpcomingComponent } from '../house-user-contest-upcoming/house-user-contest-upcoming.component';
import { HouseUserContestLiveComponent } from '../house-user-contest-live/house-user-contest-live.component';
import { HouseUserContestHistoryComponent } from '../house-user-contest-history/house-user-contest-history.component';
import { HousePropUpcomingComponent } from '../house-prop-upcoming/house-prop-upcoming.component';
import { HousePropLiveComponent } from '../house-prop-live/house-prop-live.component';
import { HousePropHistoryComponent } from '../house-prop-history/house-prop-history.component';
import { RecentHousePropHistoryComponent } from '../recent-house-prop-history/recent-house-prop-history.component'
import { SinglePropLiveComponent } from '../single-prop-live/single-prop-live.component';
import { SinglePropHistoryComponent } from '../single-prop-history/single-prop-history.component';
import { NewUsersComponent } from '../new-users/new-users.component';
import { FirstTimeDepositComponent } from '../first-time-deposit/first-time-deposit.component';
import { ContestTicketsComponent } from '../contest-tickets/contest-tickets.component';
import { HouseTicketsComponent } from '../house-tickets/house-tickets.component';
import { LandingPageComponent } from '../landing-pages/landing-pages.component';
import { PromoCodeComponent } from '../promo-code/promo-code.component';
import {ExcludedUsersComponent} from "../excluded-users/excluded-users.component";
import {HeadshotsComponent} from "../headshots/headshots.component";
import {CreatePropsWithCsvComponent} from "../create-props-with-csv/create-props-with-csv.component";
import {DeactivatePropsWithCsvComponent} from "../deactivate-props-with-csv/deactivate-props-with-csv.component";


//        url: '/house-user-contest-history/:userId',
//        name: 'house-user-contest-history',


//        url: '/list-of-contest/:userId',
//        name: 'list-of-contests',


export const Routes: any = [
    {
        url: '/login',
        name: 'login',
        component: LoginComponent
    },
    {
        url: '/dashboard',
        name: 'dashboard',
        component: DashboardComponent
    },
    {
        url: '/contest-listing',
        name: 'contest-listing',
        component: ContestListingComponent
    },
    {
        url: '/contest-order',
        name: 'contest-order',
        component: ContestOrderComponent
    },
    {
        url: '/contest-listing-history',
        name: 'contest-listing-history',
        component: ContestListingHistoryComponent
    },
    {
        url: '/contest-listing-history-archive',
        name: 'contest-listing-history-archive',
        component: ContestListingHistoryArchiveComponent
    },
    {
        url: '/contest-listing-live',
        name: 'contest-listing-live',
        component: ContestListingLiveComponent
    },
    {
        url: '/create-contest',
        name: 'create-contest',
        component: CreateContestComponent
    },
    {
        url: '/create-contest-similars',
        name: 'create-contest-similars',
        component: CreateContestSimilarsComponent
    },
    {
        url: '/create-prop',
        name: 'create-prop',
        component: CreatePropComponent
    },
    {
        url: '/create-props-with-csv',
        name: 'create-props-with-csv',
        component: CreatePropsWithCsvComponent
    },
    {
        url: '/deactivate-props-with-csv',
        name: 'deactivate-props-with-csv',
        component: DeactivatePropsWithCsvComponent
    },
    {
        url: '/house-user-contest-upcoming',
        name: 'house-user-contest-upcoming',
        component: HouseUserContestUpcomingComponent,
    },
    {
        url: '/house-user-contest-live',
        name: 'house-user-contest-live',
        component: HouseUserContestLiveComponent,
    },
    {
        url: '/house-user-contest-history',
        name: 'house-user-contest-history',
        component: HouseUserContestHistoryComponent,
        params: {
            userId: null
        }
    },
    {
        url: '/house-prop-upcoming',
        name: 'house-prop-upcoming',
        component: HousePropUpcomingComponent,
    },
    {
        url: '/house-prop-live',
        name: 'house-prop-live',
        component: HousePropLiveComponent,
    },
    {
        url: '/house-prop-history',
        name: 'house-prop-history',
        component: HousePropHistoryComponent,
    },
    {
        url: '/recent-house-prop-history',
        name: 'recent-house-prop-history',
        component: RecentHousePropHistoryComponent,
    },
    {
        url: '/single-prop-live',
        name: 'single-prop-live',
        component: SinglePropLiveComponent,
    },
    {
        url: '/single-prop-history',
        name: 'single-prop-history',
        component: SinglePropHistoryComponent,
    },
    {
        url: '/new-users',
        name: 'new-users',
        component: NewUsersComponent,
    },
    {
        url: '/first-time-deposit',
        name: 'first-time-deposit',
        component: FirstTimeDepositComponent,
    },
    {
        url: '/create-similar/:similarContestId',
        name: 'create-similar',
        component: CreateSimilarComponent,
    },
    {
        url: '/team-listing/:startTime/:endTime/:leagueType/:adminContestId',
        name: 'team-listing',
        component: TeamListingComponent,

    },
    {
        url: '/team-listing-prop/:startTime/:endTime/:leagueType/:adminContestId',
        name: 'team-listing-prop',
        component: TeamListingPropComponent,

    },
    {
        url: '/team-listing-versus/:startTime/:endTime/:leagueType/:adminContestId',
        name: 'team-listing-versus',
        component: TeamListingVersusComponent,
    },
    {
        url: '/forget-password',
        name: 'forget-password',
        component: ForgetPasswordComponent
    },
    {
        url: '/change-password',
        name: 'change-password',
        component: ResetPasswordComponent
    },
    {
        url: '/price-payload/:startTime/:endTime/:leagueType/:adminContestId/:maxEntriesAllowed/:guaranteedPrizeMoney/:type/:similarContestId',
        name: 'price-payload',
        component: PricePayoutComponent
    },
    {
        url: '/list-of-entrants/:contestId/:leaugeStatus',
        name: 'list-of-entrants',
        component: ListOfEntrantsComponent
    },
    {
        url: '/list-of-entrants-archive/:contestId/:leaugeStatus',
        name: 'list-of-entrants-archive',
        component: ListOfEntrantsArchiveComponent
    },
    {
        url: '/contest-detail/:contestId',
        name: 'contest-detail',
        component: ContestDetailComponent
    },
    {
        url: '/registered-fans',
        name: 'registered-fans',
        component: RegisteredFansComponent
    },
    {
        url: '/delete-user-requests',
        name: 'delete-user-requests',
        component: DeleteUserRequestsComponent
    },
    {
        url: '/list-of-contest/:userId',
        name: 'list-of-contests',
        component: ListOfContestsComponent
    },
    {
        url: '/fan-detail',
        name: 'fan-detail',
        component: FanDetailComponent
    },
    {
        url: '/content',
        name: 'content',
        component: ContentComponent
    },
    {
        url: '/analytics',
        name: 'analytics',
        component: AnalyticsComponent
    },
    {
        url: '/popular-players',
        name: 'popular-players',
        component: PopularPlayersComponent
    },
    {
        url: '/contest-players',
        name: 'contest-players',
        component: ContestPlayersComponent
    },
    {
        url: '/send-notification',
        name: 'send-notification',
        component: SendNotificationComponent
    },
    {
        url: '/edit-contest/:contestId/:leaugeStatus',
        name: 'edit-contest',
        component: EditContestComponent
    },
    {
        url: '/average-game-time',
        name: 'average-game-time',
        component: AverageGameTimeComponent
    },
    {
        url: '/prize-payout',
        name: 'prize-payout',
        component: PrizePayoutComponent
    },
    {
        url: '/live-player-stats',
        name: 'live-player-stats',
        component: LivePlayerStatsComponent
    },
    {
        url: '/player-props',
        name: 'player-props',
        component: PlayerPropsComponent
    },
    {
        url: '/contest-tickets',
        name: 'contest-tickets',
        component: ContestTicketsComponent
    },
    {
        url: '/house-tickets',
        name: 'house-tickets',
        component: HouseTicketsComponent
    },
    {
        url: '/landing-pages',
        name: 'landing-pages',
        component: LandingPageComponent
    },
    {
        url: '/promo-codes',
        name: 'promo-codes',
        component: PromoCodeComponent
    },
    {
        url: '/excluded-users',
        name: 'excluded-users',
        component: ExcludedUsersComponent
    },
    {
        url: '/headshots',
        name: 'headshots',
        component: HeadshotsComponent
    }

    /*  {
          url : '/contest-player-stats',
          name : 'contest-player-stats', 
          component : ContestPlayerStats
      }*/

]