import { Component, OnInit } from '@angular/core';
import { Http, Headers, Response } from '@angular/http';
import { FormGroup, FormBuilder, Validators, FormsModule } from '@angular/forms';
import { StateService, UIRouter } from '@uirouter/angular';
import { Moment } from 'moment';
import { DatePipe } from '@angular/common';

// service
import { UserDetailsService } from '../services/user-details.service';
import { HttpService } from '../services/http.service';
import { HttpClientModule, HttpRequest } from '@angular/common/http';

// component


import { SERVER_NAME, APIS } from '../config';

declare const moment: any


@Component({
    selector: 'app-promo-code',
    templateUrl: './promo-code.component.html',
    styleUrls: ['./promo-code.component.scss'],
    providers: [HttpService] // ,DatePipe]
})

export class PromoCodeComponent implements OnInit {

    constructor(private _httpService: HttpService, private _userDetailsService: UserDetailsService) { }

    // this._httpService.httpRequest();

    promoCode: '';
    landingPageId: null;
    expirationDate: '';
    dateCreated: '';
    redirectLink: '';
    playnowRedirectLink: '';

    listOfLPs = [];
    landingPage = null;


    // landing_page_id: '';

    landingPagesListingData() {
        let listingData: any = {
            "promoCode": null,
            "landingPageId": null,
            "expirationDate": null,
            "dateCreated": null,
            "redirectLink": null,
            "playnowRedirectLink": null,
        }


        this._httpService.httpRequest('POST', 'fetchLandingPages', listingData, false)
            .then(Response => {
                // console.log("HERE", Response['response']);
                this.listOfLPs = Response['response'];
                console.log('LPs:', this.listOfLPs);


            })
            .catch(data => {

            })
    }

    ngOnInit() {
        this.landingPagesListingData();
    }


    onSubmit() {
        console.log('Promo Code Info: ', this.landingPageId);

        // this.listOfLPs.forEach(lp => {
        //     console.log("TEST", lp['name'], this.landingPageId);
        //     if (lp['id'] === this.landingPageId) {
        //         console.log("MATCH FOUND")
        //         this.landingPage = lp;
        //     }
        // });



        let requestData = {
            "promoCode": this.promoCode,
            'landingPageId': this.landingPageId,
            'expirationDate': this.expirationDate,
            'dateCreated': this.dateCreated,
            'redirectLink': this.redirectLink,
            'playnowRedirectLink': this.playnowRedirectLink
        }




        this._httpService.httpRequest('POST', 'newPromoCode', requestData, true)

            .then(Response => {
                // this._userDetailsService.showSucccess(Response.response.message);
                this._userDetailsService.showSuccess(Response['message']);
                console.log(Response);

            })
            .catch(data => {
                this._userDetailsService.showError(data.message);
            });

    }
}