import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder , Validators} from '@angular/forms';

import { SERVER_NAME, APIS, DateShowFormat, DateFormat } from '../config';
import { HttpService } from '../services/http.service';
import { UIRouter } from '@uirouter/angular';
import { DatePipe } from '@angular/common';
import { Moment } from 'moment';
import 'eonasdan-bootstrap-datetimepicker';
import { UserDetailsService } from '../services/user-details.service';
import { NKDatetimeModule } from "ng2-datetime/ng2-datetime";

// declare const window:any;

// CommonJS style - working with "require"
declare const $:any;
//declare const moment:any;

@Component({
  selector: 'app-create-contest',
  templateUrl: './create-contest.component.html',
  styleUrls: ['./create-contest.component.scss'],
  providers: [HttpService,DatePipe]
})
export class CreateContestComponent implements OnInit {
  createContestForm: FormGroup;
  leagueVal: any;
  value: Date;
  contestTypeVal: any;
  multipleEntry: boolean = false;
  passwordEntry: boolean = false;
  featured : boolean = false;
  dateError: boolean = false;
  propPicked: any;
  propPickedZeroError: boolean;
  propAvailable: any;
  propAvailableZeroError: boolean;
  propError: boolean = false;
  prizeError: boolean = false;
  // entryAllowError : boolean = false;
  guaranteedPrizeMoney: any;
  guarantyPrizeZeroError: boolean;
  entryFee: any;
  entryFeeZeroError: boolean;
  maxEntriesAllowed : any;
  startTime: any;
  endTime: any;
  maxEntryZeroError : boolean;
  entryNumber: any;
  password: any;
  entryPerUserZeroError: any;
  maxEntryError: boolean;
  passwordError: boolean;
  //currentDate = new Date(new Date().getTime() + (3600 * 1000));
  currentDate = new Date();
  currentDateError: boolean = false;
  leagueType : any;
  guarantyPrizeMultiplication: boolean = false;
  maxEntryPerUser: boolean = false;
  
  date: Moment;
  a2eOptions: any; 
   dateFrom: Date = new Date();

   dateChange(date) {
    this.date = date;
  }
  startDateToChange(date){
    //console.log("start:" + date);
    this.startTime = this._httpService.convertToUTC(date,"MM/DD/YYYY HH:mm:ss");
    console.log("startTime: " + this.startTime);

  }
  dateClick() {
   // console.log('start click!')
  }
  
  getTime() {
    alert('Selected time is:' + this.date);
  };

  addTime(val, selector) {
    //this.date = moment(this.date.add(val, selector));
  };

  clearTime() {
    this.date = null;
  };
  
  endDateToChange(date){
    //console.log("end:" + date);
    this.endTime = this._httpService.convertToUTC(date,"MM/DD/YYYY HH:mm:ss");
    //console.log("endtime: " + this.endTime);

  }
  dateEndClick() {
    //console.log('click end!')
  }

  datepickerOpts = {
      startDate: new Date(),
      autoclose: true,
      todayBtn: 'linked',
      todayHighlight: true,
      assumeNearbyYear: true,
      format: 'M/dd/yyyy'
  }



  dateTo: Date = new Date();
  datepickerToOpts = {
      startDate: this.dateFrom,
      autoclose: true,
      todayBtn: 'linked',
      todayHighlight: true,
      assumeNearbyYear: true,
      format: 'M/dd/yyyy'
  }

  multidayContest : boolean = false;

  constructor(private _httpService: HttpService, private fb: FormBuilder,private datePipe: DatePipe,private _uiRouter:UIRouter,private _userDetailsService: UserDetailsService) { 
    this.leagueVal = "MLB";
    this.contestTypeVal = "Traditional";
    //this.date = moment();
    this.a2eOptions = {format: 'YYYY/MM/DD HH:mm:ss'};
  }





  ngOnInit() {
    
    this.createContestForm = this.fb.group({
      leagueType: ['', []],
      type: ['', []],
      name: ['', [Validators.required]],
      startTime: ['', [Validators.required]],
      multidate: ['',],
      endTime: ['',],
      summary: ['', [Validators.required]],
      generalInfo: ['', [Validators.required]],
      entryFee: ['', [Validators.required]],
      multipleEntryAllowed: ['', []],
      passwordEntryAllowed: ['', []],
      maxEntriesPerUser: ['', []],
      passwordForContest: ['', []],
      guaranteedPrizeMoney: ['', [Validators.required]],
      createdPropCount: [''],
      minPropsToPick: ['', [Validators.required]],
      maxEntriesAllowed : ['',[Validators.required]],
      featured: ['', []],
      extraPrize: ['', []]
    })

    
    $('.copyDisable').bind("cut copy paste",function(e) {
        e.preventDefault();
    });


    // window.prizeoutSDK.init({
    //   env: "sandbox", // or `prod`
    //   user: {
    //     firstname: "user's firstname",
    //     lastname: "user's lastname",
    //     year_of_birth: "user's year of birth",
    //     gender: "user's gender",
    //     email: "user's email",
    //     user_id: "user ID",
    //     country: "user's country",
    //     region: "user's region",
    //     balance: "current balance"
    //   },
    //   publisher: {
    //     name: "Thrive",
    //     logo: false,
    //     id: "bad46139-c5d6-4fbd-8c96-2e36b1e44087",
    //     apikey: "ac4d367b34b640e43b8f8282d235ade3",
    //     allowDestEmailChange: false,
    //     balanceCopy: "Balance"
    //   },
    //   callbacks: {
    //     onInit: function (payload) {
    //       console.log("Widget Started", payload);
    //     },
    //     onClose: function (payload) {
    //       console.log("Widget Closed", payload);
    //     },
    //     onCashoutFail: function (payload) {
    //       console.log("Cashout Failed", payload);
    //     },
    //     onCashoutSuccess: function (payload) {
    //       console.log("Cashout Completed", payload);
    //     }
    //   }
    // });

   
  }

  createContestFormSubmit(val){
    
    this.dateError = false;
    this.propPickedZeroError = false;
    this.propAvailableZeroError = false;
    // this.entryAllowError = false;
    this.guarantyPrizeZeroError = false;
    this.entryFeeZeroError = false;
    this.maxEntryZeroError = false;
    this.entryPerUserZeroError = false;
    this.maxEntryError = false;
    this.passwordError = false;
    this.currentDateError = false;
    this.propError = false;
    this.prizeError = false;
    this.guarantyPrizeMultiplication = false;
    this.maxEntryPerUser = false;
    var ONE_HOUR = 60 * 60 * 1000;
    
    
    // else if(parseInt(this.propPicked) >= parseInt(this.propAvailable)){
    //   this.propError = true;
    // }
    // else if (this.propAvailable < 3){
    //   this.propAvailableZeroError = true;
    // }
    if (parseInt(this.guaranteedPrizeMoney) <= parseInt(this.entryFee)){
      this.prizeError = true;
    }else if(this.dateFrom < this.currentDate){
       this.currentDateError = true;
     }
    // else if(parseInt(this.maxEntriesAllowed) > 10000){
    //    this.entryAllowError = true;
    // }
    else if ((parseInt(this.maxEntriesAllowed) < parseInt(this.entryNumber)) && this.multipleEntry){
      this.maxEntryError = true;
    }
    else if (this.passwordEntry && (this.password.length < 1)) {
      this.passwordError = true;
    }
    else if (this.propPicked < 1){
      this.propPickedZeroError = true;
    }
    else if (this.maxEntriesAllowed < 1){
      this.maxEntryZeroError = true;
    }
    // Brendon Brinkmann 2-6-18 changed minimun entry fee and prize money
    /*else if(this.entryFee < 0){
      this.entryFeeZeroError = true;
    }*/
    //brendon brinkmann 2-6-18 changed minimum entry fee and prize money
    else if(this.entryFee < 0){
      this.entryFeeZeroError = true;
    }
    //brendon brinkmann 2-6-18 changed minimum entry fee and prize money
    else if(this.guaranteedPrizeMoney < 0){
      this.guarantyPrizeZeroError = true;
    }
    else if((this.entryNumber < 1) && this.multipleEntry){
      this.entryPerUserZeroError = true;
    }


    /* Brendon Brinkmann 2-6-18 commented out guaranteedPrizeMoney validation
    else if ((parseInt(this.maxEntriesAllowed) * parseInt(this.entryFee)) < this.guaranteedPrizeMoney){
      this.guarantyPrizeMultiplication = true;
    }
    */
    //brendon brinkmann 2-6-18 updated guarantyPrizeMultiplication validation 
    /*else if ((parseInt(this.maxEntriesAllowed) * parseInt(this.entryFee)) <= this.guaranteedPrizeMoney){
      this.guarantyPrizeMultiplication = true;
    }*/
    
    else if((parseInt(this.entryNumber) >10000)  && (this.multipleEntry)){
      this.maxEntryPerUser = true;
    }
    // else if(this.dateTo && (new Date(this.dateTo) <= new Date(this.dateFrom))){
    //   this.dateError = true;
    // }
    else{

      // var startTime  = moment().format('MM/dd/yyyy HH:mm:ss');
      // var endTime  = moment().format('MM/dd/yyyy HH:mm:ss');
       
      //let startTimeEST = moment.utc(val.startTime).utcOffset(-4).format('MM/DD/YYYY HH:mm:ss');
      // console.log(startTimeEST);
      // let endTimeEST = moment.utc(val.endTime).utcOffset(-4).format('MM/DD/YYYY HH:mm:ss');
      let startTimeUTC = this._httpService.convertToUTC(val.startTime,"MM/DD/YYYY HH:mm:ss");
      let endTimeUTC = this._httpService.convertToUTC(val.endTime,"MM/DD/YYYY HH:mm:ss");

       //console.log("old endtime: " + val.endtime);
       //console.log("old end time:" + endTimeUTC);


      let requestData = {
        "leagueType": this.leagueVal,
        "type": this.contestTypeVal,
        "name":val.name,
        "startTime": this.startTime,
        'multiDayEndDateStr':val.endTime?this.datePipe.transform(val.endTime,"yyyy-MM-dd"):'',
        // "endTime":this.datePipe.transform(val.endTime,"MM/dd/yyyy HH:mm:ss"),
        //"startTime":startTimeEST,
        //"endTime":endTimeEST,
        //"startTime":this.datePipe.transform(val.startTime,"MM/dd/yyyy HH:mm:ss"),
        "summary":val.summary,
        "generalInfo":val.generalInfo,
        "entryFee":val.entryFee,
        "multipleEntryAllowed":val.multipleEntryAllowed?true:false,
        "passwordEntryAllowed": val.passwordEntryAllowed?true:false,
        "maxEntriesPerUser":val.maxEntriesPerUser,
        "passwordForContest":val.passwordForContest,
        "guaranteedPrizeMoney":val.guaranteedPrizeMoney,
        "createdPropCount":val.createdPropCount,
        "minPropsToPick":val.minPropsToPick,
        "maxEntriesAllowed" : val.maxEntriesAllowed,
        "featured":val.featured?true:false,
        "extraPrize":val.extraPrize
      }
      
      this._httpService.httpRequest('POST', 'createContest',requestData, true)
      .then(Response => {
        let responseData : any = Response;

        this._uiRouter.stateService.go('price-payload',{
          'startTime':this.datePipe.transform(val.startTime,"yyyy-MM-dd"),
          'endTime':val.endTime?this.datePipe.transform(val.endTime,"yyyy-MM-dd"):this.datePipe.transform(val.startTime,"yyyy-MM-dd"),
          'leagueType': this.leagueVal,
          'type': this.contestTypeVal,
          'guaranteedPrizeMoney': requestData.guaranteedPrizeMoney,
          'adminContestId':responseData.response.contestCreation.id,
          'maxEntriesAllowed': this.maxEntriesAllowed,
          'similarContestId':''
        });
      })
      .catch(data => {
        this._userDetailsService.showError(data.message);
      })
    }
  }

  leagueCheckFun(val){
    // console.log('league val', val);
    this.leagueVal = val;
  }

  contestTypeFun(val){
    //console.log('contestType', val);
    this.contestTypeVal = val;
  }

  startDateChange(event){

    this.datepickerToOpts = {
      startDate: new Date(event),
      autoclose: true,
      todayBtn: 'linked',
      todayHighlight: true,
      assumeNearbyYear: true,
      format: 'M/dd/yyyy'
    }
    this.dateTo = new Date(event);
  }

  updateCheckedOptions(event){
    //console.log(event.target.checked);
    if(event.target.checked){
      this.multidayContest = true;
    }else{
      this.multidayContest = false;
    }
  }

}
