import { Component, OnInit, ViewChild} from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Moment } from 'moment';
import { FormGroup, FormBuilder , Validators} from '@angular/forms';

import { HttpService } from '../services/http.service';



@Component({
  selector: 'app-create-a-ticket-modal',
  templateUrl: './create-a-ticket-modal.component.html',
  styleUrls: ['./create-a-ticket-modal.component.css']
})
export class CreateATicketModalComponent implements OnInit {
  @ViewChild('createTicketModal') createTicketModal: ModalDirective;
  date: Moment;
  dateTo: Date = new Date();
  dateString: String = new Date().toLocaleDateString(); 
  constructor(
    private _httpService: HttpService,
    private fb: FormBuilder,
  ) { }

  ngOnInit() {
  }

  contestForm = this.fb.group({
    leagues: ['', []],
    dollarAmount: ['', []],
    contestId: ['', []],
  });

  selectedLeagues = []

  currContestId;;
  selectedContestIds = []

  selectedDollarAmount;
  
  possibleLeagues = ['NFL', 'MLB', 'NBA', 'CSGO', 'PGA', 'LOL','DOTA']
 
  showError = false;


  show(player, eventPropsdata, adminContestId, teamid, leagueType) {
    this.createTicketModal.show();
  }

  closeModal(){
    this.createTicketModal.hide();
  }

  dateChange(date) {
    this.date = date;
  }

  setSelectedDollarAmount(amount){
    this.selectedDollarAmount=amount;
    console.log(this.selectedDollarAmount)
  }

  setContestId(contestId) {
    this.currContestId = contestId;
  }

  addSelectedContestId() {
    if(this.selectedLeagues.length <= 0) {
      this.showError = false;
    var newSelection = true;
    for(var contest of this.selectedContestIds) {
      if(this.currContestId === contest) {
        newSelection = false;
      }
    }
    if(newSelection) {
      this.selectedContestIds.push(this.currContestId);
    }
    this.currContestId = '';
  } else {
    this.showError = true;
  }
  }

  removeSelectedContestId(contestId){
    var selectedContestIds = this.selectedContestIds;
    for(var i=0; i< selectedContestIds.length; i++){
      if(contestId == selectedContestIds[i]){
        selectedContestIds.splice(i,1)
      }
    }
  }

  addSelectedLeague(lg){
    if(this.selectedContestIds.length <= 0) {
      this.showError = false;
    var newSelection = true;
    if(lg === "--Pick League(s)--"){
      newSelection = false;
    }
    for(var league of this.selectedLeagues){
      if(lg === league){
        newSelection = false;
      }
    }
    if(newSelection){
      this.selectedLeagues.push(lg);
    }
    console.log(this.selectedLeagues)
  console.log(this.selectedLeagues.length)
  } else {
    this.showError = true;
  }
}

  removeSelectedLeague(lg){
    var selectedLeagues = this.selectedLeagues;
    for(var i=0; i< selectedLeagues.length; i++){
      if(lg == selectedLeagues[i]){
        selectedLeagues.splice(i,1)
      }
    }
  }

  toggle() {
    this.showError = !this.showError;
  }

  createTicket(){

    var contestTicketDto = {
      "leagues":this.selectedLeagues,
      "dollarAmount": this.selectedDollarAmount,
      "contestIds": this.selectedContestIds.map(Number)

    }

    console.log(typeof this.selectedLeagues);
    console.log(typeof this.selectedContestIds);

    if(this.selectedLeagues.length <= 0 && this.selectedContestIds.length <=0){
      console.log('both are empty');
      return;
    }
    
    if(this.selectedLeagues.length > 0 && this.selectedContestIds.length > 0) {
      console.log('both are populated');
      return;
    }

    if(this.selectedContestIds.length > 0 && (this.selectedDollarAmount != null || this.selectedDollarAmount > 0)) {
      console.log('cant have a contest id and a dollar amount');
      return;
    }


    console.log(contestTicketDto);
    this._httpService.httpRequest('POST','createTicket', JSON.stringify(contestTicketDto), true)
    .then(Response => {
      let responseData : any = Response;
      if(responseData["success"]){
        alert("Contest Ticket Successfully Created.")
        location.reload()
      }
      console.log(responseData)
    })
    .catch(data => {

    })
  }

}