import { Component, OnInit } from '@angular/core';
import { Http, Headers, Response } from '@angular/http';
import { FormGroup, FormBuilder, Validators, FormsModule } from '@angular/forms';
import { StateService, UIRouter } from '@uirouter/angular';
import { Moment } from 'moment';
import { DatePipe } from '@angular/common';

// service
import { UserDetailsService } from '../services/user-details.service';
import { HttpService } from '../services/http.service';
import { HttpClientModule, HttpRequest } from '@angular/common/http';

// component


import { SERVER_NAME, APIS } from '../config';

declare const moment: any


@Component({
    selector: 'app-excluded-users',
    templateUrl: './excluded-users.component.html',
    styleUrls: ['./excluded-users.component.scss'],
    providers: [UserDetailsService, HttpService]
})

export class ExcludedUsersComponent {
    userUserRoles: any[] = [];
    constructor(
        private _http: Http,
        private _userDetailsService: UserDetailsService,
        private _httpService: HttpService,
        private fb: FormBuilder,
        private _uiRouter: UIRouter
    ) {}

    isUserHouseTicketDisplay = true
    houseTickets=[]

    ngOnInit() {
        this.getUserUserRoles()
    }

    // disableUserHouseTicket(userHouseTicketId){
    //     userHouseTicketId = String(userHouseTicketId)
    //     this._httpService.httpRequest('GET','disableUserHouseTicket', "userHouseTicketId=" + userHouseTicketId, true)
    //         .then(Response => {
    //             let responseData : any = Response;
    //             location.reload()
    //             alert(responseData.message)
    //             console.log(this.unusedUserHouseTickets[0]["username"])
    //
    //         })
    // }

    deleteUserUserRole(UserID){

        var r = confirm("Are you sure you want to delete this user role?");

        if (r == true) {
            this._httpService.httpRequest('GET','deleteUserUserRole',"userId="+UserID, true)
                .then(Response => {
                    let responseData: any = Response;
                    this._userDetailsService.showSuccess(responseData.message);
                    this._uiRouter.stateService.reload();
                })
                .catch(data => {
                    this._userDetailsService.showError(data.message);
                })
        } else {

        }
    }

    getUserUserRoles(){
        this._httpService.httpRequest('GET','getUserUserRoles', {}, true)
            .then(Response => {
                let responseData : any = Response;
                console.log(responseData);
                this.userUserRoles = responseData.response;
                console.log("unusedTickets: " + this.userUserRoles )
            })
    }
}