import { Component, OnInit, ViewChild} from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Moment } from 'moment';
import { FormGroup, FormBuilder , Validators} from '@angular/forms';

import { HttpService } from '../services/http.service';

@Component({
  selector: 'app-user-tickets-by-contest-tickets-modal',
  templateUrl: './user-tickets-by-contest-tickets-modal.component.html',
  styleUrls: ['./user-tickets-by-contest-tickets-modal.component.css']
})
export class UserTicketsByContestTicketsModalComponent implements OnInit {
	@ViewChild('userTicketsByContestTicketsModal') userTicketsByContestTicketsModal: ModalDirective;
  constructor(
  	private _httpService: HttpService
  	) { }

  userContestTickets = []
  ngOnInit() {
  }


  show(contestTicketId) {
    this.userTicketsByContestTicketsModal.show();
    this.getUserContestTicketsByContestTicket(contestTicketId);
  }

  closeModal(){
    this.userTicketsByContestTicketsModal.hide();
  }

  getUserContestTicketsByContestTicket(contestTicketId){
    this._httpService.httpRequest('GET','getUserTicketsByContestTicket', "contestTicketId=" + contestTicketId, true)
    .then(Response => {
      let responseData : any = Response;
      this.userContestTickets = responseData.response;
    })
  }
}
