import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Http, Headers, Response } from '@angular/http';
import { StateService, UIRouter } from '@uirouter/angular';
import { SERVER_NAME, APIS } from '../config';
import { HttpService } from '../services/http.service';
import { UserDetailsService } from '../services/user-details.service';
import { uniqR } from '@uirouter/angular';
import { queryDef } from '@angular/core/src/view/query';
import * as _ from 'lodash';
declare let jsPDF;
import * as html2canvas from "html2canvas"
import * as autoTable from 'jspdf-autotable'

@Component({
  selector: 'app-live-stats-modal',
  templateUrl: './live-stats-modal.component.html',
  styleUrls: ['./live-stats-modal.component.scss']
})
export class LiveStatsModalComponent implements OnInit {
  @ViewChild('liveStatsModal') liveStatsModal: ModalDirective;

  playerDetail: any;
  username: any;
  leaugeStatus: any;
  userPropsArray: any = [];
  currentPage: number = 1;
  itemsPerPage: number = 10;
  numberOfItem: number;
  totalPages: number;
  collection: any[] = [];
  entrants: any;
  statsArrayLength: any;
  uniqueArray: any;
  p1props: any = [];
  p2props: any = [];
  sortedPercentageArray: any = [];
  idArray: any = [];
  percentageArray: any = [];
  propPicks: any = [];
  finalArray: any = [];
  result: any = [];
  sorted: any = [];
  waitingRequest: Boolean = false;
  unique: any = [];
  constructor(
    private _uiRouter: UIRouter,
    private _httpService: HttpService,
    private _userDetails: UserDetailsService,
    private _userDetailsService: UserDetailsService) {


    //this.contestId = this._uiRouter.stateService.params.contestId;
    this.leaugeStatus = this._uiRouter.stateService.params.leaugeStatus;
    console.log(this.leaugeStatus);
    //console.log('this.contestId', this.contestId);
  }

  ngOnInit() {

  }

  show(contestID/*username,pick,leaugeStatus*/) {
    console.log("rick: " + contestID);
    //console.log('modal player data', pick,leaugeStatus);
    this.userPropsArray = [];
    this.uniqueArray = [];
    this.idArray = [];
    this.percentageArray = [];
    this.sortedPercentageArray = [];
    this.getEntrantsList(contestID);
    //this.ownPercentage();

    // this.liveStatsModal.show();

    //this.playerDetail = pick;
    //this.username = this.userPropsArray[0].contestType;

    //this.leaugeStatus = leaugeStatus;

    // console.log('playerDetail: ' + this.playerDetail +  'username' + this.username + 'leaugeStatus' + this.leaugeStatus);
  }
  removeDuplicates(originalArray, objKey) {
    var trimmedArray = [];
    var values = [];
    var value;

    for (var i = 0; i < originalArray.length; i++) {
      value = originalArray[i][objKey];

      if (values.indexOf(value) === -1) {
        trimmedArray.push(originalArray[i]);
        values.push(value);
      }
    }

    return trimmedArray;

  }

  checkIce(overP, underP) {
    if (overP == 'ICE') {
      return "ICE PICK";
    } else {
      return "Over: " + overP + "%, " + "Under: " + underP + "%";
    }
  }

  download() {

    // Save the PDF
    // doc.save('Test.pdf')

    var columns = [
      { title: "Player Name", dataKey: "name" },
      { title: "Game Detail", dataKey: "game" },
      { title: "Live Stats", dataKey: "stats" },
      { title: "Prop Values", dataKey: "props" },
      { title: "Own Percentage", dataKey: "percentage" }
    ];
    var row: any = [];

    for (var q = 0; q < this.uniqueArray.length; q++) {
      var statArray: any = [];
      for (var e = 0; e < this.uniqueArray[q].playerProps.length; e++) {
        statArray.push(" " + this.uniqueArray[q].playerProps[e].name + ": " + this.uniqueArray[q].playerProps[e].liveStatsPropValue);
      }
      console.log(statArray);
      row.push({ "name": this.uniqueArray[q].name, "game": (this.uniqueArray[q].quarter || 0) + " " + this.uniqueArray[q].team1 + " " + (this.uniqueArray[q].team1Score || 0) + "-" + this.uniqueArray[q].team2 + " " + (this.uniqueArray[q].team2Score || 0), "stats": statArray, "props": "Prop: " + this.uniqueArray[q].propValue + ", " + "Over: " + this.uniqueArray[q].overPoints + ", " + "Under: " + this.uniqueArray[q].underPoints + ", ", "percentage": this.checkIce(this.sorted[q].overPercentage, this.sorted[q].underPercentage) })
    }


    //console.log(row);
    var rows = row;

    //console.log(JSON.stringify(rows, null, 2));
    // Only pt supported (not mm or in)
    var doc = new jsPDF('l', 'pt');
    doc.autoTable(columns, rows, {
      styles: {
        theme: 'striped', // 'striped', 'grid' or 'plain' 
        styles: {},
        headerStyles: {},
        bodyStyles: {},
        alternateRowStyles: {},
        columnStyles: {},

        // Properties 
        startY: false, // false (indicates margin top value) or a number 
        margin: 40, // a number, array or object 
        pageBreak: 'auto', // 'auto', 'avoid' or 'always' 
        tableWidth: 'auto', // 'auto', 'wrap' or a number,  
        showHeader: 'everyPage', // 'everyPage', 'firstPage', 'never', 
        tableLineColor: 200, // number, array (see color section below) 
        tableLineWidth: 0,

        // Hooks 
        createdHeaderCell: function (cell, data) { },
        createdCell: function (cell, data) { },
        drawHeaderRow: function (row, data) { },
        drawRow: function (row, data) { },
        drawHeaderCell: function (cell, data) { },
        drawCell: function (cell, data) { },
        addPageContent: function (data) { }
      },
      columnStyles: {
        id: { fillColor: 255 }
      },
      margin: { top: 60 },
      addPageContent: function (data) {
        doc.text("Contest", 40, 30);
      }
    });
    doc.save('table.pdf');
  }

  ownPercentage() {
    this.finalArray = [];
    var sortedArray = [];
    //console.log("own percentage")
    var result = _(this.propPicks)
      .groupBy('propId')
      .map(function (item, itemId) {
        var obj = {};
        obj[itemId] = _.countBy(item, 'isOver')
        return obj
      }).value();


    for (var v = 0; v < this.userPropsArray.length; v++) {
      for (var m = 0; m < result.length; m++) {
        //console.log(this.userPropsArray[v].propId);
        //console.log("USER PROP ID: " + Object.keys(result[m])[0] + ", userproparray: " + this.userPropsArray[v].propId);
        if (Object.keys(result[m])[0] == this.userPropsArray[v].propId) {
          //console.log(this.userPropsArray[v].propId)
          //console.log(result[m][this.userPropsArray[v].propId]);
          this.finalArray.push({ "propId": Object.keys(result[m])[0], "overPercentage": (result[m][this.userPropsArray[v].propId].true / this.entrants.length) * 100, "underPercentage": (result[m][this.userPropsArray[v].propId].false / this.entrants.length) * 100 })
          //console.log("MATCH");
        }
      }
    }

    //console.log(this.userPropsArray);
    sortedArray = _.uniqBy(this.finalArray, 'propId');
    console.log(sortedArray);
    //console.log(sortedArray);
  }

  getPercentage(arr) {
    var a = [], b = [], prev;

    arr.sort();
    for (var i = 0; i < arr.length; i++) {
      if (arr[i] !== prev) {
        a.push(arr[i]);
        b.push(1);
      } else {
        b[b.length - 1]++;
      }
      prev = arr[i];
    }

    var returnedObj = [];
    returnedObj.push({
      "contestId": a, "ownedPercentage": b
    }
    )
    return returnedObj;
  }

  getEntrantsList(contestID) {
    //console.log(contestId);
    //console.log(this.currentPage);
    //console.log(this.itemsPerPage);
    //console.log(contestID);
    // console.log(this.itemsPerPage);
    let getEntrantListObj = {
      "pageNo": this.currentPage,
      "pageSize": 95,//this.itemsPerPage,
      "contestId": contestID
    }

    //(getEntrantListObj);
    this._httpService.httpRequest('POST', 'getEntrantsContestList', getEntrantListObj, true)
      .then(Response => {
        let response: any = Response;

        //console.log(response);
        this.entrants = response.response.data;
        //console.log(this.entrants);
        //this.pagination = response.response.pagination;



        if (this.leaugeStatus != 'upcoming') {
          //console.log('!upcoming');
          //console.log("not upcomingx");
          // console.log(this.entrants.length);
          this.propPicks = [];

          for (let i = 0; i < this.entrants.length; i++) {
            // console.log("entrants, user prop picks");
            let upp = this.entrants[i].userPropPicks;
            //console.log(upp);
            //console.log(upp[v]);

            //console.log("entrants length" + this.entrants.length);
            // console.log(upp, ' ----> ' + !upp[0].startTimeEvent2);
            if (!upp[0].startTimeEvent2) {
              //console.log("traditional");
              for (var v = 0; v < upp.length; v++) {
                //console.log(upp[v]);
                //console.log(upp[v]);
                this.userPropsArray.push({
                  "propId": upp[v].propId,
                  "name": upp[v].player1Name, "team1": upp[v].team1Abbr, "team2": upp[v].team2Abbr, "team1locationType": upp[v].team1locationType,
                  "team2locationType": upp[v].team2locationType, "playerProps": upp[v].propParameters, "contestType": "traditional",
                  "propValue": upp[v].propValue, "overPoints": upp[v].overPoints, "underPoints": upp[v].underPoints, "ownedPercentage": upp[v].ownedPercentage,
                  "quarter": upp[v].inningEvent, "team1Score": upp[v].team1Score, "team2Score": upp[v].team2Score, "isICE": upp[v].isICE
                });

                if (!upp[v].isICE) {
                  this.propPicks.push({ "player1": upp[v].player1Name, "propId": upp[v].propId, "isOver": upp[v].over })
                } else {
                  // console.log("ICE PICK : " + upp[v].player1Name)
                  this.propPicks.push({ "player1": upp[v].player1Name, "propId": upp[v].propId, "isOver": "ICE" })
                }
                //console.log("PROP PICKS");

                //console.log(JSON.stringify(this.propPicks, null, 2));
                this.result = _(this.propPicks)
                  .groupBy('propId')
                  .map(function (item, itemId) {
                    var obj = {};
                    //                      if(item.isOver != "ICE"){
                    obj[itemId] = _.countBy(item, 'isOver');
                    //  }

                    return obj
                  }).value();


                //console.log(this.result);

              }




              //console.log("result length: " + result.length)
              //console.log(result);
              /*for (var m = 0; m < result.length; m++ ){
                console.log("iteration ["+m+"]");
      
   
              
                this.finalArray.push({
                  "propId":Object.keys(result[m])[0],
                  "overPercentage":result[""+Object.keys(result[m])[0]],///this.entrants.length,
                  "underPercentage": result[""+Object.keys(result[m])[0]]///this.entrants.length
                })
               }*/
              //console.log(result);

              /*for(var v = 0; v < this.userPropsArray.length; v++){
                 for(var m = 0; m < result.length; m++){
                   //console.log(this.userPropsArray[v].propId);
                   //console.log("USER PROP ID: " + Object.keys(result[m])[0] + ", userproparray: " + this.userPropsArray[v].propId);
                   if (Object.keys(result[m])[0] == this.userPropsArray[v].propId){
                     console.log(this.userPropsArray[v].propId)
                     console.log(result[m][this.userPropsArray[v].propId].objKey);
                     //this.finalArray.push({"propId": this.userPropsArray[v].propId, "overPercentage": result[m].true, "underPercentage": result[m].false})
                     console.log("MATCH");
   
                   }
                 }
               }
              */



              //console.log(this.finalArray);
              //  console.log(JSON.stringify(result, null, 2));
              //console.log(this.propPicks);
              //console.log(result);
              //console.log("TTTTT");

              //console.log(this.userPropsArray);


              for (var x = 0; x < this.userPropsArray.length; x++) {
                this.idArray.push(this.userPropsArray[x].propId);
              }

              //console.log(idArray)
              // console.log(this.getPercentage(idArray)["0"].contestId);
              this.percentageArray = this.getPercentage(this.idArray)["0"].contestId;


              this.uniqueArray = this.removeDuplicates(this.userPropsArray, "propId");

              //console.log("unique array")
              //console.log(this.uniqueArray);
              //console.log("percentage array")
              //console.log(this.percentageArray);


              for (var p = 0; p < this.uniqueArray.length; p++) {
                for (var v = 0; v < this.percentageArray.length; v++) {
                  //console.log(this.uniqueArray[p]);
                  //console.log(percentageArray[v]);

                  if (this.uniqueArray[p].propId == this.percentageArray[v]) {
                    this.sortedPercentageArray.push(((this.getPercentage(this.idArray)["0"].ownedPercentage[v] / this.userPropsArray.length) * 100).toFixed(2));
                  }
                }
              }

              //console.log("BBBBBBBBB");
              //console.log(this.uniqueArray);
              //console.log("AAAAAAA");
              //console.log(this.sortedPercentageArray);

              this.itemsPerPage = 95//this.uniqueArray.length;
              //console.log(this.uniqueArray);

              // this.liveStatsModal.show();

              //this.waitingRequest = true;
            } else {
              console.log("versus");
              for (var v = 0; v < upp.length; v++) {
                // console.log(upp[v]);
                //console.log('upplength ' + upp.length);
                if (upp[v].isOver == true) {
                  this.userPropsArray.push({
                    /*"player1": {
                      "name": upp[v].player1Name, "team1": upp[v].team1Abbr, "team2": upp[v].team2Abbr, "team1locationType": upp[v].team1locationType, "team2locationType": upp[v].team2locationType,
                       "playerProps": upp[v].propParameters, "contestType": "versus", "propValue": upp[v].propValue, "overPoints": upp[v].overPoints, "underPoints": upp[v].underPoints, "ownedPercentage": upp[v].ownedPercentage,
                       "quarter": upp[v].inningEvent, "team1Score": upp[v].team1Score, "team2Score": upp[v].team2Score
                    }, "player2": {
                      "name": upp[v].player2Name, "team1": upp[v].team1AbbrEvent2, "team2": upp[v].team2AbbrEvent2, "team1locationType": upp[v].team1locationTypePlayer2, "team2locationType": upp[v].team2locationTypePlayer2,
                       "playerProps": upp[v].propParameters, "contestType": "versus", "propValue": upp[v].propValue, "overPoints": upp[v].overPoints, "underPoints": upp[v].underPoints, "ownedPercentage": upp[v].ownedPercentage,
                       "quarter": upp[v].inningEvent2, "team1Score": upp[v].team1ScoreEvent2, "team2Score": upp[v].team2ScoreEvent2
                    }       */

                    "p1name": upp[v].player1Name, "propId": upp[v].propId + "", "pickedPlayer1": upp[v].isOver, "p1team1": upp[v].team1Abbr, "p1team2": upp[v].team2Abbr, "p1team1locationType": upp[v].team1locationType, "p1team2locationType": upp[v].team2locationType,
                    "p1playerProps": upp[v].propParameters, "contestType": "versus", "p1propValue": upp[v].propValue, "p1overPoints": upp[v].overPoints, "p1underPoints": upp[v].underPoints, "p1ownedPercentage": upp[v].ownedPercentage,
                    "p1quarter": upp[v].inningEvent, "p1team1Score": upp[v].team1Score, "p1team2Score": upp[v].team2Score, "p2name": upp[v].player2Name, "p2team1": upp[v].team1AbbrEvent2, "p2team2": upp[v].team2AbbrEvent2, "p2team1locationType": upp[v].team1locationTypePlayer2, "p2team2locationType": upp[v].team2locationTypePlayer2,
                    "p2playerProps": upp[v].propParameters, "p2contestType": "versus", "propValue": upp[v].propValue, "overPoints": upp[v].overPoints, "underPoints": upp[v].underPoints, "ownedPercentage": upp[v].ownedPercentage,
                    "p2quarter": upp[v].inningEvent2, "p2team1Score": upp[v].team1ScoreEvent2, "p2team2Score": upp[v].team2ScoreEvent2, "player1Advantage": upp[v].player1Advantage, "player2Advantage": upp[v].player2Advantage, "player1Points": upp[v].player1Points, "player2Points": upp[v].player2Points
                  });

                } else {
                  this.userPropsArray.push({
                    /*"player1": {
                      "name": upp[v].player1Name, "team1": upp[v].team1Abbr, "team2": upp[v].team2Abbr, "team1locationType": upp[v].team1locationType, "team2locationType": upp[v].team2locationType,
                       "playerProps": upp[v].propParameters, "contestType": "versus", "propValue": upp[v].propValue, "overPoints": upp[v].overPoints, "underPoints": upp[v].underPoints, "ownedPercentage": upp[v].ownedPercentage,
                       "quarter": upp[v].inningEvent, "team1Score": upp[v].team1Score, "team2Score": upp[v].team2Score
                    }, "player2": {
                      "name": upp[v].player2Name, "team1": upp[v].team1AbbrEvent2, "team2": upp[v].team2AbbrEvent2, "team1locationType": upp[v].team1locationTypePlayer2, "team2locationType": upp[v].team2locationTypePlayer2,
                       "playerProps": upp[v].propParameters, "contestType": "versus", "propValue": upp[v].propValue, "overPoints": upp[v].overPoints, "underPoints": upp[v].underPoints, "ownedPercentage": upp[v].ownedPercentage,
                       "quarter": upp[v].inningEvent2, "team1Score": upp[v].team1ScoreEvent2, "team2Score": upp[v].team2ScoreEvent2
                    }       */

                    "p1name": upp[v].player1Name, "propId": upp[v].propId + 'b', "pickedPlayer1": upp[v].isOver, "p1team1": upp[v].team1Abbr, "p1team2": upp[v].team2Abbr, "p1team1locationType": upp[v].team1locationType, "p1team2locationType": upp[v].team2locationType,
                    "p1playerProps": upp[v].propParameters, "contestType": "versus", "p1propValue": upp[v].propValue, "p1overPoints": upp[v].overPoints, "p1underPoints": upp[v].underPoints, "p1ownedPercentage": upp[v].ownedPercentage,
                    "p1quarter": upp[v].inningEvent, "p1team1Score": upp[v].team1Score, "p1team2Score": upp[v].team2Score, "p2name": upp[v].player2Name, "p2team1": upp[v].team1AbbrEvent2, "p2team2": upp[v].team2AbbrEvent2, "p2team1locationType": upp[v].team1locationTypePlayer2, "p2team2locationType": upp[v].team2locationTypePlayer2,
                    "p2playerProps": upp[v].propParameters, "p2contestType": "versus", "propValue": upp[v].propValue, "overPoints": upp[v].overPoints, "underPoints": upp[v].underPoints, "ownedPercentage": upp[v].ownedPercentage,
                    "p2quarter": upp[v].inningEvent2, "p2team1Score": upp[v].team1ScoreEvent2, "p2team2Score": upp[v].team2ScoreEvent2, "player1Advantage": upp[v].player1Advantage, "player2Advantage": upp[v].player2Advantage, "player1Points": upp[v].player1Points, "player2Points": upp[v].player2Points
                  });
                }
                // console.log("ironman: " + this.uniqueArray.length);



              }

              for (var x = 0; x < this.userPropsArray.length; x++) {
                this.idArray.push(this.userPropsArray[x].propId);
              }

              //console.log("userpropsarray")
              //console.log(this.userPropsArray);

              this.percentageArray = this.getPercentage(this.idArray)["0"].contestId;

              this.uniqueArray = this.removeDuplicates(this.userPropsArray, "propId");

              // console.log("unique array")
              // console.log(this.uniqueArray);
              //  console.log("percentage array")
              // console.log(this.percentageArray);

              for (var p = 0; p < this.uniqueArray.length; p++) {
                for (var v = 0; v < this.percentageArray.length; v++) {
                  // console.log(this.uniqueArray[p]);
                  //  console.log(this.percentageArray[v]);

                  if (this.uniqueArray[p].propId == this.percentageArray[v]) {
                    this.sortedPercentageArray.push(((this.getPercentage(this.idArray)["0"].ownedPercentage[v] / this.userPropsArray.length) * 100).toFixed(2));
                  }
                }
              }
              this.p1props = [];
              this.p2props = [];
              //this.uniqueArray = this.userPropsArray;
              //console.log("ironman: " + this.uniqueArray.length);
              this.itemsPerPage = this.uniqueArray.length;

              //console.log(this.uniqueArray);
              /* for(var b = 0; b < this.uniqueArray.length; b++){
                 for(var n = 0; n < this.uniqueArray[b].player1.playerProps.length; n++){
                     //console.log(this.uniqueArray[b].player1.playerProps[n].name + " " + this.uniqueArray[b].player1.playerProps[n].belongsToPlayer1);
                    //console.log("--");
                     
                     if(this.uniqueArray[b].player1.playerProps[n].belongsToPlayer1 == true){
                       this.p1props.push({"name": this.uniqueArray[b].player1.playerProps[n].name, "liveStatsPropValue": this.uniqueArray[b].player1.playerProps[n].name, "propParamId": this.uniqueArray[b].player1.playerProps[n].propParamId
                     });
                   }else{
                     this.p2props.push({"name": this.uniqueArray[b].player1.playerProps[n].name, "liveStatsPropValue": this.uniqueArray[b].player1.playerProps[n].name, "propParamId": this.uniqueArray[b].player1.playerProps[n].propParamId
                   });
                   }
                 }
               }*/

              for (var b = 0; b < this.uniqueArray.length; b++) {
                for (var n = 0; n < this.uniqueArray[b].p1playerProps.length; n++) {
                  //console.log(this.uniqueArray[b].player1.playerProps[n].name + " " + this.uniqueArray[b].player1.playerProps[n].belongsToPlayer1);
                  //console.log("--");

                  if (this.uniqueArray[b].p1playerProps[n].belongsToPlayer1 == true) {
                    this.p1props.push({
                      "name": this.uniqueArray[b].p1playerProps[n].name, "liveStatsPropValue": this.uniqueArray[b].p1playerProps[n].name, "propParamId": this.uniqueArray[b].p1playerProps[n].propParamId
                    });
                  } else {
                    this.p2props.push({
                      "name": this.uniqueArray[b].p1playerProps[n].name, "liveStatsPropValue": this.uniqueArray[b].p1playerProps[n].name, "propParamId": this.uniqueArray[b].p1playerProps[n].propParamId
                    });
                  }
                }
              }


              //console.log("full stats");
              //console.log(this.uniqueArray);
              //console.log("p1props ")
              //console.log(this.p1props);
              //console.log("p2props ")
              //console.log(this.p2props);
              //console.log("playerprops " + this.userPropsArray.player1);
              // this.liveStatsModal.show();
            }
            //this.uniqueArray = this.removeDuplicates(this.userPropsArray, "propId")
            //this.itemsPerPage = this.uniqueArray.length;
            //

            //console.log("items per page " + this.itemsPerPage);
            //console.log(this.userPropsArray["0"].propId);

            // console.log(this.userPropsArray);
          }

          // this.liveStatsModal.show();

          for (var m = 0; m < this.userPropsArray.length; m++) {
            for (var n = 0; n < this.result.length; n++) {
              if (this.userPropsArray[m].propId == Object.keys(this.result[n])[0]) {
                console.log(this.userPropsArray[m])
                //if(!this.userPropsArray[m].isICE){
                this.unique.push({ "propId": Object.keys(this.result[n])[0], "overPercentage": ((this.result[n][Object.keys(this.result[n])[0]].true / this.entrants.length) * 100).toFixed(2) || 0, "underPercentage": ((this.result[n][Object.keys(this.result[n])[0]].false / this.entrants.length) * 100).toFixed(2) || 0 })
                //}else{
                //this.unique.push({"propId": Object.keys(this.result[n])[0], "overPercentage": "ICE", "under": "ICE"})
                //}
              }
            }
          }

          this.sorted = _.uniqBy(this.unique, 'propId');
          // console.log(this.sorted);
          this.liveStatsModal.show();
        }
      })
      .catch(data => {
        console.log('error', data);
      })
  }

  updateParam(paramId, paramAbbr, i, j) {
    var newValue = (<HTMLInputElement>document.getElementById(i + "-" + j)).value;


    if (newValue != "") {
      this._httpService.httpRequestLambdaCombo(
        'POST',
        'adminsite-props/update_live_player_stats',
        { paramId: paramId, newValue: newValue },
        false,
        false,
        {}
      ).then(Response => {
        let responseData: any = Response;
        if (responseData.success) {
          window.alert(responseData.message);
          this.closeModal();
        } else {
          this._userDetails.showError(responseData.message)
        }
      }).catch(data => {
        this._userDetails.showError(data.message)
      })
    }
  }

  closeModal() {
    this.liveStatsModal.hide();
  }

}