import { Component, OnInit, ViewChild} from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Moment } from 'moment';
import { FormGroup, FormBuilder , Validators} from '@angular/forms';

import { HttpService } from '../services/http.service';


@Component({
  selector: 'app-create-a-house-ticket-modal',
  templateUrl: './create-a-house-ticket-modal.component.html',
  styleUrls: ['./create-a-house-ticket-modal.component.css']
})
export class CreateAHouseTicketModalComponent implements OnInit {
  @ViewChild('createHouseTicketModal') createHouseTicketModal: ModalDirective;
  date: Moment;
  dateTo: Date = new Date();
  dateString: String = new Date().toLocaleDateString(); 
  constructor(
    private _httpService: HttpService,
    private fb: FormBuilder,
  ) { }

  ngOnInit() {
  }

  contestForm = this.fb.group({
    dollarAmount: ['', []],
  });

  selectedDollarAmount;
  
 
  showError = false;


  show(player, eventPropsdata, adminContestId, teamid, leagueType) {
    this.createHouseTicketModal.show();
  }

  closeModal(){
    this.createHouseTicketModal.hide();
  }

  dateChange(date) {
    this.date = date;
  }

  setSelectedDollarAmount(amount){
    this.selectedDollarAmount=amount;
    console.log(this.selectedDollarAmount)
  }

  toggle() {
    this.showError = !this.showError;
  }

  createHouseTicket(){

    var houseTicketDto = {
      "dollarAmount": this.selectedDollarAmount,
    }

    this._httpService.httpRequest('POST','createHouseTicket', JSON.stringify(houseTicketDto), true)
    .then(Response => {
      let responseData : any = Response;
      console.log('REPONSE DATA: ' + responseData);
      if(responseData["success"]){
        alert("Contest Ticket Successfully Created.")
        location.reload()
      }
      console.log(responseData)
    })
    .catch(data => {

    })
  }

}