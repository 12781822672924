import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder , Validators} from '@angular/forms';
import { UIRouter } from '@uirouter/angular';
import { HttpService } from '../services/http.service';
import * as $ from 'jquery';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [HttpService]
})
export class LoginComponent implements OnInit {

  loginForm : FormGroup;
  loginError : string="";
  constructor(private fb: FormBuilder, private _uiRouter: UIRouter, private _httpService: HttpService) { }

  ngOnInit() {

    this.loginForm = this.fb.group({
			email : ['', [Validators.required, Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]],
			password : ['',[Validators.required]]
		})

  }

  loginSubmit(loginData){
      console.log('loginData',loginData);
      let loginRequestData = {
                "deviceID" : null,
                "deviceToken" : null,
                "devicetype" : "3",
                "email" : loginData.email,
                "password" : loginData.password
        }
      console.log('loginData',loginRequestData)
      this._httpService.httpRequest('POST','login',loginRequestData, true)
      .then(Response => {
        console.log('login success', Response);
        let data : any = Response;
        HttpService.setUserData(data.response);
        this._uiRouter.stateService.go('contest-listing');
      })
      .catch(data => {
        console.log('login error',data);
        this.loginError = data.message;
      })
  }

  setfocustrue(){
    this.loginForm.get("email").markAsTouched({onlySelf: true});
  }

}
