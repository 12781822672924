import { Component, OnInit } from '@angular/core';
import { Http, Headers, Response } from '@angular/http';
import { StateService, UIRouter } from '@uirouter/angular';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { LiveStatsModalComponent } from '../live-stats-modal/live-stats-modal.component';

import { ContestDetailComponent } from '../contest-detail/contest-detail.component';
import * as $ from 'jquery';
import { Injectable } from '@angular/core';

// service
import { UserDetailsService } from '../services/user-details.service';
import { HttpService } from '../services/http.service';

import { SERVER_NAME, APIS } from '../config';

@Component({
  selector: 'app-live-player-stats',
  templateUrl: './live-player-stats.component.html',
  styleUrls: ['./live-player-stats.component.scss']
})


export class LivePlayerStatsComponent implements OnInit {

  contestId: any;
  currentPage: number = 1;
  itemsPerPage: number = 10;
  entrants: any;
  pagination: any;
  totalPoints: any = [];
  leaugeStatus: any;
  contestListData: any;
  collection: any[] = [];
  numberOfItem: number;
  totalPages: number;
  currentDate = new Date();
  picksData: any = [];
  pickArray: any = [];
  maximumPoint: any;
  userPropsArray: any = [];

  constructor(
    private _uiRouter: UIRouter,
    private _httpService: HttpService,
    private _userDetails: UserDetailsService,
    private _userDetailsService: UserDetailsService) {

    
    this.contestId = this._uiRouter.stateService.params.contestId;
    this.leaugeStatus = this._uiRouter.stateService.params.leaugeStatus;
    console.log('this.contestId', this.contestId);
    console.log(this.leaugeStatus);
  }



  ngOnInit() {
    this.contestListingData();
   

      //open modal using jQuery
      
  }

  contestListingData() {
    let listingData: any = {
      "leagueType": null,
      "contestType": null,
      "featured": "0",
      "currentPage": this.currentPage,
      "currentSize": this.itemsPerPage
    }

    this._httpService.httpRequest('POST', 'liveContestListing', listingData, true)
      .then(Response => {
        let data: any = Response['response'].data;
        this.numberOfItem = Response['response'].pagination.numberOfElements;
        this.totalPages = Response['response'].pagination.totalPages;
        this.collection = data;
        console.log('collection', this.collection);

      })
      .catch(data => {

      })
  }

  pageChanged($event) {
    //console.log("$event", $event);
    this.currentPage = $event;
    this.contestListingData();
  }

  contestlistClickFun(contestVal) {
    this.contestId = contestVal.contestId;
    console.log("cid: " + this.contestId);
    
  }

  viewDetailFun(contestId) {

    console.log('hi', contestId);
    this._uiRouter.stateService.go('contest-detail', { contestId: contestId });
  }

  edit(contestId) {
    console.log('contest id', contestId);
    this._uiRouter.stateService.go('edit-contest', { "contestId": contestId, "leaugeStatus": "live" });
  }

  entrantsListFun(contestId) {
    console.log('hey');




    //this._uiRouter.stateService.go('player-props',{'contestId':contestId,'leaugeStatus':'live'});

    //this._uiRouter.stateService.go('contest-detail',{contestId: contestId});
    //this._uiRouter.stateService.go('player-props',{'contestId':contestId,'leaugeStatus':'live'});
  }

  

  

}
