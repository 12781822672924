import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder , Validators} from '@angular/forms';
import { StateService, UIRouter } from '@uirouter/angular';
import { SERVER_NAME, APIS } from '../config';
import { HttpService } from '../services/http.service';
import { UserDetailsService } from '../services/user-details.service';

// modal
//import { PropsModalVersusComponent } from '../props-modal-versus/props-modal-versus.component';
declare const $:any;
@Component({
  selector: 'app-team-listing-versus',
  templateUrl: './team-listing-versus.component.html',
  styleUrls: ['./team-listing-versus.component.scss']
})
export class TeamListingVersusComponent implements OnInit {
  addPropForm: FormGroup;
  startTime: any;
  endTime: any;
  featured: any;
  leagueType: any;
  leagueDate : any;
  eventsData : any;
  playersData : any;
  teamName : any;
  golfRound : any;
  eventPropsdata : any;
  noDataMsg:string;
  adminContestId : any;
  teamid : any;
  createdPropsData : any;
  createdPropsPlayerArr = [];
  createdPropsTeamArr = [];
  createdPropsTeam2Arr = [];
  totalPlayerSelected:number;
  propsData : any;
  floatError : any;
  formatError: string;

  //versus variables
  propsVersusPostData :any =  {};
  statsPlayerAdvantage : any;
  
  player1TeamId :any;
  player1Event : any;
  player1Data : any;
  player1Points : any;

  player2TeamId :any;
  player2Event : any;
  player2Data : any;
  player2Points : any;

  overValueError :string;
  
  versusPlayers = [];
  versusEvents = [];

  
  player1Props=[];
  player2Props=[];
  maxLimitPlayer1Error : string;
  maxLimitPlayer2Error : string;

  adminContestPropsDto : any;
  createionDto: any;
  //@ViewChild('propModalVersus') propModalVersus : PropsModalVersusComponent;

  constructor(private _httpService: HttpService, private fb: FormBuilder, private _uiRouter: UIRouter,private _userDetailsService: UserDetailsService) {
    this.leagueType = this._uiRouter.stateService.params.leagueType;
    this.startTime = this._uiRouter.stateService.params.startTime;
    this.endTime = this._uiRouter.stateService.params.endTime;
    this.adminContestId = this._uiRouter.stateService.params.adminContestId;
    this.leagueDate = this.startTime+" 12:00:00";
    var arr;
   }

  ngOnInit() {
    this.addPropForm = this.fb.group({
      statsPlayerAdvantage : [''],
      player1Points : [''],
      player2Points : ['']
    })

    let requestData = {
      "adminContestId":this.adminContestId,
      "leagueType":this.leagueType,
      "currentPage":1,
      "currentSize":100,
      "startDate":this.startTime,
      "endDate":this.endTime
    }
 
    // ************************** Event Liting *************************
    this._httpService.httpRequest('POST', 'eventsListing',requestData, true)
      .then(Response => {
        let responseData :any = Response;
        this.leagueType = responseData.response.data[0].leagueType;
        this.leagueDate = this.startTime+" 12:00:00";
        this.eventsData = responseData.response.data;

      })
      .catch(data => {
        this.noDataMsg = data.message;
    })
    // ************************** Event Liting *************************
     this.fetchProps();

    // ************************** Leauge type props *************************
    
    this._httpService.httpRequest('GET', 'propParameter',"leagueType="+this.leagueType, true)
      .then(Response => {
        let propsData :any = Response;
        this.propsData = propsData.response;
      })
      .catch(data => {
        console.log('props parameters error', data);
    })
    // ************************** Leauge type props *************************

    //this.fetchCreatedProps();
    if(window.localStorage.getItem("teamid")){
      this.playersData = JSON.parse(window.localStorage.getItem("playersData"));
      this.teamName = window.localStorage.getItem("teamname");
      this.teamid = window.localStorage.getItem("teamid");
      this.eventPropsdata = JSON.parse(window.localStorage.getItem("eventPropsdata"));
  }
    
     $('#propModal').on('hide', function () {
      console.log("into props modal");
    });

    $('#propModal').on('hidden', function () {
      console.log("into props modal 2");
    });
  }


removeProp(index,playerId){
    this._httpService.httpRequest('DELETE', 'deleteProp',"propId="+index, true)
      .then(Response => {
        let responseData : any = Response;
        this._userDetailsService.showSuccess(responseData.message);
        this._uiRouter.stateService.reload();
        this.fetchProps();
      })
      .catch(data => {
        console.log('remove prop error', data);
    })
}

checkFloatValue(value){
    console.log('value',value);
    // let checkDots = (value.match(/\./g) || []).length; 
    // console.log('checkDots', checkDots);
    if($.isNumeric(value)){
      this.formatError = "";
      if(value<0.5){
        this.floatError = "Handicap advantage value should be greater than or equal to 0.5";
        return;
      }
      // else if(checkDots > 1){
      //   this.floatError = "";
      //   this.formatError = "Invalid format"
      // }
      else{
        var str = value.toString();
        if(!str.match(/^-?[0-9]*[.][5]{1}$/)) {
            this.floatError = "Handicap advantage value should be appended with (.5)";
            return;
        }else{
          this.floatError = "";
        }
      }
      return value;

    }
    else{
      if(value != ""){
        this.floatError = "";
        this.formatError = "Invalid format";  
      }
      else{
        this.floatError = "";
        this.formatError = "";  
      }
    }
  }

  getTeamPlayer(teamid,sportradarTeamId,pandascoreTeamId,abiosTeamId,eventId,teamname,eventIndex){

  this.eventPropsdata = this.eventsData[eventIndex];
  this.teamid= teamid;
  console.log("------calling getTeamPlayerNormal teamId: ",teamid," sportradarTeamId: ",sportradarTeamId," pandascoreTeamId: ",pandascoreTeamId," abiosTeamId: ",abiosTeamId," league: ",this.leagueType);

  if(this.leagueType == "NFL" || this.leagueType == "MLB" || this.leagueType == "NBA" || this.leagueType == "SOCCER" || this.leagueType == "AUDL" || this.leagueType == "CRICKET" || this.leagueType == "UFC"){
    this._httpService.httpRequest('POST', 'playerListing',{"leagueType":this.leagueType,"currentPage":1,"currentSize":100,"teamId":teamid}, true)
      .then(Response => {
          let responseData :any = Response;
        this.teamName = teamname;
        this.playersData = responseData.response.data;
        //this.propsPostData = {"contestTradational":true,"event":this.eventsData[eventIndex]};
        window.localStorage.setItem("teamid",teamid);
        window.localStorage.setItem("teamname",teamname);
        window.localStorage.setItem("playersData",JSON.stringify(this.playersData));
        window.localStorage.setItem("eventPropsdata",JSON.stringify(this.eventPropsdata));
      })
      .catch(data => {
        console.log('team list error', data);
        this.noDataMsg = "No record found";
    })
  }else if(this.leagueType == "CSGO2" || this.leagueType == "LOL" || this.leagueType == "DOTA2" || this.leagueType == "VAL"){
    this._httpService.httpRequest('POST', 'esportsPlayerListing',{"leagueType":this.leagueType,"currentPage":1,"currentSize":100,"sportsRadarTeamId":sportradarTeamId}, true)
      .then(Response => {

        let responseData :any = Response;
        this.teamName = teamname;
        this.playersData = responseData.response.data;
        //this.propsPostData = {"contestTradational":true,"event":this.eventsData[eventIndex]};
        window.localStorage.setItem("teamid",teamid);
        window.localStorage.setItem("teamname",teamname);
        window.localStorage.setItem("playersData",JSON.stringify(this.playersData));
        window.localStorage.setItem("eventPropsdata",JSON.stringify(this.eventPropsdata));
      })
      .catch(data => {
        console.log('team list error', data);
        this.noDataMsg = "No record found";
    })
  }else if(this.leagueType == "OW"){
    this._httpService.httpRequest('POST', 'esportsPlayerListing',{"leagueType":this.leagueType,"currentPage":1,"currentSize":100,"pandascoreTeamId":pandascoreTeamId}, true)
      .then(Response => {

        let responseData :any = Response;
        this.teamName = teamname;
        this.playersData = responseData.response.data;
        //this.propsPostData = {"contestTradational":true,"event":this.eventsData[eventIndex]};
        window.localStorage.setItem("teamid",teamid);
        window.localStorage.setItem("teamname",teamname);
        window.localStorage.setItem("playersData",JSON.stringify(this.playersData));
        window.localStorage.setItem("eventPropsdata",JSON.stringify(this.eventPropsdata));
      })
      .catch(data => {
        console.log('team list error', data);
        this.noDataMsg = "No record found";
    })
  }else if(this.leagueType == "GOLF"){
    this._httpService.httpRequest('POST', 'golfPlayerListing',{"leagueType":this.leagueType,"currentPage":1,"currentSize":200,"sportsRadarTeamId":sportradarTeamId,"golfRound":this.eventPropsdata.golfRound,"eventId":eventId}, true)

      .then(Response => {

        let responseData :any = Response;
        this.teamName = teamname;
        this.golfRound = "0";
        if (responseData.response.data[0] != null){
          this.golfRound = responseData.response.data[0].golfRound;
        }
        this.playersData = responseData.response.data;
        //this.propsPostData = {"contestTradational":true,"event":this.eventsData[eventIndex]};
        window.localStorage.setItem("teamid",teamid);
        window.localStorage.setItem("teamname",teamname);
        window.localStorage.setItem("playersData",JSON.stringify(this.playersData));
        window.localStorage.setItem("eventPropsdata",JSON.stringify(this.eventPropsdata));
      })
      .catch(data => {
        console.log('team list error', data);
        this.noDataMsg = "No record found";
    })
  }else if(this.leagueType == "VAL"){
    this._httpService.httpRequest('POST', 'esportsPlayerListing',{"leagueType":this.leagueType,"currentPage":1,"currentSize":100,"abiosTeamId":abiosTeamId}, true)
      .then(Response => {

        let responseData :any = Response;
        this.teamName = teamname;
        this.playersData = responseData.response.data;
        //this.propsPostData = {"contestTradational":true,"event":this.eventsData[eventIndex]};
        window.localStorage.setItem("teamid",teamid);
        window.localStorage.setItem("teamname",teamname);
        window.localStorage.setItem("playersData",JSON.stringify(this.playersData));
        window.localStorage.setItem("eventPropsdata",JSON.stringify(this.eventPropsdata));
      })
      .catch(data => {
        console.log('team list error', data);
        this.noDataMsg = "No record found";
    })
  }

    
}


publishContest(){
  // ************************** Contest Liting *************************
    this._httpService.httpRequest('GET', 'publishContest',"contestid="+this.adminContestId, true)
      .then(Response => {
        let responseData :any = Response;
        if(responseData.response){
            this._userDetailsService.showSuccess(responseData.message);
            this._uiRouter.stateService.go('contest-listing');
        }else{
          this._userDetailsService.showError(responseData.message);
        }
      })
      .catch(data => {
        this._userDetailsService.showError(data.message);
    })
    // ************************** Contest Liting *************************
}

selectPlayerForVersus(player,event,teamid){
  
  //this.player1TeamId = teamid;
  //this.player1Event = event;
  //this.player1Data = player;
  console.log(event);
  if(this.versusPlayers.length>1){
    alert("You can select only 2 player for versus prop.");
  }else{
    this.versusEvents.push(event);
    this.versusPlayers.push(player);
  }
  //console.log(this.versusPlayers);
}

getPlayer2Points(e,val){

  var charCode = e.keyCode;
  if (charCode===190) {
      console.log(charCode);
      e.preventDefault();
  }else{
    var str = val.toString();
    
      if(val>0 && val<200){
         if(str.match(/^-?[0-9]+$/)) {
            if(val % 1 === 0){
              this.player2Points = 200-val;
            }else{
              this.player2Points = (200-val).toFixed(2);
            }
            this.overValueError = "";
          }else{
              this.player2Points = 0;
              this.overValueError = "Player points should not be a float value.";
          }
        }else{
          this.player2Points = 0;
          this.overValueError = "Player points must be greater than 0 and less than 200.";
        }
    
  }
}

removePlayerForVersus(index){
  console.log("coming"+index);
    if(index==0){
      this.player1Props = this.player2Props;
      this.player2Props = [];
    }else if(index==1){
      this.player2Props = [];
    }

    this.versusEvents.splice(index,1);
    this.versusPlayers.splice(index,1);
    //console.log(this.versusPlayers);
}

createPropsForVersus(frmValue){
    console.log(this.versusPlayers);
    console.log(frmValue);
    console.log(this.player1Props);
    this.propsVersusPostData.contestVersus = true;
    this.propsVersusPostData.adminContestId = this.adminContestId;

    this.propsVersusPostData.player1Dato = this.versusPlayers[0];
    this.propsVersusPostData.player1Dato['statsPlayerAdvantage'] = parseFloat(frmValue.statsPlayerAdvantage);
    this.propsVersusPostData.player1Dato['statsplayerPoints'] = parseInt(frmValue.player1Points);
    
    this.propsVersusPostData.player1Dato['adminPropParameters']=this.player1Props;
    this.propsVersusPostData.player1Dato['event']=this.versusEvents[0];

    this.propsVersusPostData.player2Dto = this.versusPlayers[1];
    this.propsVersusPostData.player2Dto.statsPlayerAdvantage = 0;
    this.propsVersusPostData.player2Dto['statsplayerPoints'] = frmValue.player2Points;    
    this.propsVersusPostData.player2Dto['adminPropParameters']=this.player2Props;
    this.propsVersusPostData.player2Dto['event']=this.versusEvents[1];
    console.log(this.propsVersusPostData);


    this._httpService.httpRequest('POST', 'saveVersusProp',this.propsVersusPostData, true)
      .then(Response => {
        console.log('create constest success', Response);
        let responseData :any = Response;
        if(responseData.success){
            this._userDetailsService.showSuccess(responseData.message);
            // delete this.propsPostData;
            delete this.player1Props;
            delete this.player2Props;
            //delete this.eventPropsdata;
            //this.propModal.hide();
            this._uiRouter.stateService.reload();
        }
    })
      .catch(data => {
        console.log('create contest error', data);
        this._userDetailsService.showError(data.message);
      })

}

propCheckFun(props, index,e){
    
    
    if(index===0){
        //this.maxLimitPlayer1Error = "";
        if(e.target.checked){
          if(this.player1Props.length >= 3){
            (e.target || e.srcElement).checked = false;
            //this.maxLimitPlayer1Error = "You can select maximum of 3 props.";
            this._userDetailsService.showError("You can select maximum of 3 props.");
          }else{
            this.player1Props.push({
              "propParamId": props.propParamId    
            });
          }
        }else{
            let indexof = this.player1Props.findIndex(function(item, i){
              return item.propParamId == props.propParamId
            });
          this.player1Props.splice(indexof,1);
        }
        console.log(JSON.stringify(this.player1Props));      
    }else if(index===1){
        //this.maxLimitPlayer2Error = "";
        if(e.target.checked){
          if(this.player2Props.length >= 3){
            (e.target || e.srcElement).checked = false;
            //this.maxLimitPlayer2Error = "You can select maximum of 3 props.";
            this._userDetailsService.showError("You can select maximum of 3 props.");
          }else{
            this.player2Props.push({
              "propParamId": props.propParamId    
            });
          }
        }else{
            let indexof = this.player2Props.findIndex(function(item, i){
              return item.propParamId == props.propParamId
            });
          this.player2Props.splice(indexof,1);
        }
        console.log(JSON.stringify(this.player2Props));
        }
  }

  fetchProps(){
  // ************************** Contest Liting *************************
    this._httpService.httpRequest('POST', 'getEditContest',{"contestId":this.adminContestId}, true)
      .then(Response => {
        let responseData :any = Response;
        this.adminContestPropsDto = responseData.response.adminContestPropsDto;
        this.createionDto = responseData.response.createionDto;
        console.log(responseData);
        for(let data of this.adminContestPropsDto) {
          this.createdPropsTeamArr.push(data.player1TeamId);
          if(data.player2TeamId){
            this.createdPropsTeam2Arr.push(data.player2TeamId);
          }
        }
      })
      .catch(data => {
        console.log('Event list error', data);
    })
    // ************************** Contest Liting *************************
} 


slideToggle(){
  //$(".slide").click(function(){
    console.log("coming");
    $(".slide-body").slideToggle();
  //});
}

}
