import { Component, OnInit } from '@angular/core';
import { Http, Headers, Response } from '@angular/http';
import { StateService, UIRouter } from '@uirouter/angular';

import { ContestDetailComponent } from '../contest-detail/contest-detail.component';


// data table


// service
import { UserDetailsService } from '../services/user-details.service';
import { HttpService } from '../services/http.service';

import {SERVER_NAME, APIS} from '../config';


@Component({
  selector: 'app-contest-listing-live',
  templateUrl: './contest-listing-live.component.html',
  styleUrls: ['./contest-listing-live.component.scss'],
  providers: [UserDetailsService, HttpService]
})

export class ContestListingLiveComponent implements OnInit {
  contestListData: any;
  currentPage: number = 1;
  collection: any[] = [];  
  numberOfItem: number;
  totalPages: number;
  itemsPerPage: number= 10;
  contestId: any;
  currentDate = new Date();

  constructor(
    private _http: Http, 
    private _userDetails: UserDetailsService,
    private _httpService: HttpService,
    private _uiRouter: UIRouter
    ) 
  { 
      
  }

  ngOnInit() {
    this.contestListingData();
  }

  contestListingData(){
    let listingData: any = {
            "leagueType":null,
            "contestType":null,
            "featured":"0",
            "currentPage" :this.currentPage,
            "currentSize": this.itemsPerPage
    }

    this._httpService.httpRequest('POST','liveContestListing',listingData, true)
    .then(Response => {
      let data: any = Response['response'].data;
      this.numberOfItem = Response['response'].pagination.numberOfElements;
      this.totalPages = Response['response'].pagination.totalPages;
      this.collection = data;
      console.log('collection',this.collection);

    })
    .catch(data => {

    })
  }

  pageChanged($event){
    console.log("$event", $event);
    this.currentPage = $event;
    this.contestListingData();
  }

  contestlistClickFun(contestVal){
    this.contestId = contestVal.contestId;
  }

  viewDetailFun(contestId){

    console.log('hi',contestId);
    this._uiRouter.stateService.go('contest-detail',{contestId: contestId});
  }

  edit(contestId){
    console.log('contest id', contestId);
    this._uiRouter.stateService.go('edit-contest',{"contestId": contestId,"leaugeStatus":"live"});
  }

  entrantsListFun(contestId){
    this._uiRouter.stateService.go('list-of-entrants',{'contestId':contestId,'leaugeStatus':'live'});
  }

}
