import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder , Validators} from '@angular/forms';
import { UIRouter } from '@uirouter/angular';
import { HttpService } from '../services/http.service';
import { UserDetailsService } from '../services/user-details.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  resetPasswordForm : FormGroup;
  pwdMatchErr: boolean = false;
  pwdSpaceErr: boolean = false;
  constructor(private fb: FormBuilder, 
              private _uiRouter: UIRouter, 
              private _httpService: HttpService,
              private _userDetailService: UserDetailsService) { }

  ngOnInit() {
    this.resetPasswordForm = this.fb.group({
			currentPassword : ['',[Validators.required]],
      newPassword : ['',[Validators.required,Validators.minLength(6),Validators.maxLength(100),Validators.pattern(new RegExp(/^\S*$/))]],
      confirmPassword : ['',[Validators.required]]
		})
  }

  checkSpace(val,field){

  }

  resetPasswordSubmit(val){
    this.pwdMatchErr = false;
    //console.log('val',val);
    let pattern = new RegExp(" ");
    let newPwdSpaceCheck = pattern.test(val.newPassword);
    let confirmPwdSpaceCheck = pattern.test(val.confirmPassword);
    //console.log("newPwdSpaceCheck",newPwdSpaceCheck, "confirmPwdSpaceCheck", confirmPwdSpaceCheck);
    let requestData = {
            "newPassword": val.newPassword,
            "oldPassword": val.currentPassword,
            "isAdmin":1
    }
    if(newPwdSpaceCheck || confirmPwdSpaceCheck){
      this.pwdSpaceErr = true;
    }
    else if((val.newPassword != val.confirmPassword)){
      this.pwdSpaceErr = false;
      this.pwdMatchErr = true;
    }else{
      this.pwdSpaceErr = false;
      this.pwdMatchErr = false;
      this._httpService.httpRequest("POST","changePassword",requestData,true)
      .then(Response => {
        let response: any = Response;
        console.log('response',response);
        this._userDetailService.showSuccess(response.message);
      })
      .catch(data => {
        console.log('data',data);
        this._userDetailService.showError(data.message);
      })
    }
    
  }

}





