import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { SERVER_NAME, APIS, DateShowFormat, DateFormat } from '../config';
// import { HttpService } from '../services/http.service';
import { UIRouter } from '@uirouter/angular';
import { DatePipe } from '@angular/common';
import { Moment } from 'moment';
import 'eonasdan-bootstrap-datetimepicker';
import { UserDetailsService } from '../services/user-details.service';
import { NKDatetimeModule } from "ng2-datetime/ng2-datetime";
import {Observable} from "rxjs";
// CommonJS style - working with "require"
declare const $: any;
//declare const moment:any;

import { HttpService } from '../services/http.service';
import {Http} from "@angular/http";

@Component({
  selector: 'app-create-prop',
  templateUrl: './deactivate-props-with-csv.component.html',
  styleUrls: ['./deactivate-props-with-csv.component.scss'],
  providers: [HttpService, DatePipe]
})
export class DeactivatePropsWithCsvComponent implements OnInit {
  // fileToUpload: File | null = null;
  fileData : any = {};
  contentForm: FormGroup;
  csvFileSelected : boolean = false;

  unpublishedHouseProps = [];

  constructor(
      private _http: Http,
      private _userDetailsService: UserDetailsService,
      private _httpService: HttpService,
      private _uiRouter: UIRouter,
      private fb: FormBuilder,
  ) {
  }

  onUploadCsv($event) {
    let files = $event.target.files || $event.srcElement.files;
    let file = files[0];
    //console.log(file);
    // let formData : FormData = new FormData();
    // formData.append("file",file);
    this.fileData = file;
    this.csvFileSelected = true;
  }

  contentFormSubmit(formValue){
    const formData = new FormData();
    formData.append("props_csv", this.fileData, "props_csv.csv");
    this._httpService.httpRequestLambdaCombo("POST", "csv-houseprops/deactivatePropsWithCsv", formData, true, false, {})
        .then(Response => {
          let responseData : any = Response;
          this._uiRouter.stateService.reload();
          this._userDetailsService.showSuccess(responseData.message);
        })
        .catch(data => {
          this._userDetailsService.showError(data.message);
        })
  }

  fetchUnpublishedHouseProps() {
    this.unpublishedHouseProps = []
      console.log("11111");
      this._httpService.httpRequest('GET', 'fetchUnpublishedHouseProps', true)
        .then(Response => {
          let responseData: any = Response;
            console.log(responseData);
            for (let unpublishedHouseProp of responseData.response) {
            this.unpublishedHouseProps.push(unpublishedHouseProp)

              console.log("NEXT");
              console.log(this.unpublishedHouseProps);
          }
        })
        .catch(data => {
          this._userDetailsService.showError(data.message);
        })
  }

  publishAllHouseProps(){
    this._httpService.httpRequest('POST', 'publishUnpublishedHouseProps', true)
        .then(Response => {
          let responseData :any = Response;
          if(responseData.response){
            this._userDetailsService.showSuccess(responseData.message);
            this._uiRouter.stateService.reload();
          }else{
            this._userDetailsService.showError(responseData.message);
          }
        })
        .catch(data => {
          this._userDetailsService.showError(data.message);
        })
  }

  deleteHouseProp(propId){
    this._httpService.httpRequest('DELETE', 'deleteHouseProp',"propId="+propId, true)
        .then(Response => {
          let responseData :any = Response;
          this._userDetailsService.showSuccess(responseData.message);
          this._uiRouter.stateService.reload();
        })
        .catch(data => {
          this._userDetailsService.showError(data.message);
        })
  }

  ngOnInit() {
      this.fetchUnpublishedHouseProps();

    this.contentForm = this.fb.group({
      csvUpload: ['', [Validators.required]]
    })
  }

}