import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-list-of-contest-modal',
  templateUrl: './list-of-contest-modal.component.html',
  styleUrls: ['./list-of-contest-modal.component.scss']
})
export class ListOfContestModalComponent implements OnInit {
  playersdetail: any;
  @ViewChild('listOfContestModal') listOfContestModal: ModalDirective;
  constructor() { }

  ngOnInit() {
  }

  show(list){
      console.log('modal list data', list);
      this.listOfContestModal.show();
      this.playersdetail = list.userPropPicks;
      // this.playerDetail = pick;
  }

  closeModal(){
    this.listOfContestModal.hide();
  }

}
