import { Component, OnInit } from '@angular/core';
import { StateService, UIRouter } from '@uirouter/angular';
import { Http, Headers, Response } from '@angular/http';
import { FormGroup, FormBuilder , Validators} from '@angular/forms';
import { Moment } from 'moment' ;

import { UserDetailsService } from '../services/user-details.service';
import { HttpService } from '../services/http.service';

@Component({
  selector: 'house-tickets',
  templateUrl: './house-tickets.component.html',
  styleUrls: ['./house-tickets.component.css']
})
export class HouseTicketsComponent implements OnInit {
  unusedUserHouseTickets: any[] = [];
  constructor(
  	private _http: Http, 
    private _userDetails: UserDetailsService,
    private _httpService: HttpService,
    private fb: FormBuilder,
  	private _uiRouter: UIRouter
  ) {}

  isUserHouseTicketDisplay = true
  houseTickets=[]

  ngOnInit() {
    this.getUnusedUserHouseTickets(1)
    this.getHouseTickets()
  }

  wasUsed(boolVal){
    if(boolVal===true){
      return "Y"
    } else {
      return "N"
    }
  }

  disableUserHouseTicket(userHouseTicketId){
    userHouseTicketId = String(userHouseTicketId)
    this._httpService.httpRequest('GET','disableUserHouseTicket', "userHouseTicketId=" + userHouseTicketId, true)
    .then(Response => {
      let responseData : any = Response;
      location.reload()
      alert(responseData.message)
      console.log(this.unusedUserHouseTickets[0]["username"])

    })
  }

  toggleListDisplay() {
    if(this.isUserHouseTicketDisplay) {
      var hideList = document.getElementById("userHouseTickets");
      var showList = document.getElementById("houseTickets");
      this.isUserHouseTicketDisplay = false;
    } else {
      var hideList = document.getElementById("houseTickets");
      var showList = document.getElementById("userHouseTickets");
      this.isUserHouseTicketDisplay = true;
    }

    hideList.style.display = "none"
    showList.style.display = "block"

  }

  getUnusedUserHouseTickets(page){
    this._httpService.httpRequest('GET','getUserHouseTickets', {"page": page }, true)
    .then(Response => {
      let responseData : any = Response;
      this.unusedUserHouseTickets = responseData.response.response;
      console.log(this.unusedUserHouseTickets[0]["username"])
    })

    this.unusedUserHouseTickets.forEach(e=>{
      e.expirationDate = new Date(e.expirationDate);
    })
    console.log("unusedTickets: " + this.unusedUserHouseTickets )
  }

  getHouseTickets(){
    this._httpService.httpRequest('GET','getAllHouseTickets', "", true)
    .then(Response => {
      let responseData : any = Response;
      this.houseTickets = responseData.response;
    })
  }
}