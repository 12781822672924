import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder , Validators} from '@angular/forms';

import { SERVER_NAME, APIS } from '../config';
import { HttpService } from '../services/http.service';
import { UserDetailsService } from '../services/user-details.service';
import { UIRouter } from '@uirouter/angular';
import { DatePipe } from '@angular/common';


import { FileUploader } from 'ng2-file-upload';
// CommonJS style - working with "require"
declare const $:any;


@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss'],
  providers: [HttpService,DatePipe]
})
export class ContentComponent implements OnInit {
  contentForm: FormGroup;
  privacyForm: FormGroup;
  howtoplayForm : FormGroup;
  iceDescForm: FormGroup;
  termsError : any;
  privacyError : any;
  contentError : any;
  getDescription : any;
  fileData : any = {};
  hasBaseDropZoneOver:any;
  contentData : any;
  privacyFileSelected : boolean = false;
  termsFileSelected : boolean = false;
  howtoplayFileSelected : boolean = false;
  public uploader:FileUploader = new FileUploader({isHTML5:true});
  constructor(private _httpService: HttpService, 
              private fb: FormBuilder, 
              private _uiRouter : UIRouter,
              private _userDetailsService : UserDetailsService) { 
    
  }


  ngOnInit() {
      this.contentForm = this.fb.group({
        pdfupload: ['', [Validators.required]]
      })

      this.privacyForm = this.fb.group({
        pdfupload: ['', [Validators.required]]
      })

      this.howtoplayForm = this.fb.group({
        pdfupload: ['', [Validators.required]]
      })

      this.iceDescForm = this.fb.group({
        description: ['',[Validators.required]]
      })

      this.getIceDescription();
      this.getContentData();
  }


  getContentData(){
      this._httpService.httpRequest('GET', 'getContent','', true,true)
        .then(Response => {
          let responseData : any = Response;
          this.contentData = responseData.response;
          //this._userDetailsService.showSuccess(responseData.message);
        })
        .catch(data => {
          this._userDetailsService.showError(data.message);
        })
  }


  onChangeTerms($event) {
    let files = $event.target.files || $event.srcElement.files;
    let file = files[0];
    //console.log(file);
    let formData : FormData = new FormData();
    formData.append("file",file);
    this.fileData = formData;
    this.termsFileSelected = true;
  }

  onChangePrivacy($event) {
    let files = $event.target.files || $event.srcElement.files;
    let file = files[0];
    //console.log(file);
    let formData : FormData = new FormData();
    formData.append("file",file);
    this.fileData = formData;
    this.privacyFileSelected = true;    
  }

  onChangeHowToPlay($event) {
    let files = $event.target.files || $event.srcElement.files;
    let file = files[0];
    //console.log(file);
    let formData : FormData = new FormData();
    formData.append("file",file);
    this.fileData = formData;
    this.howtoplayFileSelected = true;    
  }

  

  howtoplayFormSubmit(formValue){
    this._httpService.httpRequest('POST', 'howtoplayUpload',this.fileData, true,false)
      .then(Response => {
        let responseData : any = Response;
        this._uiRouter.stateService.reload();
        this._userDetailsService.showSuccess(responseData.message);
      })
      .catch(data => {
        this._userDetailsService.showError(data.message);
      })
  }
  
  privacyFormSubmit(formValue){
    
    this._httpService.httpRequest('POST', 'privacyUpload',this.fileData, true,false)
      .then(Response => {
        let responseData : any = Response;
        this._uiRouter.stateService.reload();
        this._userDetailsService.showSuccess(responseData.message);
      })
      .catch(data => {
        this._userDetailsService.showError(data.message);
      })
  }
  // content form submit
  contentFormSubmit(formValue){
    
    this._httpService.httpRequest('POST', 'saveContent',this.fileData, true,false)
      .then(Response => {
        let responseData : any = Response;
        this._uiRouter.stateService.reload();
        this._userDetailsService.showSuccess(responseData.message);
      })
      .catch(data => {
        this._userDetailsService.showError(data.message);
      })
  }

  // get ice prop description on load
  getIceDescription(){
    this._httpService.httpRequest("GET",'getIcePropDescription','',true)
    .then(Response => {
      let responseData: any = Response;
      this.getDescription = responseData.response.description;
    })
    .catch(data => {
      this._userDetailsService.showError(data.message);
    })
  }

  // on submit ice description message
  iceDescSubmit(desc){
    let requestData = {
      "description": desc.description
    }
    this._httpService.httpRequest('POST','icePropDescription',requestData,true)
    .then(Response => {
      let responseData : any = Response;
      this._userDetailsService.showSuccess(responseData.message);
    })
    .catch(data => {
      this._userDetailsService.showError(data.message);
    })
  }

}
