import { Component, OnInit } from '@angular/core';
// import { FormGroup, FormBuilder , Validators} from '@angular/forms';
import { UIRouter } from '@uirouter/angular';
import { HttpService } from '../services/http.service';
import { DatePipe } from '@angular/common';
import { UserDetailsService } from '../services/user-details.service';

import { TeamListingComponent } from '../team-listing/team-listing.component';

@Component({
  selector: 'app-price-payout',
  templateUrl: './price-payout.component.html',
  styleUrls: ['./price-payout.component.scss'],
  providers: [DatePipe]
})
export class PricePayoutComponent implements OnInit {
//   pricePayloadForm: FormGroup;
  firstPlace: number = 0;
  pricePayoutArr = [{
      position: 1,
      amount: 0,
      numEntrants: 1,
      error: ''
  }];
  pricePayoutFinalArr = [];
  leagueType: any;
  startTime: any;
  endTime: any;
  adminContestId: any;
  guaranteedPrice: any;
  contestType: any;
  totalAmount: number = 0;
  amountTotalVal:number = 0;
  numEntrantsTotalVal: number = 1;
  guaranteedPriceExceeded: boolean = false;
  amountZeroErr: boolean = false;
  placeZeroErr: boolean = false;
  amountVal: number;
  inputEntrants: number;
  maxEntriesAllowed: number;
  maxEntryExceed:boolean = false;
  alertMsg:boolean = false;
  similarContestId : any;

  constructor(private _httpService: HttpService, private _uiRouter: UIRouter, private datePipe: DatePipe, private _userDetailsService: UserDetailsService) {
        this.leagueType = this._uiRouter.stateService.params.leagueType;
        this.startTime = this._uiRouter.stateService.params.startTime;
        this.endTime = this._uiRouter.stateService.params.endTime;
        this.adminContestId = this._uiRouter.stateService.params.adminContestId;
        this.guaranteedPrice = this._uiRouter.stateService.params.guaranteedPrizeMoney;
        this.contestType = this._uiRouter.stateService.params.type;
        this.maxEntriesAllowed = this._uiRouter.stateService.params.maxEntriesAllowed;
        this.similarContestId = this._uiRouter.stateService.params.similarContestId;
        console.log('similarContestId',this.similarContestId);
        console.log('all data',this.leagueType + ' '+ this.startTime + ' ' + this.adminContestId + ' ' + this.guaranteedPrice + ' '+ this.contestType);
   }



  ngOnInit() {
      this._httpService.httpRequest("POST","isContestPayoutExist",{"adminContestId":this.adminContestId},true)
      .then(Response => {
          let response: any = Response;
          console.log('in success');
          if(response.response === true){
            this._userDetailsService.showError(response.message);
           
            if(this.contestType==="Traditional" || this.contestType==="TRADITIONAL"){
                this._uiRouter.stateService.go('team-listing',{'startTime' : this.datePipe.transform(this.startTime,"yyyy-MM-dd"),
                'endTime':this.endTime,
                'leagueType': this.leagueType,
                'adminContestId':this.adminContestId});
            }else{
                this._uiRouter.stateService.go('team-listing-versus',{'startTime' : this.datePipe.transform(this.startTime,"yyyy-MM-dd"),
                'endTime':this.endTime,
                'leagueType': this.leagueType,
                'adminContestId':this.adminContestId});
            }
          }
          
      })
      .catch(data => {
          console.log('not exist');
      })
  }

  alertClose(e){
    $(e.currentTarget).parents('.alert-msg').slideUp();
    this.alertMsg = false;
  }
  

  addSnippet(){
    let snippetObj = {
        position: 1,
        amount: 0,
        numEntrants: 1,
        error: ''
    }

    this.guaranteedPriceExceeded = false;
    if(this.amountTotalVal > this.guaranteedPrice){
        this.guaranteedPriceExceeded = true;
    }else{
        this.guaranteedPriceExceeded = false;
        this.pricePayoutArr.push(snippetObj);
    }
    this.placeUpdateFun();
  }

  

  pricePayloadSubmit(){
    this.pricePayoutFinalArr = [];
    this.amountZeroErr = false;
    this.placeZeroErr = false;
    let firstPlace: any = {
        position: 1,
        amount: this.firstPlace,
        numEntrants: 1,
        error: ''
    }
    this.maxEntryExceed = false;
    this.pricePayoutFinalArr.unshift(firstPlace);


    for(let i in this.pricePayoutArr){
        this.pricePayoutFinalArr.push(this.pricePayoutArr[i]);
    }

    for (let index in this.pricePayoutFinalArr){
        this.pricePayoutFinalArr[index].position = parseInt(index) + 1;
    };

    let nextVal:number;
    
    for(let i=0;i<this.pricePayoutFinalArr.length-1;i++){
        this.pricePayoutFinalArr[i+1].error = '';
        // console.log('loop enter');
        if(this.pricePayoutFinalArr[i+1].amount > this.pricePayoutFinalArr[i].amount){
            this.alertMsg = true;
            // console.log('loop condition check');
            this.pricePayoutFinalArr[i+1].error = 'Next amount cannot be greater than previous amount';
            // console.log('this.pricePayoutFinalArr error',this.pricePayoutFinalArr);
            return false;
        }
    }
    

    let requestData =  {
            "adminContestId": this.adminContestId,
            "similarContestId":this.similarContestId,
            "payout": this.pricePayoutFinalArr
    }
    console.log('requestData', requestData);
    this.guaranteedPriceExceeded = false;
    let amountEachVal = 0;
    let placeEachVal = 0;
     $('.amount').each(function(){
        amountEachVal = $(this).val();
        // console.log('amountEachVal',amountEachVal);
    })
   

    for(let i in requestData.payout){
        if(requestData.payout.hasOwnProperty(i)){
            if(requestData.payout[i]['numEntrants']===null || requestData.payout[i]['numEntrants']===undefined || requestData.payout[i]['numEntrants']==="" || requestData.payout[i]['numEntrants']===0 ){
                placeEachVal=0;
                break;
            }else{
                placeEachVal=requestData.payout[i]['numEntrants']
            }
        }
       
    }
    // console.log('place each value',placeEachVal);
    // console.log('this.pricePayoutArr.length',this.pricePayoutArr.length);
    if(this.amountTotalVal != this.guaranteedPrice){
        this.guaranteedPriceExceeded = true;
    }
    else if(amountEachVal == 0){
        // console.log('amountZeroErr', this.amountZeroErr);
        this.amountZeroErr = true;
    }
    else if(((placeEachVal == 0)) && (this.pricePayoutArr.length != 0)){
        this.placeZeroErr = true;
    }
    else if(this.numEntrantsTotalVal > this.maxEntriesAllowed){
        this.maxEntryExceed = true;
    }
    else{
        this.guaranteedPriceExceeded = false;

        if(this.similarContestId && this.similarContestId!=''){
            this._httpService.httpRequest('POST','similarPublish',requestData).then(Response => {
                let response: any = Response;
                if (response.success){
                    this._userDetailsService.showSuccess(response.message);
                    this._uiRouter.stateService.go('contest-listing');    
                }else{
                    this._userDetailsService.showError(response.message);
                }
            })
            .catch(data => {
                console.log('error data', data);
            })
        }else{
            this._httpService.httpRequest('POST','contestPayout',requestData).then(Response => {
                console.log('success Response', Response);
                if(this.contestType==="Traditional" ||  this.contestType==="TRADITIONAL"){
                    this._uiRouter.stateService.go('team-listing',{'startTime' : this.datePipe.transform(this.startTime,"yyyy-MM-dd"),'endTime':this.endTime,'leagueType': this.leagueType,'adminContestId':this.adminContestId});
                }else{
                    this._uiRouter.stateService.go('team-listing-versus',{'startTime' : this.datePipe.transform(this.startTime,"yyyy-MM-dd"),'endTime':this.endTime,'leagueType': this.leagueType,'adminContestId':this.adminContestId});
                }
            })
            .catch(data => {
                console.log('error data', data);
            })   
        }
        
    }
  }


  amountUpdateFun(event?,amountVal?){
    this.guaranteedPriceExceeded = false;
    this.amountVal = amountVal;
    console.log('amountVal',amountVal);
    // console.log('hello',this.firstPlace);
    // this.amountTotalVal = this.firstPlace;
    
    if((this.amountVal > 9999999) && (event.keyCode != 8)){
        console.log("value greater");
        event.preventDefault();
    }
    // console.log('event',val.length);
    this.amountTotalVal = 0;
    for(let i in this.pricePayoutArr){
        this.amountTotalVal += (this.pricePayoutArr[i].amount * this.pricePayoutArr[i].numEntrants);
    }
    this.amountTotalVal = this.amountTotalVal + this.firstPlace;
    // console.log('amountTotalVal',this.amountTotalVal);
  }

  placeUpdateFun(event?,inputEntrants?){
    this.guaranteedPriceExceeded = false;
    this.inputEntrants = inputEntrants;
    this.amountUpdateFun(event,this.amountVal);
    console.log('event',event,inputEntrants);
    
    // console.log('placeUpdate');
    this.numEntrantsTotalVal = 1;
    // console.log(typeof this.numEntrantsTotalVal);
    for(let i in this.pricePayoutArr){
        console.log('entrants',this.pricePayoutArr[i].numEntrants);
        this.numEntrantsTotalVal += this.pricePayoutArr[i].numEntrants;
    }
    if((this.inputEntrants > 999) && (event.keyCode != 8)){
        console.log('place input');
        event.preventDefault();
    }
    // if(event.keyCode == 48){
    //     console.log('zero pressed');
    //     return false;
    // }
  }

  deleteRow(i){
    // console.log('i',i);
    console.log('row deleted');
    this.placeZeroErr = false;
    this.amountZeroErr = false;
    let delRowIndex = i;
    this.pricePayoutArr.splice(delRowIndex,1);
    this.placeUpdateFun();
    this.amountUpdateFun();
    // console.log('this.pricePayoutArr',this.pricePayoutArr);
  }

  remainingAmountFun(){
      let remainingPrice = this.guaranteedPrice - this.amountTotalVal;
      if(remainingPrice < 0){
          remainingPrice = 0;
      }
      return remainingPrice;
  }

}
