import { Component, OnInit,ViewChild } from '@angular/core';
import { HttpService } from '../services/http.service';
import { FormGroup, FormBuilder , Validators} from '@angular/forms';
import { UIRouter } from '@uirouter/angular';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ListOfContestsComponent } from '../list-of-contests/list-of-contests.component';
import { FanDetailComponent } from '../fan-detail/fan-detail.component';
import { UserDetailsService } from '../services/user-details.service';

@Component({
  selector: 'app-registered-fans',
  templateUrl: './registered-fans.component.html',
  styleUrls: ['./registered-fans.component.scss']
})
export class RegisteredFansComponent implements OnInit {
  filterForm:FormGroup;
  responsePayout: any;
  registeredUserDetail: any;
  fanDetail: any;
  pagination: any;
  showFanDetail: boolean = false;
  currentPage: number = 1;
  pageSize: number = 10;
  userName: any = "";
  sortByNoOfContestsParicipated: boolean = false;
  sortByWalletBalance: boolean = false;
  sortByTotalWonPrizeMoney: boolean = false;
  isDescreasingOrder: boolean = false;
  noData: boolean = false;
  action : any;
  fid : any;
  currentPromos: any;
  @ViewChild('walletBalanceModal') walletBalanceModal: ModalDirective;
  @ViewChild('promoCodesModal') promoCodesModal: ModalDirective;


  constructor(private httpService: HttpService, 
              private fb: FormBuilder,
              private _uiRouter: UIRouter,
              private _userDetailsService : UserDetailsService
              ) {}

  ngOnInit() {
    this.registeredFanList();
    this.filterForm = this.fb.group({
      sortBy: ['noOfContestParticipated',[]],
      order: ['increasing',[]]
    })
  }

  registeredFanList(){
    $('loaders-css').show();
    this.noData = false;
    let requestPayload: any = {
            "pageNo":this.currentPage,
            "pageSize":this.pageSize,
            "userName" : this.userName,
            "sortByNoOfContestsParicipated": this.sortByNoOfContestsParicipated,
            "sortByWalletBalance": this.sortByWalletBalance,
            "sortByTotalWonPrizeMoney": this.sortByTotalWonPrizeMoney,
            "descreasingOrders" : this.isDescreasingOrder
          }
          
    this.httpService.httpRequest('POST','getRegisteredUsers',requestPayload,false)
    .then(Response => {
      this.noData = false;
      let response: any = Response;
      this.pagination = response.response.pagination;
      this.registeredUserDetail = response.response.data;
      console.log('this.registeredUserDetail',this.registeredUserDetail);
      if(this.registeredUserDetail == ""){
        console.log('no records found');
        this.noData = true;
      }
      console.log('success data', this.registeredUserDetail);
      $('loaders-css').hide();
    })
    .catch(data => {
      console.log('error', data);
      $('loaders-css').hide();
    })
  }

  viewUserPromoCodes(userId){
    let requestObj = {
        "userId" : userId
      }
    this.httpService.httpRequest('POST','getUserPromoCodes',userId,false)
    .then(Response => {
      let response: any = Response;
      console.log('response',response);
      this.currentPromos = response.response['userPromos']
      if(this.currentPromos == ""){
        this.noData = true
        console.log('no promo codes found')
      }
      console.log('sucess data', this.registeredUserDetail);
      console.log(this.currentPromos);
    })
    .catch(data => {
      console.log('error========',data);
    })
    this.promoCodesModal.show();
  }

  hideUserPromoCodes(){
    this.promoCodesModal.hide();
    this.currentPromos = null;
  }

  pageChanged($event, val){
    console.log('form val', val);
    this.currentPage = $event;
   this.registeredFanList(); 
  }

  searchFanFun(searchChar){
    console.log('char ',searchChar);
    searchChar = $.trim(searchChar);
    this.userName = searchChar;
    this.registeredFanList();
  }

  contestFilterFun(value){
    console.log("value ===> " + value.sortBy);
    if(value.sortBy == "noOfContestParticipated"){
      this.sortByNoOfContestsParicipated = true;
      this.sortByTotalWonPrizeMoney = false;
      this.sortByWalletBalance = false;
      if(value.order == "decreasing"){
        this.isDescreasingOrder = true;
        this.registeredFanList();
        console.log('decreasing');
      }else{
        this.isDescreasingOrder = false;
        this.registeredFanList();
        console.log('increasing');
      }
    }else if (value.sortBy == "totalPrizeMoney"){
      this.sortByTotalWonPrizeMoney = true;
      this.sortByNoOfContestsParicipated = false;
      this.sortByWalletBalance = false;
      this.registeredFanList();
      if(value.order == "decreasing"){
        this.isDescreasingOrder = true;
        this.registeredFanList();
        console.log('decreasing');
      }else{
        this.isDescreasingOrder = false;
        this.registeredFanList();
        console.log('increasing');
      }
    }else /*value.sortBy == "walletBalance"*/{
      this.sortByTotalWonPrizeMoney = false;
      this.sortByNoOfContestsParicipated = false;
      this.sortByWalletBalance = true;
      this.registeredFanList();
      if(value.order == "decreasing"){
        this.isDescreasingOrder = true;
        this.registeredFanList();
        console.log('decreasing');
      }else{
        this.isDescreasingOrder = false;
        this.registeredFanList();
        console.log('increasing');
      }
    }

    
    console.log('constestFilter',value);
  }

  viewContestsFun(userId){
    this._uiRouter.stateService.go('list-of-contests',{"userId":userId});
  }

  viewHouseUserContests(userId){
    this._uiRouter.stateService.go('house-user-contest-history',{"userId":userId});
  }

  deleteFanFun(userId){
    let confirmDelete = confirm('Are you sure you want to delete this fan');
    if(confirmDelete == true){
      console.log('userId',userId);
      let requestObj = {
        "userId" : userId
      }
      this.httpService.httpRequest('POST','deleteRegisterdFan',requestObj,true)
      .then(Response => {
        let response: any = Response;
        console.log('response',response);
        //update 2-6-18 hide user deletion notification
        //this._userDetailsService.showSuccess(response.message);
        this.registeredFanList();
      })
      .catch(data => {
        console.log('error========',data);
        
      })
    }
  }

  editAmount(fid,action){
    this.action = action;
    this.fid = fid;
    // $("#editamount"+fid).hide();
    // $("#updateamount"+fid).removeClass("hide");
    this.walletBalanceModal.show();
  }

  updateAmount(){
    let amount = ($("#amount"+this.fid).val());
    let action = parseInt($("#action"+this.fid).val());

    if(amount>0){
        this.httpService.httpRequest('POST','addWalletBalance',{"userId":this.fid,"amount":amount,"actionFlag":action},true)
        .then(Response => {
          let response: any = Response;
          this._userDetailsService.showSuccess(response.message);
          this.walletBalanceModal.hide();
          //this._uiRouter.stateService.reload();
        })
        .catch(data => {
          console.log('error',data);
          this.walletBalanceModal.hide();
          this._userDetailsService.showError(data.message);
        })
    }else{
        this._userDetailsService.showError('Please enter valid amount.');
    }
     

  }

}
