import { Component, OnInit } from '@angular/core';


import { SERVER_NAME, APIS } from '../config';
import { HttpService } from '../services/http.service';
import { UIRouter } from '@uirouter/angular';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  constructor(public _httpService : HttpService, public _uiRouter : UIRouter) {  }

  ngOnInit() {
    if(!HttpService.getUserData()){
      this._uiRouter.stateService.go('login');
    }
  }

  logoutFnc(){
      HttpService.removeUserData();
      this._uiRouter.stateService.go('login');
  }

}
