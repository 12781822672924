import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder , Validators} from '@angular/forms';
import { UserDetailsService } from '../services/user-details.service';
import { HttpService } from '../services/http.service';
import { CustomValidatorsService } from '../services/custom-validator.service';

@Component({
  selector: 'app-average-game-time',
  templateUrl: './average-game-time.component.html',
  styleUrls: ['./average-game-time.component.scss']
})
export class AverageGameTimeComponent implements OnInit {
  avgGameForm: FormGroup;
  MLBval:any;
  NBAval:any;
  NFLval:any;
  constructor(private fb: FormBuilder, 
              private _httpService: HttpService,
              private _userDetails: UserDetailsService) { }

  ngOnInit() {

    this.avgGameForm = this.fb.group({
      mlb: ['', [Validators.required,CustomValidatorsService.min(250)]],
      nfl: ['', [Validators.required,CustomValidatorsService.min(250)]],
      nba: ['', [Validators.required,CustomValidatorsService.min(250)]]
      
    });

    this.loadGameData();
  }

  loadGameData(){
    this._httpService.httpRequest('GET','getGameTime','',true)
    .then(Response => {
      let responseData: any = Response;
      //console.log(responseData);
      responseData.response.forEach(element => {
          if(element.leagueType=="MLB"){
            this.MLBval = element.gameTime;
          }else if(element.leagueType=="NBA"){
            this.NBAval = element.gameTime;            
          }else if(element.leagueType=="NFL"){
            this.NFLval = element.gameTime;            
          } 
      });
    })
    .catch(data => {
      this._userDetails.showError(data.message);
    })
  }

  gameFun(val){
    let requestObj: any = { 
      mlb: parseInt(val.mlb),
      nfl: parseInt(val.nfl),
      nba: parseInt(val.nba)
    }
    this._httpService.httpRequest('POST','avgGameTime',requestObj,true)
    .then(Response => {
      let response: any = Response;
      this._userDetails.showSuccess(response.message);
    })
    .catch(data => {
      this._userDetails.showError(data.message);
    })
  }
}
