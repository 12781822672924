import { Component, OnInit } from '@angular/core';
import { Http, Headers, Response } from '@angular/http';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { StateService, UIRouter } from '@uirouter/angular';
import { Moment } from 'moment';
import { DatePipe } from '@angular/common';

// service
import { UserDetailsService } from '../services/user-details.service';
import { HttpService } from '../services/http.service';

// component
import { ListOfEntrantsComponent } from '../list-of-entrants/list-of-entrants.component';
import { ContestDetailComponent } from '../contest-detail/contest-detail.component';

import { SERVER_NAME, APIS } from '../config';

declare const moment: any

@Component({
  selector: 'app-house-prop-history',
  templateUrl: './house-prop-history.component.html',
  styleUrls: ['./house-prop-history.component.scss'],
  providers: [HttpService, DatePipe]
})
export class HousePropHistoryComponent implements OnInit {
  filterfrm: FormGroup;
  searchfrm: FormGroup;
  contestListData: any;
  currentPage: number = 1;
  itemsPerPage: number = 10;
  collection: any[] = [];
  numberOfItem: number;
  totalPages: number;
  contestId: any;
  currentDate = new Date();
  contestData: any = [];
  arrayOfKeyValues: any = [];
  showErrorMsg: boolean = false;
  sortBy: any = 0;
  dateSelectError: any = "";
  eventIds: "";

  date1: Date;
  datepickerOpts = {
    endDate: new Date(),
    autoclose: true,
    todayBtn: 'linked',
    todayHighlight: true,
    assumeNearbyYear: true,
    format: 'M, dd yyyy'
  }
  date2: Date;
  datepickerToOpts = {
    startDate: this.date1,
    endDate: new Date(),
    autoclose: true,
    todayBtn: 'linked',
    todayHighlight: true,
    assumeNearbyYear: true,
    format: 'M, dd yyyy'
  }

  constructor(
    private _http: Http,
    private _userDetails: UserDetailsService,
    private _httpService: HttpService,
    private fb: FormBuilder,
    private _uiRouter: UIRouter,
  ) { }

  ngOnInit() {
    this.filterfrm = this.fb.group({
      leagueType: ['ALL', []],
      sortBy: ['', []],
      startDate: ['', []],
      endDate: ['', []]
    });

    this.searchfrm = this.fb.group({
      contestName: ['', []],
    });
    this.contestListingData();
  }

  sortDataFun(val) {
    if (val.sortBy) {
      this.sortBy = val.sortBy;
      this.contestListingData(val.sortBy, val.leagueType, val.contestType, val.startDate ? moment(val.startDate).format('YYYY/MM/DD') + " 00:00:00" : '', val.endDate ? moment(val.endDate).format('YYYY/MM/DD') + " 23:59:59" : '');
    }
  }

  searchEventId(eventIds){
    console.log('char ',eventIds);
    eventIds = $.trim(eventIds);
    this.eventIds = eventIds;
    this.contestListingData();
  }

  contestListingData(sortBy = '', leagueType = 'ALL', contestType = 'ALL', startDate = '', endDate = '') {
    let listingData: any = {
      "eventIds": this.eventIds,
      "leagueType": leagueType,
      "contestType": null,
      "featured": "0",
      "currentPage": this.currentPage,
      "currentSize": this.itemsPerPage
    }

    this._httpService.httpRequest('POST', 'fetchHousePropsHistory', listingData, true).then(Response => {
      let data: any = Response['response'].data;
      this.numberOfItem = Response['response'].pagination.numberOfElements;
      this.totalPages = Response['response'].pagination.totalPages;
      this.collection = data;
    }).catch(data => {

    })
  }

  halfToString(half){
    if(half==1){
      return "(1st Half)"
    } else if(half==2){
      return "(2nd Half)"
    } else { 
      return "(Full Game)"
    }
  }

  pageChanged($event, val) {
    this.currentPage = $event;
    this.contestListingData(val.sortBy, val.leagueType, val.contestType, val.startDate, val.endDate);
  }

  filterData(val) {
    if ((val.contestStartDate && !val.contestEndDate) || (!val.contestStartDate && val.contestEndDate)) {
      this.dateSelectError = "Please select both start and end date"
    } else {
      this.contestListingData(val.sortBy, val.leagueType, val.contestType, val.startDate, val.endDate);
    }
  }

  resetFilter() {
    this.contestListingData();
  }

  deactivate(id, name) {
    if(confirm("Are you sure you want to deactivate " + name + "?")) {
    this._httpService.httpRequest('GET', 'deactivateHouseProp', 'propId=' + id, true).then(Response => {
      window.alert(name + " Deactivated");
      this.contestListingData();
    }).catch(data => {

    })
  }
  }

  reenable(id, name) {
    this._httpService.httpRequest('GET', 'reenableHouseProp', 'propId=' + id, true).then(Response => {
      window.alert(name + " Re-Enabled");
      this.contestListingData();
    }).catch(data => {

    })
  }

  reactivate(id, name) {
    this._httpService.httpRequest('GET', 'reactivateHouseProp', 'propId=' + id, true).then(Response => {
      window.alert(name + " Reactivated");
      this.contestListingData();
    }).catch(data => {

    })
  }

  disable(id, name) {
    if(confirm("Are you sure you want to disable " + name + "?")) {
    this._httpService.httpRequest('GET', 'disableHouseProp', 'propId=' + id, true).then(Response => {
      window.alert(name + " Disabled");
      this.contestListingData();
    }).catch(data => {

    })
  }
  }

  updateParam(paramId, i, j) {
    var newValue = (<HTMLInputElement>document.getElementById(i + "-" + j)).value;
    if (newValue != "") {
      this._httpService.httpRequest('GET', 'updateHousePropParameter', 'paramId=' + paramId + '&newValue=' + newValue, true).then(Response => {
        let responseData : any = Response;
        if(responseData.success){
          window.alert(responseData.message);
          this.contestListingData();
        }else{
          this._userDetails.showError(responseData.message)
        }
      }).catch(data => {
        this._userDetails.showError(data.message)
      })
    }
  }

  startDateChange(date1) {
    this.dateSelectError = "";
    if ((this.date2 < this.date1 && (this.date2))) {
      this.dateSelectError = "End date should be greater than start date";
    } else {
      this.dateSelectError = "";
      this.datepickerToOpts = {
        startDate: this.date1,
        endDate: new Date(),
        autoclose: true,
        todayBtn: 'linked',
        todayHighlight: false,
        assumeNearbyYear: true,
        format: 'M, dd yyyy'
      }
    }
  }

  endDateChange(date1, date2) {
    this.dateSelectError = "";
    if (date2 >= date1) {
      this.dateSelectError = "";
    } else if ((date2 && date1) && date2 <= date1) {
      this.dateSelectError = "End date should be greater than start date";
    }
  }
}