import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { StateService, UIRouter } from '@uirouter/angular';

// service
import { UserDetailsService } from '../services/user-details.service';
import { HttpService } from '../services/http.service';

@Component({
  selector: 'app-contest-detail',
  templateUrl: './contest-detail.component.html',
  styleUrls: ['./contest-detail.component.scss']
})
export class ContestDetailComponent implements OnInit {
  contestId : any;
  contestPrizes : any;
  contestDetails : any;
  contestProps: any;
  
  constructor( 
    private _userDetails: UserDetailsService,
    private _httpService: HttpService,
    private _uiRouter: UIRouter) { 
      this.contestId = this._uiRouter.stateService.params.contestId;
    }
  
  ngOnInit() {
     this._httpService.httpRequest('POST','contestDetail',{contestId : this.contestId}, true)
        .then(Response => {
          let responseData: any = Response;
          console.log('contestDetails',responseData)
          this.contestDetails = responseData.response.createionDto;
          this.contestPrizes = responseData.response.prizeDto;
          this.contestProps = responseData.response.adminContestPropsDto;
          //console.log('contestDetails',this.contestDetails);
          ///console.log('contestPrizes',this.contestPrizes);
        })
        .catch(data => {

        })
  }


  viewDetailFun(contestId){

    console.log('hi',contestId);
    this._uiRouter.stateService.go('contest-detail',{contestId: contestId});
  }
  

}
