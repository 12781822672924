import { Component, OnInit, ViewChild } from '@angular/core';
import { EntrantDetailModalComponent } from '../entrant-detail-modal/entrant-detail-modal.component';
import { StateService, UIRouter } from '@uirouter/angular';
import { HttpService } from '../services/http.service';

//Services
import { UserDetailsService } from '../services/user-details.service';

@Component({
  selector: 'app-list-of-entrants-archive',
  templateUrl: './list-of-entrants-archive.component.html',
  styleUrls: ['./list-of-entrants-archive.component.scss']
})
export class ListOfEntrantsArchiveComponent implements OnInit {
  contestId: any;
  currentPage: number = 1;
  itemsPerPage: number = 10;
  entrants : any;
  pagination: any;
  totalPoints : any = [];
  leaugeStatus :any;

  picksData : any = [];
  pickArray : any = [];
  maximumPoint : any;
  constructor(private _uiRouter: UIRouter, private _httpService : HttpService, private _userDetailsService : UserDetailsService) { 

    this.contestId = this._uiRouter.stateService.params.contestId;
    this.leaugeStatus = this._uiRouter.stateService.params.leaugeStatus;
    console.log('this.contestId',this.contestId);
  }

  ngOnInit() {
      this.getEntrantsList();
  }

  pageChanged($event){
    console.log('event',$event);
    this.currentPage = $event;
    this.getEntrantsList();
  }

  didUseTicket(ticketId){
    if(ticketId!=0 && ticketId!=null){
      return "(Ticket Entry)"
    }
  }



  getEntrantsList(){
    let getEntrantListObj = {
      "pageNo":this.currentPage,
      "pageSize":this.itemsPerPage,
      "contestId":this.contestId
    }
    console.log("CONTESTIDIDIDID: " + this.contestId);
    this._httpService.httpRequest('POST','getEntrantsContestListArchive',getEntrantListObj,true)
    .then(Response => {
      let response: any = Response;

      console.log(response);

      this.entrants = response.response.data;
      this.pagination = response.response.pagination;
      
      console.log(this.entrants);

      if(this.leaugeStatus!='upcoming'){
          for(let i=0;i<this.entrants.length;i++){
            let upp = this.entrants[i].userPropPicks;
            console.log("pickle rick");
            console.log(upp);
            
            let tpoints = 0;
            let propsData = this.checkProps(upp);
            console.log(propsData);
              for(let j=0;j<propsData.length;j++){
               // if(!propsData[j].ice){
                  if(propsData[j].player2Name=='NULL' || !(propsData[j].player2Name)){
                    if(propsData[j].isOver && (propsData[j].livePropPoints >= propsData[j].propValue)){
                      tpoints = tpoints +propsData[j].overPoints;
                    }else if(!(propsData[j].isOver) && (propsData[j].livePropPoints < propsData[j].propValue)){
                      tpoints = tpoints + propsData[j].underPoints;
                    }
                  }else{
                   /* if(propsData[j].isOver==true && ((propsData[j].livePropPoints+propsData[j].player1Advantage) >= propsData[j].livePropPointsPlayer2)){
                      tpoints = tpoints + propsData[j].player1Points;
                    }else if(propsData[j].isOver==false && (propsData[j].livePropPoints < propsData[j].livePropPointsPlayer2)){
                      tpoints = tpoints + propsData[j].player2Points;
                    }*/

                    tpoints = tpoints + propsData[j].userPoints;
                  }
                //}
              }
              this.totalPoints[i] = tpoints;
          }
      }

      if(this.leaugeStatus=='upcoming'){
        for(let i=0;i<this.entrants.length;i++){
          let upp = this.entrants[i].userPropPicks;
          let tpoints = 0;
          let propsData = this.checkProps(upp);
            //console.log("coming-=="+propsData.length);
            for(let j=0;j<propsData.length;j++){

              //console.log(propsData[j]);
                if(propsData[j].player2Name=='NULL' || !(propsData[j].player2Name)){
                    if(propsData[j].isOver){
                      tpoints = tpoints + propsData[j].overPoints;
                    }else if(!(propsData[j].isOver) ){
                      tpoints = tpoints + propsData[j].underPoints;
                    }
                  }else{
                    if(propsData[j].isOver==true){
                      tpoints = tpoints + propsData[j].player1Points;
                    }else if(propsData[j].isOver==false){
                      tpoints = tpoints + propsData[j].player2Points;
                    }
                 }
            }
            //console.log(tpoints)
            this.totalPoints[i] = tpoints;

        }
      }


      /* for(let i=0;i<this.entrants.length;i++){
         let upp = this.entrants[i].userPropPicks;
         let tpoints = 0;
         console.log(this.checkProps(upp));
          
          for(let j=0;j<upp.length;j++){
            
            if(this.leaugeStatus=='upcoming'){
             
              if(!upp[j].propDisabled){  // none prop disable
                if(!(upp[j].isICE)){
                  if(upp[j].player2Name=='NULL' || !(upp[j].player2Name)){
                    if(upp[j].isOver){
                      tpoints = tpoints + upp[j].overPoints;
                    }else if(!(upp[j].isOver) ){
                      tpoints = tpoints + upp[j].underPoints;
                    }
                  }else{
                    if(upp[j].isOver==true){
                      tpoints = tpoints + upp[j].player1Points;
                    }else if(upp[j].isOver==false){
                      tpoints = tpoints + upp[j].player2Points;
                    }
                  }
                }
              }
              
            }else{
                if(upp[j].player2Name=='NULL' || !(upp[j].player2Name)){
                  //console.log(upp[j].isOver+"======"+upp[j].livePropPoints+"======"+upp[j].propValue);
                  if(upp[j].isOver && (upp[j].livePropPoints >= upp[j].propValue)){
                    //console.log(upp[j].overPoints);
                    tpoints = tpoints + upp[j].overPoints;
                  }else if(!(upp[j].isOver) && (upp[j].livePropPoints < upp[j].propValue)){
                    //console.log(upp[j].underPoints);
                    tpoints = tpoints + upp[j].underPoints;
                  }
                }else{
                  if(upp[j].isOver==true && ((upp[j].livePropPoints+upp[j].player1Advantage) >= upp[j].livePropPointsPlayer2)){
                    tpoints = tpoints + upp[j].player1Points;
                  }else if(upp[j].isOver==false && (upp[j].livePropPoints < upp[j].livePropPointsPlayer2)){
                    tpoints = tpoints + upp[j].player2Points;
                  }
                }
            }
            
          }
           this.totalPoints[i] = tpoints;

           
       }*/
      //console.log("length"+this.totalPoints);
      // totalElements = response.response.pagination.numberOfElements
    })
    .catch(data => {
      //console.log('error',data);
    })
  }


  checkProps(pickData){
      this.picksData=pickData;
      this.pickArray=[];
    let  primaryScore={},secondaryScore={};
    this.picksData.forEach(data1 =>{
      if(data1.isICE && data1.icePrimary){
        primaryScore = data1;
      }else if(data1.isICE && !data1.icePrimary){
        secondaryScore = data1;
      }
      if(!data1.isICE && !data1.propDisabled){
        this.pickArray.push(data1);
        //this.maximumPoint += data.userPropPick.userPoints;
      }

    });
   return this.checkForDisable(primaryScore,secondaryScore);
  }

  checkForDisable(primaryScore,secondaryScore){
    let count=0;
    this.picksData.forEach(data=> {
      if(!data.isICE && data.propDisabled){
        count++;
        if(count===1){
          if(!primaryScore.propDisabled){
            this.pickArray.push(primaryScore);
              this.maximumPoint+=primaryScore.userPoints;
          }else{
            if(!secondaryScore.propDisabled){
              this.pickArray.push(secondaryScore);
                this.maximumPoint+=secondaryScore.userPoints;
              count++;
            }
      
          }
        }
      }
    })
   // console.log(this.pickArray);
    return this.pickArray;
  }

  addMax(pickData){
    let  primaryScore={},secondaryScore={};
    pickData.forEach(data=>{
      if(data.userPropPick.isICE && data.userPropPick.icePrimary){
        primaryScore = data.userPropPick;
      }else if(data.userPropPick.isICE && !data.userPropPick.icePrimary){
        secondaryScore = data.userPropPick;
      }
      if(!data.userPropPick.isICE && !data.userPropPick.propDisabled){
        this.maximumPoint += data.userPropPick.userPoints;
      }

    });
    this.checkForDisable(primaryScore,secondaryScore);
    return this.maximumPoint;
  }

}
