import { Component, OnInit } from '@angular/core';
import { Http, Headers, Response } from '@angular/http';
import { FormGroup, FormBuilder , Validators} from '@angular/forms';
import { StateService, UIRouter } from '@uirouter/angular';
import { Moment } from 'moment';

// service
import { UserDetailsService } from '../services/user-details.service';
import { HttpService } from '../services/http.service';

// component
import { ListOfEntrantsComponent } from '../list-of-entrants/list-of-entrants.component';
import{ ContestDetailComponent } from '../contest-detail/contest-detail.component';
import { LivePlayerStatsComponent } from '../live-player-stats/live-player-stats.component';
import {SERVER_NAME, APIS} from '../config';

declare const moment:any


@Component({
  selector: 'app-player-props',
  templateUrl: './player-props.component.html',
  styleUrls: ['./player-props.component.scss'],
  providers: [UserDetailsService, HttpService]
})

export class PlayerPropsComponent implements OnInit {
  filterfrm: FormGroup;
  searchfrm : FormGroup;
  contestListData: any;
  currentPage: number = 1;
  itemsPerPage: number = 10;
  collection: any[] = [];  
  numberOfItem: number;
  totalPages: number;
  contestId: any;
  currentDate = new Date();
  contestData : any =[];
  arrayOfKeyValues : any=[];
  showErrorMsg :boolean =  false;
  sortBy: any =0;
  dateSelectError : any = "";
  userPropsArray: any = [];
  leaugeStatus: any;
  entrants: any;
  pagination: any;

  //dateFrom: Date = new Date();


  date1: Date;
  datepickerOpts = {
      endDate : new Date(),
      autoclose: true,
      todayBtn: 'linked',
      todayHighlight: true,
      assumeNearbyYear: true,
      format: 'M, dd yyyy'
  }
  date2: Date ;
  datepickerToOpts = {
      startDate: this.date1,
      endDate : new Date(),
      autoclose: true,
      todayBtn: 'linked',
      todayHighlight: true,
      assumeNearbyYear: true,
      format: 'M, dd yyyy'
  }



  constructor(
    private _http: Http, 
    private fb: FormBuilder,
    private _uiRouter: UIRouter,
    private _httpService: HttpService,
    private _userDetails: UserDetailsService,
    private _userDetailsService: UserDetailsService) {

    
    this.contestId = this._uiRouter.stateService.params.contestId;
    this.leaugeStatus = this._uiRouter.stateService.params.leaugeStatus;
    console.log('this.contestId', this.contestId);
  }

  ngOnInit() {
    this.filterfrm = this.fb.group({
      leagueType: ['ALL', []],
      sortBy:['',[]],
      startDate:['',[]],
      endDate:['',[]]
    });

    this.searchfrm = this.fb.group({
      contestName: ['', []],
    });

    console.log(this.userPropsArray);
    this.getEntrantsList(this.contestId);

    this.contestListingData();
  }

  
  getEntrantsList(contestId) {
    console.log(contestId);
    console.log(this.currentPage);
    console.log(this.itemsPerPage);
    let getEntrantListObj = {
      "pageNo": this.currentPage,
      "pageSize": this.itemsPerPage,
      "contestId": contestId
    }
    this._httpService.httpRequest('POST', 'getEntrantsContestList', getEntrantListObj, true)
      .then(Response => {
        let response: any = Response;

        this.entrants = response.response.data;
        this.pagination = response.response.pagination;



        if (this.leaugeStatus != 'upcoming') {
          //console.log('!upcoming');
          //console.log(this.entrants.length);
          for (let i = 0; i < this.entrants.length; i++) {

            let upp = this.entrants[i].userPropPicks;
            //console.log(upp);
            if (!upp[0].startTimeEvent2) {
             // console.log("traditional");
              for (var v = 0; v < upp.length; v++) {

                this.userPropsArray.push({
                  "traditional": {
                    "name": upp[v].player1Name,
                    "team": upp[v].player1TeamAbrivation, "playerProps": upp[v].propParameters
                  }
                });
              }
            } else {
              //console.log("versus");
              for (var v = 0; v < upp.length; v++) {
                //console.log(upp[v]);
                this.userPropsArray.push({
                  "versus": {
                    "player1": {
                      "name1": upp[v].player1Name,
                      "team1": upp[v].player1TeamAbrivation, "playerProps": upp[v].propParameters[0]
                    }, "player2": {
                      "name2": upp[v].player2Name,
                      "team2": upp[v].player2TeamAbrivation, "playerProps": upp[v].propParameters[1]
                    }
                  }
                });
              }
            }
            console.log(this.userPropsArray);
          }
        }
      })
      .catch(data => {
        //console.log('error',data);
      })
  }

  sortDataFun(val){
    console.log(val);
    if(val.sortBy){
      this.sortBy = val.sortBy;
      this.contestListingData(val.sortBy,val.leagueType,val.contestType,val.startDate?moment(val.startDate).format('YYYY/MM/DD')+" 00:00:00":'',val.endDate?moment(val.endDate).format('YYYY/MM/DD')+" 23:59:59":'');
    }
  }

  contestListingData(sortBy='',leagueType='ALL',contestType='ALL',startDate='',endDate=''){
    
    let listingData: any = {
            "leagueId":leagueType,
            "sortBy": this.sortBy,
            "startDate" : startDate?moment(startDate).format('YYYY/MM/DD')+" 00:00:00":'',
            "endDate" : endDate?moment(endDate).format('YYYY/MM/DD')+" 23:59:59":'',
            "currentPage":this.currentPage,
            "currentSize":this.itemsPerPage
    }

    this._httpService.httpRequest('POST','popularPlayers',listingData, true)
    .then(Response => {
      let data: any = Response['response'].data;
      this.numberOfItem = Response['response'].pagination.numberOfElements;
      this.totalPages = Response['response'].pagination.totalPages;
      this.collection = data;
      console.log(Response)
      console.log('collection',this.collection);
    })
    .catch(data => {

    })
  }

  pageChanged($event,val){
    console.log(val);
    this.currentPage = $event;
    this.contestListingData(val.sortBy,val.leagueType,val.contestType,val.startDate,val.endDate);
  }

  filterData(val){
    
    if((val.contestStartDate && !val.contestEndDate) || (!val.contestStartDate && val.contestEndDate)){
      this.dateSelectError = "Please select both start and end date"
    }else{
    this.contestListingData(val.sortBy,val.leagueType,val.contestType,val.startDate,val.endDate);
    }
  }

  resetFilter(){
    this.contestListingData();
  }

  startDateChange(date1){
    console.log('start date date 1');
    this.dateSelectError = "";
        if((this.date2<this.date1 && (this.date2))){
          this.dateSelectError = "End date should be greater than start date";
        }else{
          this.dateSelectError = "";
          this.datepickerToOpts = {
            startDate: this.date1,
            endDate : new Date(),
            autoclose: true,
            todayBtn: 'linked',
            todayHighlight: false,
            assumeNearbyYear: true,
            format: 'M, dd yyyy'
          }
        }
  }

  endDateChange(date1,date2){
    console.log('date1',date1);
    console.log('date2',date2);
    this.dateSelectError = "";
    if(date2 >= date1){
      this.dateSelectError = "";
    }else if((date2 && date1) && date2 <= date1){
      this.dateSelectError = "End date should be greater than start date";
    }
  }

}
